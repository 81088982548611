import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core'
import {
  CreditRenewAttachmentsViewModel,
  CreditRenewDataViewModel,
} from '@ifc-api/models'
import {
  AttachmentDocumentTypeArray,
  AttachmentDocumentTypeEnum,
  AuthorizationTypeArray,
  CreditRenewStatusTypeEnum,
  CreditRequestTypeArray,
  CreditRequestTypeEnum,
  GradeTypeArray,
  ModalityArray,
  PeriodTypeArray,
  PosGradeTypeArray,
  PreGradeTypeArray,
  SuspensionTypeArray,
  UniversityTypeArray,
  toAttachmentDocumentType,
  toCreditRequestType,
} from '@shared/models'
import { CommonService } from '@shared/services'
import { format } from 'date-fns'
import { saveAs } from 'file-saver'
import { take } from 'rxjs'
import Swal from 'sweetalert2'
import { DownRequestService } from './down-request.service'

@Component({
  selector: 'app-fesca-request-details',
  templateUrl: './fesca-request-details.component.html',
  styleUrls: ['./fesca-request-details.component.scss']
})
export class FescaRequestDetailsComponent implements OnChanges {

  @Input() requestDetails?: CreditRenewDataView

  creditRequestTypes = CreditRequestTypeEnum
  creditRenewStatusTypes = this._creditRenewStatusTypes

  toCreditRequestType = toCreditRequestType

  creditRequestTypeArray = CreditRequestTypeArray
  gradeTypeArray = GradeTypeArray
  preGradeTypeArray = PreGradeTypeArray
  posGradeTypeArray = PosGradeTypeArray
  universityTypeArray = UniversityTypeArray
  modalityArray = ModalityArray
  periodTypeArray = PeriodTypeArray
  authorizationTypeArray = AuthorizationTypeArray
  suspensionTypeArray = SuspensionTypeArray
  attachmentDocumentType = AttachmentDocumentTypeEnum
  attachmentDocumentTypeArray = AttachmentDocumentTypeArray

  downloading = false

  constructor(
    public commonService: CommonService,
    private downRequestService: DownRequestService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['requestDetails'] && this.requestDetails) {
      this.requestDetails.attachments = this.requestDetails.attachments?.map((_attach) => {
        const documentType = this.attachmentDocumentTypeArray.filter((_typeDoc) => _typeDoc.id === _attach.documentType)[0]

        if (toAttachmentDocumentType(_attach.documentType) === this.attachmentDocumentType.DocumentType && _attach.description) {
          _attach.typeName = _attach.description
        } else if (documentType) {
          _attach.typeName = documentType.name
        }

        return _attach
      })
    }
  }

  private get _creditRenewStatusTypes(): { name: string, id: number | string }[] {
    const data = Object.keys(CreditRenewStatusTypeEnum)
      .filter((_key: any) => !isNaN(Number(CreditRenewStatusTypeEnum[_key])))
      .map((_key: any, _index) => {
        const value = CreditRenewStatusTypeEnum[_key]

        return {
          name: _key,
          id: value,
        }
      })

    return data
  }

  downloadPDF(): void {
    const requestID = this.requestDetails?.id

    if (requestID) {
      this.downloading = true
      const today = format(new Date(), 'yyyy-MM-dd')

      this.downRequestService.getRequestPDF(requestID)
        .pipe(take(1))
        .subscribe({
          next: (report) => {
            this.downloading = false
            saveAs(report, `${requestID}-${today}.pdf`)
          },
          error: (err) => {
            this.downloading = false
            const message = this.commonService.getMessageErrorHTTP(err, 'Lo sentimos, ha ocurrido un error')
            Swal.fire('Ups.. 😟', message, 'error')
          }
        })
    }
  }

}

interface CreditRenewDataView extends CreditRenewDataViewModel {
  attachments?: CreditRenewAttachmentsView[] | null
}

interface CreditRenewAttachmentsView extends CreditRenewAttachmentsViewModel {
  name?: string
  typeName?: string
}
