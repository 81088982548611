import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UserRequestViewModel } from '@ifc-api/models';
import {
  ApexLegend,
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart,
  ChartComponent,
  ApexTooltip,
} from "ng-apexcharts";
import { timer } from 'rxjs';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: string[];
  legend: ApexLegend;
  tooltip: ApexTooltip;
}

@Component({
  selector: 'app-chart-pie-pqrs',
  templateUrl: './chart-pie-pqrs.component.html',
  styleUrls: ['./chart-pie-pqrs.component.scss']
})
export class ChartPiePqrsComponent implements OnChanges {

  @ViewChild('chart', { static: true }) chart!: ChartComponent
  chartOptions: ChartOptions

  @Input() pqrs: UserRequestViewModel[] = []
  @Input() orderBy!: string
  @Input() title: string = ''

  constructor() {
    this.chartOptions = {
      series: [],
      chart: {
        type: "pie"
      },
      labels: [],
      responsive: [],
      legend: {
        show: true,
        position: 'bottom'
      },
      tooltip: {
        custom: ({ series, seriesIndex, w }) => {
          const percent = series[seriesIndex]
          const count = Math.round((percent * this.pqrs.length) / 100)
          return '<div class="px-1">' + w.globals.labels[seriesIndex] + ': ' + count + '</div>'
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && this.pqrs && this.orderBy) {
      this.setDataChatPie()
    }
  }

  private setDataChatPie(): void {
    const orderBy = <keyof UserRequestViewModel>this.orderBy
    let labels = this.pqrs.map((_pqrs) => _pqrs[orderBy])

    labels = labels.filter((_label, index) => {
      const indexFind = labels.findIndex((__label) => __label === _label)
      return index === indexFind
    }).sort((a, b) => {
      if (!a || !b) { return 0 }
      if (a < b) { return -1 }
      if (a > b) { return 1 }

      return 0
    })

    const series: number[] = []

    labels.forEach((_label) => {
      const pqrsPerLabelLength = this.pqrs.filter((_pqrs) => _pqrs[orderBy] === _label).length
      const percent = (pqrsPerLabelLength / this.pqrs.length) * 100

      series.push(percent)
    })

    const newOptions = {
      labels
    }

    timer(300).subscribe({
      next: () => {
        this.chart.updateSeries(series)
        this.chart.updateOptions(newOptions, true)
      }
    })

  }

}
