<app-loading-data *ngIf="loadingData; else dataLoaded"></app-loading-data>

<ng-template #dataLoaded>
  <div mat-dialog-title class="d-flex justify-content-between">
    <div class="my-2">Historial - Crédito {{ dialogData.creditNumber }}</div>

    <button mat-icon-button [mat-dialog-close]="null" color="warn">
      <mat-icon>cancel</mat-icon>
    </button>
  </div>

  <mat-dialog-content class="overflow-x">
    <table mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="generatedOn">
        <th mat-header-cell *matHeaderCellDef class="px-1">Fecha</th>
        <td mat-cell *matCellDef="let element" class="px-1">
          {{ element.generatedOn | date : "medium" }}
        </td>
      </ng-container>

      <ng-container matColumnDef="report">
        <th mat-header-cell *matHeaderCellDef class="px-1">Reporte</th>
        <td mat-cell *matCellDef="let element" class="px-1">
          {{ element.reporteTypeName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="px-1">&nbsp;</th>
        <td mat-cell *matCellDef="let element" class="px-1">
          <a
            mat-icon-button
            [href]="element.url"
            target="_blank"
            color="primary"
          >
            <mat-icon>open_in_new</mat-icon>
          </a>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </mat-dialog-content>

  <mat-dialog-actions>
    <mat-paginator [pageSizeOptions]="[8, 25, 50, 100]"></mat-paginator>
  </mat-dialog-actions>
</ng-template>
