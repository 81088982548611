/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AuthenticationRequest } from '../models/authentication-request';
import { AuthenticationResponse } from '../models/authentication-response';
import { ChangePasswordCommand } from '../models/change-password-command';
import { ChangePasswordViewModel } from '../models/change-password-view-model';
import { ConfirmAccountCommand } from '../models/confirm-account-command';
import { ConfirmAccountViewModel } from '../models/confirm-account-view-model';
import { MessageSuscriptionCommand } from '../models/message-suscription-command';
import { MessageSuscriptionViewModel } from '../models/message-suscription-view-model';
import { PasswordResetCommand } from '../models/password-reset-command';
import { PasswordResetViewModel } from '../models/password-reset-view-model';
import { RegistrationAdminRequest } from '../models/registration-admin-request';
import { RegistrationRequest } from '../models/registration-request';
import { RegistrationResponse } from '../models/registration-response';

@Injectable({ providedIn: 'root' })
export class AccountService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiAccountAuthenticatePost()` */
  static readonly ApiAccountAuthenticatePostPath = '/api/Account/authenticate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountAuthenticatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountAuthenticatePost$Plain$Response(
    params?: {
      body?: AuthenticationRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AuthenticationResponse>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountAuthenticatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AuthenticationResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountAuthenticatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountAuthenticatePost$Plain(
    params?: {
      body?: AuthenticationRequest
    },
    context?: HttpContext
  ): Observable<AuthenticationResponse> {
    return this.apiAccountAuthenticatePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<AuthenticationResponse>): AuthenticationResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountAuthenticatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountAuthenticatePost$Json$Response(
    params?: {
      body?: AuthenticationRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AuthenticationResponse>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountAuthenticatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AuthenticationResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountAuthenticatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountAuthenticatePost$Json(
    params?: {
      body?: AuthenticationRequest
    },
    context?: HttpContext
  ): Observable<AuthenticationResponse> {
    return this.apiAccountAuthenticatePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<AuthenticationResponse>): AuthenticationResponse => r.body)
    );
  }

  /** Path part for operation `apiAccountRegisterPost()` */
  static readonly ApiAccountRegisterPostPath = '/api/Account/register';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountRegisterPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountRegisterPost$Plain$Response(
    params?: {
      body?: RegistrationRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<RegistrationResponse>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountRegisterPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RegistrationResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountRegisterPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountRegisterPost$Plain(
    params?: {
      body?: RegistrationRequest
    },
    context?: HttpContext
  ): Observable<RegistrationResponse> {
    return this.apiAccountRegisterPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RegistrationResponse>): RegistrationResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountRegisterPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountRegisterPost$Json$Response(
    params?: {
      body?: RegistrationRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<RegistrationResponse>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountRegisterPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RegistrationResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountRegisterPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountRegisterPost$Json(
    params?: {
      body?: RegistrationRequest
    },
    context?: HttpContext
  ): Observable<RegistrationResponse> {
    return this.apiAccountRegisterPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<RegistrationResponse>): RegistrationResponse => r.body)
    );
  }

  /** Path part for operation `apiAccountMessagesSuscriptionPost()` */
  static readonly ApiAccountMessagesSuscriptionPostPath = '/api/Account/messages-suscription';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountMessagesSuscriptionPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountMessagesSuscriptionPost$Plain$Response(
    params?: {
      body?: MessageSuscriptionCommand
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MessageSuscriptionViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountMessagesSuscriptionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageSuscriptionViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountMessagesSuscriptionPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountMessagesSuscriptionPost$Plain(
    params?: {
      body?: MessageSuscriptionCommand
    },
    context?: HttpContext
  ): Observable<MessageSuscriptionViewModel> {
    return this.apiAccountMessagesSuscriptionPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MessageSuscriptionViewModel>): MessageSuscriptionViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountMessagesSuscriptionPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountMessagesSuscriptionPost$Json$Response(
    params?: {
      body?: MessageSuscriptionCommand
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MessageSuscriptionViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountMessagesSuscriptionPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageSuscriptionViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountMessagesSuscriptionPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountMessagesSuscriptionPost$Json(
    params?: {
      body?: MessageSuscriptionCommand
    },
    context?: HttpContext
  ): Observable<MessageSuscriptionViewModel> {
    return this.apiAccountMessagesSuscriptionPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<MessageSuscriptionViewModel>): MessageSuscriptionViewModel => r.body)
    );
  }

  /** Path part for operation `apiAccountPasswordRecoveryPost()` */
  static readonly ApiAccountPasswordRecoveryPostPath = '/api/Account/password-recovery';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountPasswordRecoveryPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountPasswordRecoveryPost$Plain$Response(
    params?: {
      body?: PasswordResetCommand
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PasswordResetViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountPasswordRecoveryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PasswordResetViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountPasswordRecoveryPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountPasswordRecoveryPost$Plain(
    params?: {
      body?: PasswordResetCommand
    },
    context?: HttpContext
  ): Observable<PasswordResetViewModel> {
    return this.apiAccountPasswordRecoveryPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PasswordResetViewModel>): PasswordResetViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountPasswordRecoveryPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountPasswordRecoveryPost$Json$Response(
    params?: {
      body?: PasswordResetCommand
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PasswordResetViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountPasswordRecoveryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PasswordResetViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountPasswordRecoveryPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountPasswordRecoveryPost$Json(
    params?: {
      body?: PasswordResetCommand
    },
    context?: HttpContext
  ): Observable<PasswordResetViewModel> {
    return this.apiAccountPasswordRecoveryPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PasswordResetViewModel>): PasswordResetViewModel => r.body)
    );
  }

  /** Path part for operation `apiAccountPasswordChangePost()` */
  static readonly ApiAccountPasswordChangePostPath = '/api/Account/password-change';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountPasswordChangePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountPasswordChangePost$Plain$Response(
    params?: {
      body?: ChangePasswordCommand
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ChangePasswordViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountPasswordChangePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangePasswordViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountPasswordChangePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountPasswordChangePost$Plain(
    params?: {
      body?: ChangePasswordCommand
    },
    context?: HttpContext
  ): Observable<ChangePasswordViewModel> {
    return this.apiAccountPasswordChangePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangePasswordViewModel>): ChangePasswordViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountPasswordChangePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountPasswordChangePost$Json$Response(
    params?: {
      body?: ChangePasswordCommand
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ChangePasswordViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountPasswordChangePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChangePasswordViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountPasswordChangePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountPasswordChangePost$Json(
    params?: {
      body?: ChangePasswordCommand
    },
    context?: HttpContext
  ): Observable<ChangePasswordViewModel> {
    return this.apiAccountPasswordChangePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangePasswordViewModel>): ChangePasswordViewModel => r.body)
    );
  }

  /** Path part for operation `apiAccountRegisterUserPost()` */
  static readonly ApiAccountRegisterUserPostPath = '/api/Account/register-user';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountRegisterUserPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountRegisterUserPost$Plain$Response(
    params?: {
      body?: RegistrationAdminRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<RegistrationResponse>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountRegisterUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RegistrationResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountRegisterUserPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountRegisterUserPost$Plain(
    params?: {
      body?: RegistrationAdminRequest
    },
    context?: HttpContext
  ): Observable<RegistrationResponse> {
    return this.apiAccountRegisterUserPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RegistrationResponse>): RegistrationResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountRegisterUserPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountRegisterUserPost$Json$Response(
    params?: {
      body?: RegistrationAdminRequest
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<RegistrationResponse>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountRegisterUserPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RegistrationResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountRegisterUserPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountRegisterUserPost$Json(
    params?: {
      body?: RegistrationAdminRequest
    },
    context?: HttpContext
  ): Observable<RegistrationResponse> {
    return this.apiAccountRegisterUserPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<RegistrationResponse>): RegistrationResponse => r.body)
    );
  }

  /** Path part for operation `apiAccountConfirmAccountPost()` */
  static readonly ApiAccountConfirmAccountPostPath = '/api/Account/confirm-account';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountConfirmAccountPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountConfirmAccountPost$Plain$Response(
    params?: {
      body?: ConfirmAccountCommand
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ConfirmAccountViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountConfirmAccountPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConfirmAccountViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountConfirmAccountPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountConfirmAccountPost$Plain(
    params?: {
      body?: ConfirmAccountCommand
    },
    context?: HttpContext
  ): Observable<ConfirmAccountViewModel> {
    return this.apiAccountConfirmAccountPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConfirmAccountViewModel>): ConfirmAccountViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountConfirmAccountPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountConfirmAccountPost$Json$Response(
    params?: {
      body?: ConfirmAccountCommand
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ConfirmAccountViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountConfirmAccountPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConfirmAccountViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountConfirmAccountPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiAccountConfirmAccountPost$Json(
    params?: {
      body?: ConfirmAccountCommand
    },
    context?: HttpContext
  ): Observable<ConfirmAccountViewModel> {
    return this.apiAccountConfirmAccountPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConfirmAccountViewModel>): ConfirmAccountViewModel => r.body)
    );
  }

  /** Path part for operation `apiAccountSendConfirmEmailPost()` */
  static readonly ApiAccountSendConfirmEmailPostPath = '/api/Account/sendConfirmEmail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountSendConfirmEmailPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountSendConfirmEmailPost$Plain$Response(
    params?: {
      email?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountSendConfirmEmailPostPath, 'post');
    if (params) {
      rb.query('email', params.email, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountSendConfirmEmailPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountSendConfirmEmailPost$Plain(
    params?: {
      email?: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.apiAccountSendConfirmEmailPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiAccountSendConfirmEmailPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountSendConfirmEmailPost$Json$Response(
    params?: {
      email?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, AccountService.ApiAccountSendConfirmEmailPostPath, 'post');
    if (params) {
      rb.query('email', params.email, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiAccountSendConfirmEmailPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiAccountSendConfirmEmailPost$Json(
    params?: {
      email?: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.apiAccountSendConfirmEmailPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

}
