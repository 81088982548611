import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { ValidateDirective } from './validate.directive';

@NgModule({
  declarations: [ValidateDirective],
  imports: [
    CommonModule
  ],
  providers: [
    DecimalPipe
  ],
  exports: [ValidateDirective]
})
export class ValidateModule {}
