import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toNumber'
})
export class ToNumberPipe implements PipeTransform {

  transform (value?: number | string): number | null {
    if (!value) {
      return null
    }

    if (value && typeof value === 'string') {

      const textArray = value.split('')

      const arrayNumber = textArray
        .filter(word => !(/\D/.test(word)))

      const numberString = arrayNumber.join('')

      return Number(numberString)

    }

    return Number(value)
  }

}
