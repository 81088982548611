import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayGetValue'
})
export class ArrayGetValuePipe implements PipeTransform {

  /**
   * 
   * @param data Arreglo u objector
   * @param value Valor por el cual filtrar
   * @param key Clave o propiedad que tiene asignado el valor a filtrar
   * @param keyReturn Clave o propiedad a la que retornar su valor
   * @returns 
   */
  transform (
    data: { [key: string]: string | number }[] | object,
    value?: string | number,
    key?: string,
    keyReturn?: string
  ): string | number {

    if (value === null || value === undefined) {
      return ''
    }

    if (Array.isArray(data)) {

      const result = data.filter((_object) => {
        key = key ? key : 'id'
        return _object[key] === value
      })[0]

      if (result) {
        keyReturn = keyReturn ? keyReturn : 'name'
        return result[keyReturn]
      }

    } else if (typeof data === 'object') {
      // TODO: is object
    }

    return ''
  }

}
