import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FirtsUpperPipe } from './firts-upper.pipe';

@NgModule({
  declarations: [FirtsUpperPipe],
  imports: [
    CommonModule,
  ],
  exports: [FirtsUpperPipe]
})
export class FirtsUpperModule {}
