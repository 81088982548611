import {
  Component,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core"
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog"
import { MatPaginator } from "@angular/material/paginator"
import { MatTableDataSource } from "@angular/material/table"
import { environment } from "@environment/environment"
import { GetHistoryViewModel } from '@ifc-api/models'
import { ReportsService } from '@ifc-api/services'
import { TypesOfReports } from '@shared/models'
import { CommonService } from '@shared/services'
import {
  take,
  timer,
} from "rxjs"
import Swal from "sweetalert2"

@Component({
  selector: 'app-report-history',
  templateUrl: './report-history.component.html',
  styleUrls: ['./report-history.component.scss']
})
export class ReportHistoryComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator

  dataSource = new MatTableDataSource<GetHistoryViewModel>()
  displayedColumns = ['generatedOn', 'report', 'actions']

  reportsHistory: GetHistoryViewModel[] = []
  loadingData = true

  constructor(
    private dialogRef: MatDialogRef<ReportHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    private commonService: CommonService,
    private reportsService: ReportsService,
  ) {
    this.getReportHistory()
  }

  ngOnInit(): void {}

  getReportHistory(): void {
    this.loadingData = true

    this.reportsService.apiReportsHistoryReportTypeCreditNumberGet$Json({
      reportType: this.dialogData.reportType as number,
      creditNumber: this.dialogData.creditNumber,
    })
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          const reportsHistory = res.map((_report: any) => {
            _report.url = `${environment.apiIFC}${_report.url}`
            return _report
          })
            .sort(function (a, b) {
              if (!a.generatedOn || !b.generatedOn) { return 0 }

              if (a.generatedOn > b.generatedOn) { return -1 }
              if (a.generatedOn < b.generatedOn) { return 1 }

              return 0
            })

          this.dataSource = new MatTableDataSource(reportsHistory)
          this.loadingData = false

          timer(300).subscribe({
            next: () => {
              this.dataSource.paginator = this.paginator
            }
          })
        },
        error: (err) => {
          const message = this.commonService.getMessageErrorHTTP(err, 'Lo sentimos, ha ocurrido un error')

          Swal.fire({
            icon: 'error',
            title: 'Upss.. 🙁',
            text: message,
            confirmButtonText: 'Aceptar'
          }).then(() => {
            this.dialogRef.close()
          })
        }
      })
  }

}

interface DialogData {
  reportType: TypesOfReports
  creditNumber: number
}
