import {
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core"
import { MatDialog } from "@angular/material/dialog"
import { Router } from "@angular/router"
import { CreditInfo } from '@ifc-api/models'
import { TypesIfEnabled } from '@shared/models'
import { CommonService } from '@shared/services'
import { AccountStatusComponent } from '../account-status/account-status.component'
import { PeaceAndSaveComponent } from '../peace-and-save/peace-and-save.component'
import { DebtADayComponent } from '../debt-a-day/debt-a-day.component'
import { IncomeStatementComponent } from '../income-statement/income-statement.component'
import { ReceiptPaymentsComponent } from '../receipt-payments/receipt-payments.component'
import { PaymentPseComponent } from '../payment-pse/payment-pse.component'

@Component({
  selector: 'app-credit-detail',
  templateUrl: './credit-detail.component.html',
  styleUrls: ['./credit-detail.component.scss']
})
export class CreditDetailComponent {

  @Input() document?: string | null
  @Input() credit?: CreditInfo
  @Output() close = new EventEmitter()

  typesIfEnabled = TypesIfEnabled

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private commonService: CommonService,
  ) {}

  /**
   * Presentar modal de reporte de estado de cuenta
   */
  presentAccountStatus(): void {
    const maxWidth = this.commonService.isMobile ? undefined : '50vw'

    this.dialog.open(AccountStatusComponent, {
      maxWidth,
      data: {
        document: this.document,
        creditInfoDetail: this.credit,
      }
    })

    this.close.emit()
  }

  /**
   * Presentar modal de paz y salvo
   */
  presentPeaceAndSave(): void {
    const maxWidth = this.commonService.isMobile ? undefined : '50vw'

    this.dialog.open(PeaceAndSaveComponent, {
      maxWidth,
      data: {
        document: this.document,
        creditInfoDetail: this.credit,
      }
    })

    this.close.emit()
  }

  /**
   * Presentar modal de certifcado de deuda al día
   */
  presentDebtADay(): void {
    const maxWidth = this.commonService.isMobile ? undefined : '50vw'

    this.dialog.open(DebtADayComponent, {
      maxWidth,
      data: {
        document: this.document,
        creditInfoDetail: this.credit,
      }
    })

    this.close.emit()
  }

  /**
   * Presentar modal de certificado para declarar renta
   */
  presentIncomeStatement(): void {
    const maxWidth = this.commonService.isMobile ? undefined : '50vw'

    this.dialog.open(IncomeStatementComponent, {
      maxWidth,
      data: {
        document: this.document,
        creditInfoDetail: this.credit,
      }
    })

    this.close.emit()
  }

  /**
   * Presentar modal de recibo de pago
   */
  presentReceiptPayments(): void {
    const maxWidth = this.commonService.isMobile ? undefined : '50vw'

    this.dialog.open(ReceiptPaymentsComponent, {
      maxWidth,
      data: {
        document: this.document,
        creditInfoDetail: this.credit,
      }
    })

    this.close.emit()
  }

  /**
   * Presenta modal de pago en linea PSE
   */
  presentPaymentPSE(): void {
    const maxWidth = this.commonService.isMobile ? undefined : '50vw'

    this.dialog.open(PaymentPseComponent, {
      maxWidth,
      disableClose: true,
      data: {
        document: this.document,
        creditInfoDetail: this.credit,
      }
    })

    this.close.emit()
  }

  /**
   * Redirige a renovación de crédito
   * @param creditNumber Número de crédito
   */
  redirectRenewCredit(creditNumber?: number): void {
    this.router.navigate(['/educative-credit', creditNumber])
  }

}
