import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environment/environment';

@Injectable({
  providedIn: 'root'
})
export class UploadFileService {

  constructor(private http: HttpClient) {}

  uploadFile(file: File): Observable<HttpEvent<UploadFileResponse>> {
    const formData = new FormData()
    formData.append('archivo', file, file.name)

    return this.http.post<UploadFileResponse>(`${environment.apiIFC}/api/UserRequest/uploadFile`, formData, {
      reportProgress: true,
      observe: 'events'
    })
  }

}

interface UploadFileResponse {
  fileName: string
  filePath: string
}
