<div class="row py-2">
  <div class="col-12 col-md-6">
    <p class="mb-0 mat-body-strong">Fecha de creado:</p>
    <p>{{ requestDetails?.createdOn | date : "medium" }}</p>
  </div>

  <div class="col-12 col-md-6">
    <p class="mb-0 mat-body-strong">Última actualización:</p>
    <p>{{ requestDetails?.updatedOn | date : "medium" }}</p>
  </div>

  <div class="col-12 col-md-6">
    <p class="mb-0 mat-body-strong">Tipo de Solicitud:</p>
    <p>
      {{
        creditRequestTypeArray
          | arrayGetValue : requestDetails?.requestType : "id" : "name"
      }}
    </p>
  </div>

  <div class="col-12 col-md-6">
    <p class="mb-0 mat-body-strong">Estado:</p>

    <p>
      {{
        creditRenewStatusTypes
          | arrayGetValue
            : requestDetails?.currentStatus?.creditRenewStatusType
            : "id"
            : "name"
      }}
    </p>
  </div>

  <div *ngIf="requestDetails?.ifcResponseNumber" class="col-12 py-2">
    <div class="alert alert-primary">
      Número de radicado IAS: <b> {{ requestDetails?.ifcResponseNumber }} </b>
    </div>
  </div>

  <div class="col-12 pb-4">
    <button
      mat-stroked-button
      (click)="downloadPDF()"
      color="primary"
      class="w-100 rounded-3"
      [disabled]="downloading"
    >
      Descargar pdf <mat-icon>file_download</mat-icon>
      <mat-spinner
        *ngIf="downloading"
        [diameter]="20"
        class="d-inline-flex ms-2"
        color="primary"
      ></mat-spinner>
    </button>
  </div>

  <div class="col-12">
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          Datos de cliente
        </mat-expansion-panel-header>

        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Primer nombre:</p>
              <p>{{ requestDetails?.solicitudIAS?.persona?.primerNombre }}</p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Segundo nombre:</p>
              <p>{{ requestDetails?.solicitudIAS?.persona?.segundoNombre }}</p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Primer apellido:</p>
              <p>
                {{ requestDetails?.solicitudIAS?.persona?.primerApelllido }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Segundo apellido:</p>
              <p>
                {{ requestDetails?.solicitudIAS?.persona?.segundoApellido }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Identificación:</p>
              <p>{{ requestDetails?.solicitudIAS?.persona?.identificacion }}</p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Fecha de nacimiento:</p>
              <p>
                {{
                  requestDetails?.solicitudIAS?.persona?.fechaNacimiento | date
                }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Correo electrónico:</p>
              <p>{{ requestDetails?.solicitudIAS?.persona?.email }}</p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Teléfono:</p>
              <p>
                {{ requestDetails?.solicitudIAS?.persona?.telefono }} -
                {{ requestDetails?.solicitudIAS?.persona?.telefonoMovil }}
              </p>
            </div>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          Datos de nucleo familiar en Casanare
        </mat-expansion-panel-header>

        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Departamento:</p>
              <p>{{ requestDetails?.familyRegionName }}</p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Municipio:</p>
              <p>{{ requestDetails?.familyCityName }}</p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Vereda / Barrio:</p>
              <p>{{ requestDetails?.familyZone }}</p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Dirección de residencia:</p>
              <p>{{ requestDetails?.familyAddress }}</p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Teléfono familiar:</p>
              <p>{{ requestDetails?.familyPhone }}</p>
            </div>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          Datos de consignación
        </mat-expansion-panel-header>

        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">
                Valor consignado en época de estudio:
              </p>
              <p>${{ requestDetails?.consignmentValue | number }}</p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Fecha de la cuota consignada:</p>
              <p>{{ requestDetails?.consignmentDate | date }}</p>
            </div>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          Datos laborales
        </mat-expansion-panel-header>

        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Vinculación laboral:</p>
              <p>{{ requestDetails?.currentlyWork ? "Si" : " No" }}</p>
            </div>

            <div
              class="col-12 col-md-6 px-0"
              [ngClass]="{
                'd-none': !requestDetails?.currentlyWork,
                'd-flex justify-content-between': commonService.isMobile
              }"
            >
              <p class="mb-0 mat-body-strong">Nombre de empresa o actividad:</p>
              <p>{{ requestDetails?.companyName }}</p>
            </div>

            <div
              class="col-12 col-md-6 px-0"
              [ngClass]="{
                'd-none': !requestDetails?.currentlyWork,
                'd-flex justify-content-between': commonService.isMobile
              }"
            >
              <p class="mb-0 mat-body-strong">Departamento:</p>
              <p>{{ requestDetails?.companyRegionName }}</p>
            </div>

            <div
              class="col-12 col-md-6 px-0"
              [ngClass]="{
                'd-none': !requestDetails?.currentlyWork,
                'd-flex justify-content-between': commonService.isMobile
              }"
            >
              <p class="mb-0 mat-body-strong">Ciudad:</p>
              <p>{{ requestDetails?.companyCityName }}</p>
            </div>

            <div
              class="col-12 col-md-6 px-0"
              [ngClass]="{
                'd-none': !requestDetails?.currentlyWork,
                'd-flex justify-content-between': commonService.isMobile
              }"
            >
              <p class="mb-0 mat-body-strong">Barrio:</p>
              <p>{{ requestDetails?.companyZone }}</p>
            </div>

            <div
              class="col-12 col-md-6 px-0"
              [ngClass]="{
                'd-none': !requestDetails?.currentlyWork,
                'd-flex justify-content-between': commonService.isMobile
              }"
            >
              <p class="mb-0 mat-body-strong">Dirección:</p>
              <p>{{ requestDetails?.companyAddress }}</p>
            </div>

            <div
              class="col-12 col-md-6 px-0"
              [ngClass]="{
                'd-none': !requestDetails?.currentlyWork,
                'd-flex justify-content-between': commonService.isMobile
              }"
            >
              <p class="mb-0 mat-body-strong">Teléfono:</p>
              <p>{{ requestDetails?.companyPhone }}</p>
            </div>

            <div
              class="col-12 col-md-6 px-0"
              [ngClass]="{
                'd-none': !requestDetails?.currentlyWork,
                'd-flex justify-content-between': commonService.isMobile
              }"
            >
              <p class="mb-0 mat-body-strong">Tipo de contrato:</p>
              <p>{{ requestDetails?.contractType }}</p>
            </div>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          Información académica
        </mat-expansion-panel-header>

        <div class="container-fluid">
          <div class="row">
            <div class="col-12 px-0">
              <h3 class="my-1">Ciclo académico</h3>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Año:</p>
              <p>{{ requestDetails?.academicYear }}</p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Semestre:</p>
              <p>{{ requestDetails?.academicSemester }}</p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Nivel académico:</p>
              <p>
                {{
                  gradeTypeArray
                    | arrayGetValue : requestDetails?.gradeType : "id" : "name"
                }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Nivel de formación:</p>
              <p>
                {{
                  requestDetails?.preGradeType
                    ? (preGradeTypeArray
                      | arrayGetValue
                        : requestDetails?.preGradeType
                        : "id"
                        : "name")
                    : requestDetails?.posGradeType
                    ? (posGradeTypeArray
                      | arrayGetValue
                        : requestDetails?.posGradeType
                        : "id"
                        : "name")
                    : ""
                }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">País:</p>
              <p>{{ requestDetails?.studyCountryName }}</p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Departamento:</p>
              <p>{{ requestDetails?.studyRegionName }}</p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Municipio:</p>
              <p>{{ requestDetails?.studyCityName }}</p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Barrio:</p>
              <p>{{ requestDetails?.studyZone }}</p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Dirección:</p>
              <p>{{ requestDetails?.studyAddress }}</p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Universidad:</p>
              <p>{{ requestDetails?.universityName }}</p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Programa académico:</p>
              <p>{{ requestDetails?.universityProgramName }}</p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Naturaleza de la Institución:</p>
              <p>
                {{
                  universityTypeArray
                    | arrayGetValue
                      : requestDetails?.universityType
                      : "id"
                      : "name"
                }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Modalidad de asistencia:</p>
              <p>
                {{
                  modalityArray
                    | arrayGetValue : requestDetails?.modality : "id" : "name"
                }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">
                Cuántos periodos o semestres tiene el programa académico:
              </p>
              <p>
                {{ requestDetails?.programPeriodCount }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Tipo de período:</p>
              <p>
                {{
                  periodTypeArray
                    | arrayGetValue : requestDetails?.periodType : "id" : "name"
                }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Periodo o semestre a cursar:</p>
              <p>
                {{ requestDetails?.currentPeriod }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">
                Nota promedio último periodo o semestre cursado:
              </p>
              <p>
                {{ requestDetails?.averageNotes }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">
                Valor matrícula ordinaria certificado por la universidad:
              </p>
              <p>${{ requestDetails?.enrollmentValue | number }}</p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">
                Número de SMMLV sostenimiento aprobados:
              </p>
              <p>
                {{ requestDetails?.smmlv }}
              </p>
            </div>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel
        *ngIf="
          toCreditRequestType(requestDetails?.requestType) !==
          creditRequestTypes.Suspension
        "
      >
        <mat-expansion-panel-header>
          Autorización de giro
        </mat-expansion-panel-header>

        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">
                Autorizo que el valor de la matrícula sea girado:
              </p>
              <p>
                {{
                  authorizationTypeArray
                    | arrayGetValue
                      : requestDetails?.authorizationType
                      : "id"
                      : "name"
                }}
              </p>
            </div>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel *ngIf="requestDetails?.hasRL">
        <mat-expansion-panel-header>
          Información de representante legal
        </mat-expansion-panel-header>

        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Nombres:</p>
              <p>
                {{ requestDetails?.rlName }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Apellidos:</p>
              <p>
                {{ requestDetails?.rlLastName }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Tipo de identificación:</p>
              <p>
                {{ requestDetails?.rlDocumentTypeName }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Identificación:</p>
              <p>
                {{ requestDetails?.rlIdentificationNumber }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Departamento de expedición:</p>
              <p>
                {{ requestDetails?.rlExpeditionRegionName }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Municipio de expedición:</p>
              <p>
                {{ requestDetails?.rlExpeditionCityName }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Fecha de nacimiento:</p>
              <p>
                {{ requestDetails?.rlBirthDate | date }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Departamento de nacimiento:</p>
              <p>
                {{ requestDetails?.rlRegionName }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Municipio de nacimiento:</p>
              <p>
                {{ requestDetails?.rlCityName }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Correo electrónico:</p>
              <p>
                {{ requestDetails?.rlEmail }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Teléfono:</p>
              <p>
                {{ requestDetails?.rlPhone }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">
                Actividad principal que desarrolla:
              </p>
              <p>
                {{ requestDetails?.rlEconomyActivity }}
              </p>
            </div>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel *ngIf="requestDetails?.solicitudIAS?.coDeudor">
        <mat-expansion-panel-header>
          Datos de codeudor
        </mat-expansion-panel-header>

        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Tipo de identificación:</p>
              <p>
                {{
                  requestDetails?.solicitudIAS?.coDeudor
                    ?.tipoIdentificacionNombre
                }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Identificación:</p>
              <p>
                {{ requestDetails?.solicitudIAS?.coDeudor?.identificacion }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Primer nombre:</p>
              <p>
                {{ requestDetails?.solicitudIAS?.coDeudor?.primerNombre }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Segundo nombre:</p>
              <p>
                {{ requestDetails?.solicitudIAS?.coDeudor?.segundoNombre }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Primer apellido:</p>
              <p>
                {{ requestDetails?.solicitudIAS?.coDeudor?.primerApellido }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Segundo apellido:</p>
              <p>
                {{ requestDetails?.solicitudIAS?.coDeudor?.segundoApellido }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Departamento de expedición:</p>
              <p>
                {{ requestDetails?.solicitudIAS?.coDeudor?.regionExpName }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Municipio de expedición:</p>
              <p>
                {{ requestDetails?.solicitudIAS?.coDeudor?.ciudadExpName }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Género:</p>
              <p>
                {{ requestDetails?.solicitudIAS?.coDeudor?.genero }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Fecha de nacimiento:</p>
              <p>
                {{
                  requestDetails?.solicitudIAS?.coDeudor?.fechaNacimiento | date
                }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Departamento de nacimiento:</p>
              <p>
                {{ requestDetails?.solicitudIAS?.coDeudor?.regionNacName }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Municipio de nacimiento:</p>
              <p>
                {{ requestDetails?.solicitudIAS?.coDeudor?.ciudadNacName }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Departamento de residencia:</p>
              <p>
                {{ requestDetails?.solicitudIAS?.coDeudor?.regionName }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Municipio de residencia:</p>
              <p>
                {{ requestDetails?.solicitudIAS?.coDeudor?.ciudadName }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Barrio / Vereda residencia:</p>
              <p>
                {{ requestDetails?.solicitudIAS?.coDeudor?.barrio }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Dirección de residencia:</p>
              <p>
                {{ requestDetails?.solicitudIAS?.coDeudor?.direccion }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Teléfono:</p>
              <p>
                {{ requestDetails?.solicitudIAS?.coDeudor?.telefono }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Celular:</p>
              <p>
                {{ requestDetails?.solicitudIAS?.coDeudor?.celular }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Dirección correspondencia:</p>
              <p>
                {{
                  requestDetails?.solicitudIAS?.coDeudor
                    ?.direccionCorrespondencia
                }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">
                Tipo de dirección de correspondencia:
              </p>
              <p>
                {{
                  requestDetails?.solicitudIAS?.coDeudor?.tipoDirCorrespondencia
                }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Correo electrónico:</p>
              <p>
                {{ requestDetails?.solicitudIAS?.coDeudor?.email }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Estudios realizados:</p>
              <p>
                {{ requestDetails?.solicitudIAS?.coDeudor?.estudiosRealizados }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Tipo de vivienda:</p>
              <p>
                {{ requestDetails?.solicitudIAS?.coDeudor?.tipoDeVivienda }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Estado civil:</p>
              <p>
                {{ requestDetails?.solicitudIAS?.coDeudor?.estadoCivil }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Hijos a cargo:</p>
              <p>
                {{ requestDetails?.solicitudIAS?.coDeudor?.hijosACargo }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">
                Tipo de identificación conyugue:
              </p>
              <p>
                {{
                  requestDetails?.solicitudIAS?.coDeudor
                    ?.tipoIdentificacionConyugueName
                }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Identificación conyugue:</p>
              <p>
                {{
                  requestDetails?.solicitudIAS?.coDeudor
                    ?.numeroDeIdentificacionConyugue
                }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Primer nombre conyugue:</p>
              <p>
                {{
                  requestDetails?.solicitudIAS?.coDeudor?.primerNombreConyugue
                }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Segundo nombre conyugue:</p>
              <p>
                {{
                  requestDetails?.solicitudIAS?.coDeudor?.segundoNombreConyugue
                }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Primer apellido conyugue:</p>
              <p>
                {{
                  requestDetails?.solicitudIAS?.coDeudor?.primerApellidoConyugue
                }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Segundo apellido conyugue:</p>
              <p>
                {{
                  requestDetails?.solicitudIAS?.coDeudor
                    ?.segundoApellidoConyugue
                }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Ingreso mensual conyugue:</p>
              <p>
                {{
                  requestDetails?.solicitudIAS?.coDeudor?.ingresoMensualConyugue
                    | number
                }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Tipo actividad conyugue:</p>
              <p>
                {{
                  requestDetails?.solicitudIAS?.coDeudor?.tipoActividadConyugue
                }}
              </p>
            </div>

            <div class="col-12 pt-3 px-0">
              <h2>Datos laborales de codeudor</h2>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">
                Actividad principal de codeudor:
              </p>
              <p>
                {{
                  requestDetails?.solicitudIAS?.coDeudor
                    ?.actividadPrincipalCodeudor
                }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Nombre de la empresa:</p>
              <p>
                {{ requestDetails?.solicitudIAS?.coDeudor?.nombreDeLaEmpresa }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Departamento - empresa:</p>
              <p>
                {{
                  requestDetails?.solicitudIAS?.coDeudor
                    ?.departamentoEmpresaName
                }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Municipio - empresa:</p>
              <p>
                {{
                  requestDetails?.solicitudIAS?.coDeudor?.municipioEmpresaName
                }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Dirección empresa:</p>
              <p>
                {{ requestDetails?.solicitudIAS?.coDeudor?.direccionEmpresa }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Antigüedad en empresa:</p>
              <p>
                {{
                  requestDetails?.solicitudIAS?.coDeudor?.antiguedadEnEmpresa
                }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Cargo:</p>
              <p>
                {{ requestDetails?.solicitudIAS?.coDeudor?.cargo }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Tipo de contrato:</p>
              <p>
                {{ requestDetails?.solicitudIAS?.coDeudor?.tipoDeContrato }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Teléfono empresa:</p>
              <p>
                {{ requestDetails?.solicitudIAS?.coDeudor?.telefonoEmpresa }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Otras actividades:</p>
              <p>
                {{ requestDetails?.solicitudIAS?.coDeudor?.otrasActividadades }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Activos codeudor:</p>
              <p>
                {{ requestDetails?.solicitudIAS?.coDeudor?.activosCodeudor }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Pasivos codeudor:</p>
              <p>
                {{ requestDetails?.solicitudIAS?.coDeudor?.pasivosCodeudor }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Ingreso 1 codeudor:</p>
              <p>
                {{
                  requestDetails?.solicitudIAS?.coDeudor?.nombreIngreso1Codeudor
                }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Valor ingreso 1:</p>
              <p>
                {{
                  requestDetails?.solicitudIAS?.coDeudor?.valorIngreso1 | number
                }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Ingreso 2 codeudor:</p>
              <p>
                {{
                  requestDetails?.solicitudIAS?.coDeudor?.nombreIngreso2Codeudor
                }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Valor ingreso 2:</p>
              <p>
                {{
                  requestDetails?.solicitudIAS?.coDeudor?.valorIngreso2 | number
                }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Ingreso 3 codeudor:</p>
              <p>
                {{
                  requestDetails?.solicitudIAS?.coDeudor?.nombreIngreso3Codeudor
                }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Valor ingreso 3:</p>
              <p>
                {{
                  requestDetails?.solicitudIAS?.coDeudor?.valorIngreso3 | number
                }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Egreso 1 codeudor:</p>
              <p>
                {{
                  requestDetails?.solicitudIAS?.coDeudor?.nombreEgreso1Codeudor
                }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Valor egreso 1:</p>
              <p>
                {{
                  requestDetails?.solicitudIAS?.coDeudor?.valorEgreso1 | number
                }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Egreso 2 codeudor:</p>
              <p>
                {{
                  requestDetails?.solicitudIAS?.coDeudor?.nombreEgreso2Codeudor
                }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Valor egreso 2:</p>
              <p>
                {{
                  requestDetails?.solicitudIAS?.coDeudor?.valorEgreso2 | number
                }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Egreso 3 codeudor:</p>
              <p>
                {{
                  requestDetails?.solicitudIAS?.coDeudor?.nombreEgreso3Codeudor
                }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Valor egreso 3:</p>
              <p>
                {{
                  requestDetails?.solicitudIAS?.coDeudor?.valorEgreso3 | number
                }}
              </p>
            </div>

            <div class="col-12 pt-3 px-0">
              <h2>Bienes raices de codeudor</h2>
            </div>

            <div class="col-12 pt-3 px-0">
              <h3>Referencias codeudor</h3>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Tipo referencia:</p>
              <p>
                {{ requestDetails?.solicitudIAS?.coDeudor?.tipoReferencia }}
              </p>
            </div>

            <div class="w-100"></div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Primer nombre:</p>
              <p>
                {{
                  requestDetails?.solicitudIAS?.coDeudor?.primerNombreRefencia
                }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Segundo nombre:</p>
              <p>
                {{
                  requestDetails?.solicitudIAS?.coDeudor?.segundoNombreRefencia
                }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Primer apellido:</p>
              <p>
                {{
                  requestDetails?.solicitudIAS?.coDeudor?.primerApellidoRefencia
                }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Segundo apellido:</p>
              <p>
                {{
                  requestDetails?.solicitudIAS?.coDeudor
                    ?.segundoApellidoRefencia
                }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Departamento:</p>
              <p>
                {{
                  requestDetails?.solicitudIAS?.coDeudor
                    ?.departamentoRefenciaName
                }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Municipio:</p>
              <p>
                {{
                  requestDetails?.solicitudIAS?.coDeudor?.municipioRefenciaName
                }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Barrio:</p>
              <p>
                {{ requestDetails?.solicitudIAS?.coDeudor?.barrioRefencia }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Dirección:</p>
              <p>
                {{ requestDetails?.solicitudIAS?.coDeudor?.direccionRefencia }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Teléfono:</p>
              <p>
                {{ requestDetails?.solicitudIAS?.coDeudor?.telefonoRefencia }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">
                Nombre establecimiento comercial:
              </p>
              <p>
                {{
                  requestDetails?.solicitudIAS?.coDeudor
                    ?.nombreEstablecimientoComercialRefencia
                }}
              </p>
            </div>

            <div class="col-12 pt-3 px-0">
              <h3>Bienes raices del codeudor</h3>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Tipo de bien:</p>
              <p>
                {{ requestDetails?.solicitudIAS?.coDeudor?.tipoDeBien }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Departamento:</p>
              <p>
                {{
                  requestDetails?.solicitudIAS?.coDeudor?.departamentoBienName
                }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Municipio:</p>
              <p>
                {{ requestDetails?.solicitudIAS?.coDeudor?.municipioBienName }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Número de escritura:</p>
              <p>
                {{
                  requestDetails?.solicitudIAS?.coDeudor?.numeroDeEscrituraBien
                }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">
                Número de matrícula inmobiliaria:
              </p>
              <p>
                {{
                  requestDetails?.solicitudIAS?.coDeudor
                    ?.matriculaInmobiliariaBien
                }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Valor comercial:</p>
              <p>
                {{
                  requestDetails?.solicitudIAS?.coDeudor?.valorComercialBien
                    | number
                }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Valor hipoteca:</p>
              <p>
                {{
                  requestDetails?.solicitudIAS?.coDeudor?.valorHipotecaBien
                    | number
                }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Avaluo catastral:</p>
              <p>
                {{
                  requestDetails?.solicitudIAS?.coDeudor?.avaluoCatastralBien
                    | number
                }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Dirección del bien:</p>
              <p>
                {{ requestDetails?.solicitudIAS?.coDeudor?.direccionDelBien }}
              </p>
            </div>

            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">Hipoteca a favor de:</p>
              <p>
                {{ requestDetails?.solicitudIAS?.coDeudor?.hipotecadoAFavorDe }}
              </p>
            </div>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel
        *ngIf="
          toCreditRequestType(requestDetails?.requestType) ===
          creditRequestTypes.Suspension
        "
      >
        <mat-expansion-panel-header>
          Causal de suspención de desembolso
        </mat-expansion-panel-header>

        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-md-6 px-0">
              <p class="mb-0 mat-body-strong">
                Causal de suspención de desembolso:
              </p>
              <p>
                {{
                  suspensionTypeArray
                    | arrayGetValue
                      : requestDetails?.suspensionType
                      : "id"
                      : "name"
                }}
              </p>
            </div>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          Documentos anexos
        </mat-expansion-panel-header>

        <div class="container-fluid">
          <div class="row">
            <div class="col-12 px-0">
              <mat-nav-list>
                <mat-list-item
                  *ngFor="let attach of requestDetails?.attachments"
                >
                  <mat-icon mat-list-icon>description</mat-icon>
                  <a
                    *ngIf="attach.typeName"
                    matLine
                    [href]="attach.filePath"
                    target="_blank"
                  >
                    {{ attach.typeName }}
                  </a>
                  <a matLine [href]="attach.filePath" target="_blank">
                    {{ attach.name }}
                  </a>
                  <a mat-icon-button [href]="attach.filePath" target="_blank">
                    <mat-icon>open_in_new</mat-icon>
                  </a>
                </mat-list-item>
              </mat-nav-list>
            </div>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          Historial de estados
        </mat-expansion-panel-header>

        <div class="container-fluid">
          <div
            *ngFor="let _status of requestDetails?.statusHistory"
            class="row pb-4"
          >
            <div
              class="col-12 col-md-6 px-0"
              [ngClass]="{
                'd-flex justify-content-between': commonService.isMobile
              }"
            >
              <p class="mb-0 mat-body-strong">Fecha:</p>
              <p>
                {{ _status.createdOn | date : "medium" }}
              </p>
            </div>

            <div
              class="col-12 col-md-6 px-0"
              [ngClass]="{
                'd-flex justify-content-between': commonService.isMobile
              }"
            >
              <p class="mb-0 mat-body-strong">Estado:</p>
              <p>
                {{
                  creditRenewStatusTypes
                    | arrayGetValue
                      : _status.creditRenewStatusType
                      : "id"
                      : "name"
                }}
              </p>
            </div>

            <div class="col-12 px-0">
              <p class="mb-0 mat-body-strong">Observaciones:</p>
              <p>{{ _status.comments }}</p>

              <mat-divider></mat-divider>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
