import {
  Directive,
  ElementRef,
  Optional,
  OnInit,
  Input,
  HostListener,
} from '@angular/core';
import {
  NgControl,
  ControlValueAccessor,
} from '@angular/forms';


@Directive({
  selector: '[appTruncateText]'
})
export class TruncateTextDirective implements OnInit {

  private _truncateLength: number | string = ''

  @Input('appTruncateText')
  public set truncateLength(textOption: string | number) {

    const isNumberValid = !isNaN(Number(textOption))

    if (!isNumberValid) {
      console.warn(`Note: The value ${JSON.stringify(textOption)} is not assignable to the text attribute.
        Only numbers allowed.`)
      this._truncateLength = -1
      return
    }

    this._truncateLength = Number(textOption)

    const elem = this.elementRef.nativeElement

    if (elem.tagName === 'ION-INPUT') {

      elem.getInputElement().then((_elem: HTMLInputElement) => {

        const eleValue = _elem.value;

        if (Number(textOption) < -1 && eleValue !== eleValue.trim()) {
          // initially trim the value if needed
          TruncateTextDirective.dispatchEvent(_elem, 'blur');
        }

      });

    } else {

      const eleValue = elem.value;

      if (Number(textOption) < -1 && eleValue !== eleValue.trim()) {
        // initially trim the value if needed
        TruncateTextDirective.dispatchEvent(elem, 'blur');
      }

    }
  }

  public get truncateLength() {
    return +this._truncateLength
  }

  private _valueAccessor!: ControlValueAccessor
  private _writeValue!: (value: string) => void

  constructor(
    private elementRef: ElementRef,
    @Optional() private ngControl: NgControl
  ) {}

  private static dispatchEvent(el: any, eventType: string): void {
    const event = document.createEvent('Event')
    event.initEvent(eventType, false, false)
    el.dispatchEvent(event)
  }

  private static toTruncateValue(el: any, value: string, lengthText: number | string): void {
    el.value = value.substring(0, Number(lengthText))
    TruncateTextDirective.dispatchEvent(el, 'input')
  }

  ngOnInit(): void {
    if (!this.ngControl) {
      console.warn('Note: The appTruncateText directive should be used with one of ngModel, formControl or formControlName directives.')
      return
    }

    if (!this.ngControl.valueAccessor) { return }

    this._valueAccessor = this.ngControl.valueAccessor
    this._writeValue = this._valueAccessor.writeValue

    this._valueAccessor.writeValue = (value: string) => {
      let _value = value
      if (+this.truncateLength > -1 || value.length < +this.truncateLength) {
        _value = value
      } else {
        _value = value.substring(0, Number(this.truncateLength))
      }

      if (this._writeValue) {
        this._writeValue.call(this._valueAccessor, _value)
      }

      if (value !== _value) {
        if (this._valueAccessor.registerOnChange) {
          this._valueAccessor.registerOnChange(_value);
        }

        if (this._valueAccessor.registerOnTouched) {
          this._valueAccessor.registerOnTouched(_value);
        }
      }
    }
  }

  @HostListener('blur', [
    '$event.target',
    '$event.target.value',
  ])
  onBlur(el: any, value: string): void {

    if (this.truncateLength === -1) {
      return
    }

    if (+this.truncateLength > -1 && value.length > +this.truncateLength) {
      TruncateTextDirective.toTruncateValue(el, value, this.truncateLength)
      TruncateTextDirective.dispatchEvent(el, 'blur'); // in case updateOn is set to blur
    }
  }

  @HostListener('ionBlur', [
    '$event.target',
    '$event.target.value',
  ])
  onIonBlur(el: any, value: string): void {

    if (this.truncateLength === -1) {
      return
    }

    if (+this.truncateLength > -1 && value.length > +this.truncateLength) {
      TruncateTextDirective.toTruncateValue(el, value, this.truncateLength)
      TruncateTextDirective.dispatchEvent(el, 'blur'); // in case updateOn is set to blur
    }
  }

  @HostListener('input', [
    '$event.target',
    '$event.target.value',
  ])
  onInput(el: any, value: string): void {

    if (this.truncateLength === -1) {
      return
    }

    if (+this.truncateLength > -1 && value.length > +this.truncateLength) {
      TruncateTextDirective.toTruncateValue(el, value, this.truncateLength)
    }
  }

}
