import {
  Component,
  Input,
} from '@angular/core'
import { TypesOfReports } from 'src/app/shared/models/reportType.model'
import { format } from 'date-fns'
import { saveAs } from 'file-saver'
import { take } from 'rxjs'
import Swal from 'sweetalert2'
import { DownReportsService } from './down-reports.service'

@Component({
  selector: 'app-button-pdf',
  templateUrl: './button-pdf.component.html',
  styleUrls: ['./button-pdf.component.scss']
})
export class ButtonPdfComponent {

  @Input() reportType!: TypesOfReports
  @Input() creditNumber!: number | undefined
  @Input() year: string | number
  @Input() nameReport = 'mi-report'
  @Input() labelButton = 'Descargar pdf'

  downloading = false

  constructor(private reportsService: DownReportsService) {
    this.year = format(new Date(), 'yyyy')
  }

  getDocumentPDF(): void {
    if (!this.creditNumber) {
      return
    }

    const today = format(new Date(), 'yyyy-MM-dd')
    this.downloading = true

    this.reportsService.getReportPDF(this.reportType, this.creditNumber, this.year)
      .pipe(take(1))
      .subscribe({
        next: (report) => {
          saveAs(report, `${this.nameReport}-${this.creditNumber}-${today}.pdf`)
          this.downloading = false
        },
        error: (err) => {
          this.downloading = false
          Swal.fire('Upss.. 🙁', 'Lo sentimos, ha ocurrido un error', 'error')
        }
      })
  }

}
