<app-loading-data *ngIf="!peaceAndSave; else dataLoaded"></app-loading-data>

<ng-template #dataLoaded>
  <div mat-dialog-title class="d-flex justify-content-between">
    <div class="my-2">Paz y salvo</div>

    <button mat-icon-button [mat-dialog-close]="null" color="warn">
      <mat-icon>cancel</mat-icon>
    </button>
  </div>

  <mat-dialog-content>
    <div class="d-flex flex-column">
      <div class="text-justify">
        <p>
          Que:
          {{ dialogData.creditInfoDetail.details?.userName | titlecase }}
          identificados(as) con cédula de ciudadanía No
          {{ dialogData.document }} en su condición de deudor y codeudor
          respectivamente, se encuentran a paz y salvo con la siguiente
          obligación:
        </p>
        <br />
      </div>

      <div class="d-flex justify-content-between">
        <p>No. crédito:</p>
        <p class="ms-2">{{ peaceAndSave?.creditNumber }}</p>
      </div>

      <div class="d-flex justify-content-between">
        <p>Valor aprobado:</p>
        <p class="ms-2">{{ peaceAndSave?.totalValue | number }}</p>
      </div>

      <div class="d-flex justify-content-between">
        <p>Saldo:</p>
        <p>${{ peaceAndSave?.debtValue | number }}</p>
      </div>

      <div class="d-flex justify-content-between">
        <p>Estado de cuenta de crédito:</p>
        <p class="ms-2">
          {{
            (peaceAndSave?.status | lowercase) === "ac" ? "ACTIVO" : "CANCELADO"
          }}
        </p>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="center">
    <button
      mat-flat-button
      (click)="presentReportHistory()"
      color="primary"
      class="rounded-3"
    >
      Historial
    </button>

    <div class="px-2"></div>

    <app-button-pdf
      [reportType]="typesOfReports.Compliance"
      [creditNumber]="dialogData.creditInfoDetail.creditNumber"
      [nameReport]="'paz_y_salvo'"
    ></app-button-pdf>
  </mat-dialog-actions>
</ng-template>
