import {
  Component,
  Input,
  OnInit,
} from "@angular/core"
import { Router } from "@angular/router"
import {
  GetMyMessagesViewModel,
  MaskAsReadCommand,
  UserInfoResponse,
} from '@ifc-api/models'
import { MessagesService } from '@ifc-api/services'
import { AppService } from '@shared/services'
import { take } from "rxjs"

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  @Input() notification?: GetMyMessagesViewModel
  user!: UserInfoResponse | null

  constructor(
    private router: Router,
    appService: AppService,
    private messagesService: MessagesService,
  ) {
    appService.$user.pipe(take(1))
      .subscribe({
        next: (user) => (this.user = user)
      })
  }

  ngOnInit(): void {}

  navigateToRoute(): void {
    if (!this.notification?.hyperlink) {
      return
    }

    this.markAsRead()
    this.router.navigateByUrl(this.notification.hyperlink)
  }

  markAsRead(): void {
    if (!this.user) {
      return
    }

    const data: MaskAsReadCommand = {
      messageId: this.notification?.id,
      userId: this.user.id!
    }

    this.messagesService.apiMessagesMarkAsReadPost$Json({
      body: data
    })
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.notification!.wasRead = res.marked
        }
      })
  }

}
