<mat-sidenav-container class="h-100">
  <mat-sidenav
    *ngIf="appService.isLogged | async"
    #navigationSidenav
    [mode]="commonService.isTabletOrMobile ? 'over' : 'side'"
    [opened]="appService.isAdmin && !commonService.isTabletOrMobile"
    class="sidenav py-2"
    position="start"
  >
    <ng-container *ngTemplateOutlet="itemsMenuMainTemplate" />
  </mat-sidenav>

  <mat-sidenav
    *ngIf="appService.isLogged | async"
    #profileSidenav
    mode="over"
    class="sidenav py-2"
    style="width: 350px"
    position="end"
  >
    <ng-container *ngTemplateOutlet="itemsMenuProfileTemplate" />
  </mat-sidenav>

  <mat-sidenav-content style="overflow-x: inherit">
    <div class="d-flex flex-column h-100">
      <app-toolbar
        *ngIf="appService.isLogged | async"
        (navigationSidenav)="navigationSidenav.toggle()"
        (profileSidenav)="profileSidenav.toggle()"
      ></app-toolbar>

      <div class="flex-column flex-grow-1">
        <router-outlet></router-outlet>
      </div>

      <app-footer *appIfRol="roles.USUARIO"></app-footer>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #itemsMenuMainTemplate>
  <div class="rounded px-4 py-2" style="width: calc(100% - 50px)">
    <a [routerLink]="appService.redirectRouteHome">
      <img
        src="/assets/images/logo_ifc_azul.png"
        class="img-fluid"
        alt="Logo"
      />
    </a>
  </div>

  <mat-nav-list class="menu__main__nav">
    <ng-container *ngFor="let item_ of items">
      <ng-container *appIfRol="item_.permissions">
        <ng-container
          *ngTemplateOutlet="itemMainTemplate; context: { item: item_ }"
        ></ng-container>

        <mat-list-item
          *ngIf="item_.subItems.length === 0 && item_.isHref"
          (click)="
            commonService.isTabletOrMobile ? navigationSidenav.toggle() : true
          "
        >
          <mat-icon matListItemIcon>
            {{ item_.icon }}
          </mat-icon>
          <a
            matListItemTitle
            [href]="item_.routerLink"
            target="_blank"
            class="no-color"
          >
            {{ item_.label }}
          </a>
        </mat-list-item>

        <mat-accordion *ngIf="item_.subItems.length > 0" displayMode="flat">
          <mat-expansion-panel
            [expanded]="item_.isActive"
            style="box-shadow: none"
          >
            <mat-expansion-panel-header
              [ngClass]="{ 'item-active': item_.isActive }"
            >
              <mat-panel-title>
                <mat-icon
                  matListItemIcon
                  class="ps-1"
                  style="padding-right: 0.9em"
                  [color]="item_.isActive ? 'accent' : undefined"
                >
                  {{ item_.icon }}
                </mat-icon>
                {{ item_.label }}
              </mat-panel-title>
            </mat-expansion-panel-header>

            <mat-nav-list>
              <ng-container *ngFor="let subitem_ of item_.subItems">
                <ng-container
                  *ngTemplateOutlet="
                    itemMainTemplate;
                    context: { item: subitem_ }
                  "
                ></ng-container>
              </ng-container>
            </mat-nav-list>
          </mat-expansion-panel>
        </mat-accordion>
      </ng-container>
    </ng-container>
  </mat-nav-list>
</ng-template>

<ng-template #itemMainTemplate let-item_="item">
  <mat-list-item
    *ngIf="item_.subItems?.length === 0 && !item_.isHref"
    (click)="commonService.isTabletOrMobile ? navigationSidenav.toggle() : true"
    class="item_main__nav mat-mdc-list-item-interactive"
    [class]="itemActive.isActive ? 'item-active' : undefined"
    [routerLink]="[item_.routerLink]"
  >
    <mat-icon matListItemIcon>
      {{ item_.icon }}
    </mat-icon>
    <a
      matListItemTitle
      [routerLink]="[item_.routerLink]"
      class="no-color"
      routerLinkActive="route-active"
      #itemActive="routerLinkActive"
    >
      {{ item_.label }}
    </a>
  </mat-list-item>
</ng-template>

<ng-template #itemsMenuProfileTemplate>
  <mat-list>
    <mat-list-item>
      <h1 matListItemTitle>Perfil de usuario</h1>
      <button
        (click)="profileSidenav.toggle()"
        matListItemMeta
        mat-icon-button
        color="accent"
      >
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-list-item>
  </mat-list>

  <div class="container-fluid py-2">
    <div class="row">
      <div class="col-3 pe-2 pt-1">
        <div class="avatar">
          <img src="/assets/images/avatar.svg" alt="Avatar" />
        </div>
      </div>

      <div class="col-9 ps-0">
        <h3 class="mb-0" style="line-height: 1.2em">
          {{ (appService.$user | async)?.fullName }}
        </h3>
        <p>
          <small> {{ (appService.$user | async)?.email }} </small>
        </p>

        <button (click)="logOut()" mat-flat-button color="accent">
          Cerrar sesión
        </button>
      </div>
    </div>
  </div>

  <mat-nav-list>
    <mat-divider> </mat-divider>

    <mat-list-item
      [routerLink]="['/profile']"
      (click)="profileSidenav.toggle()"
    >
      <mat-icon matListItemIcon color="primary">person</mat-icon>
      <a matListItemTitle class="no-color">Mi perfil</a>
      <p matListItemLine>Configuración de la cuenta</p>
    </mat-list-item>

    <mat-list-item
      [routerLink]="['/notifications']"
      (click)="profileSidenav.toggle()"
    >
      <mat-icon
        matListItemIcon
        color="primary"
        [matBadge]="
          appService.notificationsActives.length > 0
            ? appService.notificationsActives.length
            : undefined
        "
        matBadgeColor="accent"
      >
        notifications
      </mat-icon>
      <a matListItemTitle class="no-color">Mis notificaciones</a>
      <p matListItemLine>Bandeja de notificaciones</p>
    </mat-list-item>

    <mat-list-item
      *appIfRol="roles.USUARIO"
      [routerLink]="['/citizen-attention']"
      (click)="profileSidenav.toggle()"
    >
      <mat-icon matListItemIcon color="primary">speaker_notes</mat-icon>
      <a matListItemTitle class="no-color">PQRS</a>
      <p matListItemLine>Atención al cliente</p>
    </mat-list-item>
  </mat-nav-list>
</ng-template>
