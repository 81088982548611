<div class="bg__primary">
  <div [ngClass]="appService.isAdmin ? 'container-fluid' : 'container'">
    <mat-toolbar color="primary">
      <mat-toolbar-row class="px-0">
        <button
          *ngIf="commonService.isMobile || appService.isAdmin"
          mat-icon-button
          (click)="navigationSidenav.emit()"
        >
          <mat-icon>menu</mat-icon>
        </button>

        <a
          [routerLink]="appService.redirectRouteHome"
          *appIfRol="roles.USUARIO"
          class="logo-blanco h-100 me-5 d-none d-md-block"
        >
        </a>

        <div *appIfRol="roles.USUARIO" class="align-self-end">
          <div *ngIf="!commonService.isMobile">
            <a
              (click)="showSubItemsHome = !showSubItemsHome"
              mat-button
              class="button-menu me-2"
              [ngClass]="{
                'item-active-menu': isHome
              }"
            >
              Inicio
            </a>

            <a
              [routerLink]="['profile']"
              routerLinkActive="item-active-menu"
              mat-button
              class="button-menu me-2"
            >
              Mi perfil
            </a>
            <a
              [routerLink]="['simulator']"
              routerLinkActive="item-active-menu"
              mat-button
              class="button-menu me-2"
            >
              Simulador
            </a>
            <a
              mat-button
              href="https://ifcmovil.tawk.help/"
              target="_blank"
              class="button-menu me-2"
            >
              Centro de ayuda
            </a>
          </div>
        </div>

        <div class="mx-auto">
          <span
            *appIfRol="[
              roles.ADMNISTRADOR,
              roles.CREDITOS,
              roles.MODERADOR,
              roles.PAGOS,
              roles.PQR,
              roles.MENSAJES
            ]"
          >
            {{ metaTags.titleToolbar | async }}
          </span>
        </div>

        <mat-chip-listbox>
          <mat-chip
            (click)="profileSidenav.emit()"
            class="pointer bg-transparent"
          >
            <img matChipAvatar src="/assets/images/avatar.svg" alt="Avatar" />
            <span class="text-white">
              {{ user?.name }}
            </span>
          </mat-chip>
        </mat-chip-listbox>
      </mat-toolbar-row>
    </mat-toolbar>
  </div>
</div>

<div *appIfRol="roles.USUARIO">
  <div
    *ngIf="!commonService.isMobile && showSubItemsHome"
    [@openClose]="showSubItemsHome ? 'open' : 'close'"
    class="bg__white"
  >
    <div class="container">
      <mat-toolbar class="bg-transparent">
        <mat-toolbar-row class="px-0">
          <a
            routerLink="home"
            routerLinkActive="item-active-submenu"
            mat-button
            color="primary"
            class="me-2"
          >
            Dashboard
          </a>
          <a
            mat-button
            [routerLink]="['credits']"
            routerLinkActive="item-active-submenu"
            color="primary"
            class="me-2"
          >
            Mis créditos
          </a>
          <a
            mat-button
            [routerLink]="['payments']"
            routerLinkActive="item-active-submenu"
            color="primary"
            class="me-2"
          >
            Pagos
          </a>
          <a
            mat-button
            [matBadge]="
              this.appService.notificationsActives.length > 0
                ? this.appService.notificationsActives.length
                : undefined
            "
            matBadgeColor="accent"
            [routerLink]="['notifications']"
            routerLinkActive="item-active-submenu"
            color="primary"
            class="me-2"
          >
            Notificaciones
          </a>
          <a
            mat-button
            [routerLink]="['citizen-attention']"
            routerLinkActive="item-active-submenu"
            color="primary"
            class="me-2"
          >
            Atención al ciudadano
          </a>
        </mat-toolbar-row>
      </mat-toolbar>
    </div>
  </div>
</div>
