import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgApexchartsModule } from "ng-apexcharts";

import { ChartBarComponent } from './chart-bar.component';


@NgModule({
  declarations: [ChartBarComponent],
  imports: [
    CommonModule,
    RouterModule,

    MatCardModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    NgApexchartsModule,
  ],
  exports: [ChartBarComponent]
})
export class ChartBarModule { }
