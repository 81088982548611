import { Injectable } from "@angular/core"
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from "@angular/router"
import { AppService } from '@shared/services'
import {
  map,
  Observable,
} from "rxjs"

@Injectable({
  providedIn: 'root'
})
export class RolGuard {

  constructor(private appService: AppService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const roles: string | string[] = route.data['roles']

    if (roles) {

      return this.appService.$user.pipe(
        map(user => {

          if (user) {
            const userRoles = user.roles || []

            if (typeof roles === 'string') {

              return userRoles.includes(roles)

            } else if (Array.isArray(roles)) {

              const match: boolean[] = []

              roles.forEach((value) => {
                const exists = userRoles.includes(value)
                match.push(exists)
              })

              return match.includes(true)
            }

            return false
          } else {
            return false
          }

        })
      )

    } else {
      return true
    }

  }

}
