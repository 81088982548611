import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appOlyAlpha]',
})
export class OnlyAlphaDirective {
  @Input() allowSpaces: boolean = true; // Permitir espacios por defecto

  constructor(private control: NgControl) {}

  @HostListener('input', ['$event'])
  onInput(event: Event) {
    const input = event.target as HTMLInputElement;

    // Expresión regular: letras con tildes
    const regex = this.allowSpaces ? /^[a-zA-ZÀ-ÿ\s]*$/ : /^[a-zA-ZÀ-ÿ]*$/;

    if (!regex.test(input.value)) {
      // Eliminar caracteres no válidos
      input.value = input.value.replace(
        this.allowSpaces ? /[^a-zA-ZÀ-ÿ\s]/g : /[^a-zA-ZÀ-ÿ]/g,
        ''
      );
      this.control.control?.setValue(input.value); // Actualizar el FormControl
    }
  }
}
