<mat-form-field appearance="outline" class="w-100 form_field_3">
  <mat-label> {{ label }} </mat-label>
  <input
    matInput
    [matDatepicker]="dp"
    [formControl]="dateControl"
    [min]="minDate"
    [max]="maxDate"
    [readonly]="readonly"
    (click)="readonly ? dp.open() : null"
  />
  <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
  <mat-datepicker
    #dp
    touchUi
    startView="multi-year"
    (yearSelected)="chosenYearHandler($event, dp)"
  >
  </mat-datepicker>
</mat-form-field>
