import {
  Component,
  Inject,
} from "@angular/core"
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog"
import {
  ComplianceViewModel,
  CreditInfo,
} from '@ifc-api/models'
import { CreditInfoService } from '@ifc-api/services'
import { TypesOfReports } from '@shared/models'
import { CommonService } from '@shared/services'
import { take } from "rxjs"
import Swal from "sweetalert2"
import { ReportHistoryComponent } from '../report-history/report-history.component'

@Component({
  selector: 'app-peace-and-save',
  templateUrl: './peace-and-save.component.html',
  styleUrls: ['./peace-and-save.component.scss']
})
export class PeaceAndSaveComponent {

  peaceAndSave?: ComplianceViewModel
  typesOfReports = TypesOfReports

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<PeaceAndSaveComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    private commonService: CommonService,
    private creditInforService: CreditInfoService,
  ) {
    this.getPeaceAndSave()
  }

  getPeaceAndSave(): void {
    const creditNumber = this.dialogData.creditInfoDetail.creditNumber || 0

    this.creditInforService
      .apiCreditInfoComplianceDocumentIdCreditNumberGet$Json({
        documentId: this.dialogData.document,
        creditNumber,
      })
      .pipe(take(1))
      .subscribe({
        next: response => {
          this.peaceAndSave = response
        },
        error: (err) => {
          const message = this.commonService.getMessageErrorHTTP(err)

          Swal.fire({
            icon: 'error',
            title: 'Upss.. 🙁',
            text: message,
            confirmButtonText: 'Aceptar'
          }).then(() => this.dialogRef.close())
        }
      })
  }

  presentReportHistory(): void {
    const dialogRef = this.dialog.open(ReportHistoryComponent, {
      data: {
        reportType: this.typesOfReports.Compliance,
        creditNumber: this.dialogData.creditInfoDetail.creditNumber,
      }
    })
  }

}

interface DialogData {
  document: string
  creditInfoDetail: CreditInfo
}
