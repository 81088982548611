import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ArrayGetValuePipe } from './array-get-value.pipe';



@NgModule({
  declarations: [ArrayGetValuePipe],
  imports: [
    CommonModule
  ],
  exports: [ArrayGetValuePipe]
})
export class ArrayGetValueModule { }
