import { Injectable } from "@angular/core"
import { Router } from "@angular/router"
import {
  AuthenticationResponse,
  GetMyMessagesViewModel,
  UserInfoResponse,
  WebProcessViewModel,
} from '@ifc-api/models'
import {
  AdminService,
  UserInfoService,
} from '@ifc-api/services'
import { addSeconds } from "date-fns"
import {
  firstValueFrom,
  map,
  Observable,
  ReplaySubject,
  take,
} from "rxjs"

@Injectable({
  providedIn: 'root'
})
export class AppService {

  private authStateSubject = new ReplaySubject<UserInfoResponse | null>(0)
  $user = this.authStateSubject.asObservable()
  redirectRoute = ''
  private _redirectRouteHome = '/home'
  redirectLogin = '/auth/login'
  expireToken: string | null = null
  isAdmin = false
  notificationsActives: GetMyMessagesViewModel[] = []
  ifEnabledItems: WebProcessViewModel[] = []

  constructor(
    private router: Router,
    private adminService: AdminService,
    private userInfoService: UserInfoService,
  ) {}

  /**
   * Retorna falso o verdadero depende si está logueado
   */
  get isLogged(): Observable<boolean> {
    return this.authStateSubject.asObservable().pipe(map(user => !!user))
  }

  get redirectRouteHome(): string {
    return this._redirectRouteHome
  }

  /**
   * Verifica si hay un usuario previamente logueado
   */
  initializeApp(): Promise<boolean | string> {
    return new Promise(async (resolve, reject) => {

      const accessToken = localStorage.getItem('accessToken')
      this.expireToken = localStorage.getItem('expireToken')

      // Si existe asigna el token a una variable
      if (accessToken && Number(this.expireToken) > new Date().getTime()) {
        try {
          const user = await this.getUser()
          this.authStateSubject.next(user)

          this._redirectRouteHome = this.getRouteRedirect(user)

          this.getIfEnabledItems()
        } catch (error) {
          // El token no es válido
          this.authStateSubject.next(null)
          this._redirectRouteHome = '/auth/login'

          await this.router.navigate([this.redirectRouteHome], { replaceUrl: true })
        }

        resolve(true)
      } else {
        this.logOut()
        this.authStateSubject.next(null)
        resolve('No hay token!')
      }
    })
  }

  getRouteRedirect(user: UserInfoResponse): string {
    const matchUser = user.roles?.includes(Roles.USUARIO)
    let routeRedirect = ''

    if (matchUser) {
      routeRedirect = '/home'
      this.isAdmin = false
    } else {
      routeRedirect = '/admin'
      this.isAdmin = true
    }

    return routeRedirect
  }

  /**
   * Asigna los datos del usuario a las variables locales
   */
  configUserSession(authLogin?: AuthenticationResponse): Promise<void> {
    const storageDataLocal = (nombre: string, valor: string): Promise<void> => {
      return new Promise((resolve, reject) => {
        localStorage.setItem(nombre, valor)
        resolve()
      })
    }

    return new Promise(async (resolve, reject) => {
      if (authLogin && authLogin.token) {
        this.expireToken = addSeconds(new Date(), 3600).getTime().toString()

        await storageDataLocal('accessToken', `Bearer ${authLogin.token}`)
        await storageDataLocal('expireToken', this.expireToken)
      }

      try {
        const user = await this.getUser()
        this.authStateSubject.next(user)
        this._redirectRouteHome = this.getRouteRedirect(user)

        this.getIfEnabledItems()

        resolve()
      } catch (error) {
        this.logOut()
        reject(error)
      }
    })
  }

  /**
   * Obtiene los datos del usuario logueado
   */
  private getUser(): Promise<UserInfoResponse> {
    return firstValueFrom(this.userInfoService.apiUserInfoProfileGet$Json())
  }

  /**
   * Obtiene los permisos para habilitar o deshabilitar servicios
   */
  private getIfEnabledItems(): void {
    this.adminService.apiAdminProcessListGet$Json()
      .pipe(take(1))
      .subscribe({
        next: (items) => this.ifEnabledItems = items
      })
  }

  /**
   * Cerrar sesión
   */
  logOut(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('expireToken')
      this.authStateSubject.next(null)
      this.isAdmin = false
      this.redirectRoute = ''
      resolve()
    })
  }

}

export type Roles = 'Administrator' |
  'Moderator' |
  'PQR' |
  'Payments' |
  'Credits' |
  'User' |
  'Messages' |
  'Analista'

export const Roles = {
  ADMNISTRADOR: 'Administrator' as Roles,
  MODERADOR: 'Moderator' as Roles,
  PQR: 'PQR' as Roles,
  PAGOS: 'Payments' as Roles,
  CREDITOS: 'Credits' as Roles,
  USUARIO: 'User' as Roles,
  MENSAJES: 'Messages' as Roles,
  ANALISTA: 'Analista' as Roles,
}

export type RolesNumber = 1 |
  2 |
  3 |
  4 |
  5 |
  6 |
  7 |
  8

export const RolesNumber = {
  ADMNISTRADOR: 1 as RolesNumber,
  MODERADOR: 2 as RolesNumber,
  PQR: 3 as RolesNumber,
  PAGOS: 4 as RolesNumber,
  CREDITOS: 5 as RolesNumber,
  USUARIO: 6 as RolesNumber,
  MENSAJES: 7 as RolesNumber,
  ANALISTA: 8 as RolesNumber,
}
