import { DecimalPipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  CreditInfo,
  PayCommand,
  PaymentStatusByCreditQuery,
  PaymentStatusByCreditViewModel,
  UserInfoResponse,
} from '@ifc-api/models';
import { PaymentService } from '@ifc-api/services';
import { AppService, CommonService } from '@shared/services';
import { take } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-payment-pse',
  templateUrl: './payment-pse.component.html',
  styleUrls: ['./payment-pse.component.scss'],
})
export class PaymentPseComponent implements OnInit {
  private user!: UserInfoResponse;

  paymentGroup: UntypedFormGroup;
  minPaymentIsZero = false;
  paymentStatus?: PaymentStatusByCreditViewModel;

  loadingData = true;
  paying = false;

  constructor(
    private decimalPipe: DecimalPipe,
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<PaymentPseComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    private router: Router,
    appService: AppService,
    private commonService: CommonService,
    private paymentService: PaymentService
  ) {
    this.paymentGroup = this.formBuilderPaymentGroup;
    this.paymentGroup.setValidators(
      this.commonService.requiredIfValidator('toPay', 'amount', 'otro')
    );

    appService.$user.pipe(take(1)).subscribe({
      next: (user) => {
        if (user) {
          this.user = user;
          this.checkHavePendingPayments();
        }
      },
    });
  }

  ngOnInit(): void {
    this.minPaymentIsZero =
      this.dialogData.creditInfoDetail.details?.minimumValue === 0;
    this.paymentGroup.controls['toPay'].setValue(
      this.minPaymentIsZero ? 'max' : 'min'
    );
  }

  private get formBuilderPaymentGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      toPay: [null, [Validators.required]],
      amount: [null],
      description: [null, [Validators.required, Validators.minLength(3)]],
      termAndConditions: [false, [Validators.requiredTrue]],
    });
  }

  checkHavePendingPayments(): void {
    const data: PaymentStatusByCreditQuery = {
      creditId: this.dialogData.creditInfoDetail.creditId,
      userId: this.user.id!,
    };

    this.paymentService
      .apiPaymentHavePendingPaymentsPost$Json({
        body: data,
      })
      .pipe(take(1))
      .subscribe({
        next: (paymentStatus) => {
          this.paymentStatus = paymentStatus;

          if (paymentStatus.hasPendingPayments) {
            Swal.fire(paymentStatus.message ?? '', undefined, 'warning').then(
              () => this.dialogRef.close()
            );
          } else {
            this.loadingData = false;
          }
        },
        error: (err) => {
          const message = this.commonService.getMessageErrorHTTP(
            err,
            'Lo sentimos, ha ocurrido un error'
          );
          Swal.fire('Ups.. 😟', message, 'error').then(() =>
            this.dialogRef.close()
          );
        },
      });
  }

  redirectPaymentsHistory(): void {
    this.dialogRef.close();
    this.router.navigate(['payments']);
  }

  validateAmountGetMessage(): string | null {
    const { amount } = this.paymentGroup.value;
    const maxPayment = <number>(
      this.dialogData.creditInfoDetail.details?.totalPayment
    );

    if (Number(amount) > maxPayment) {
      return null;
    }

    if (Number(amount) <= 0) {
      return 'El valor debe ser mayor a cero';
    }

    return null;
  }

  toPay(): void {
    this.paymentGroup.markAllAsTouched();

    if (this.paymentGroup.invalid) {
      return;
    }

    let amountToPay: string | number = 0;
    const { toPay, amount, description } = this.paymentGroup.value;

    if (toPay === 'min') {
      amountToPay = <number>(
        this.dialogData.creditInfoDetail.details?.minimumValue
      );
    } else if (toPay === 'max') {
      amountToPay = <number>(
        this.dialogData.creditInfoDetail.details?.totalPayment
      );
    } else if (toPay === 'otro') {
      const messageValidation = this.validateAmountGetMessage();

      if (messageValidation) {
        this.commonService.presentSnackBar(messageValidation);
        return;
      }

      amountToPay = amount;
    }

    const amountToPayString = <string>this.decimalPipe.transform(amountToPay);
    const message = `Valor a pagar: <br /> <h2>$ ${amountToPayString}</h2>
    <small>Al dar clic en Confirmar será redireccionado al Sistema de Pagos Seguros PSE,
    donde se realizará la transacción Bancaria. El IFC nunca solicitará sus datos privados 
    (Claves Bancarias o Nro de Tarjeta)</small> <br />`;

    Swal.fire({
      title: 'Confirme su pago',
      html: message,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
    }).then((_resultSwal) => {
      if (_resultSwal.isConfirmed) {
        this.paying = true;

        const data: PayCommand = {
          userId: this.user.id!,
          creditId: this.dialogData.creditInfoDetail.creditId,
          paymentValue: <number>amountToPay,
          description,
        };

        this.paymentService
          .apiPaymentPost$Json({
            body: data,
          })
          .pipe(take(1))
          .subscribe({
            next: (res) => {
              if (!res.code) {
                window.location.assign(<string>res.urlPayment);
              } else {
                this.paying = false;
                Swal.fire('Ups.. 😟', res.message ?? '', 'error');
              }
            },
            error: (err) => {
              this.paying = false;
              const message = this.commonService.getMessageErrorHTTP(
                err,
                'Ha ocurrido un error'
              );
              Swal.fire('Ups.. 😟', message, 'error');
            },
          });
      }
    });
  }
}

interface DialogData {
  document: string;
  creditInfoDetail: CreditInfo;
}
