<app-loading-data *ngIf="!status; else dataLoaded"></app-loading-data>

<ng-template #dataLoaded>
  <div mat-dialog-title class="d-flex justify-content-between">
    <div class="my-2">Estado de cuenta de crédito</div>

    <button mat-icon-button [mat-dialog-close]="null" color="warn">
      <mat-icon>cancel</mat-icon>
    </button>
  </div>

  <mat-dialog-content>
    <div
      *ngIf="!status?.observation; else templateObservation"
      class="d-flex flex-column"
    >
      <div class="d-flex justify-content-between">
        <p>Fecha:</p>
        <p class="ms-2">{{ status?.startDate | date }}</p>
      </div>

      <div class="d-flex justify-content-between">
        <p>Titular:</p>
        <p class="ms-2">{{ status?.userName | titlecase }}</p>
      </div>

      <div class="d-flex justify-content-between">
        <p>Identificación:</p>
        <p class="ms-2">{{ dialogData.document }}</p>
      </div>

      <div class="d-flex justify-content-between">
        <p>Crédito:</p>
        <p class="ms-2">{{ status?.creditType }}</p>
      </div>

      <div class="d-flex justify-content-between">
        <p>Dirección registrada:</p>
        <p class="ms-2">{{ status?.address }}</p>
      </div>

      <div class="d-flex justify-content-between">
        <p>Municipio:</p>
        <p class="ms-2">{{ dialogData.creditInfoDetail.details?.cityName }}</p>
      </div>

      <div class="d-flex justify-content-between">
        <p>Número de obligación:</p>
        <p class="ms-2">{{ dialogData.creditInfoDetail.creditNumber }}</p>
      </div>

      <div class="d-flex justify-content-between">
        <p>Tipo de cartera:</p>
        <p class="ms-2 text-end">{{ status?.type }}</p>
      </div>

      <div class="d-flex justify-content-between">
        <p>Valor mínimo a pagar:</p>
        <p class="ms-2">
          {{ dialogData.creditInfoDetail.details?.minimumValue | number }}
        </p>
      </div>

      <div class="d-flex justify-content-between">
        <p>Valor total a pagar:</p>
        <p class="ms-2">${{ status?.debtValue | number }}</p>
      </div>

      <div class="d-flex justify-content-between">
        <p>Fecha de corte:</p>
        <p class="ms-2">{{ status?.paymentDate | date }}</p>
      </div>
    </div>

    <ng-template #templateObservation>
      <div>
        <p>{{ status?.observation }}</p>
        <br />
        <p>
          No es posible generar tu reporte, por favor comunicate con nosotros.
        </p>
      </div>
    </ng-template>
  </mat-dialog-content>

  <mat-dialog-actions align="center">
    <button
      mat-flat-button
      (click)="presentReportHistory()"
      color="primary"
      class="rounded-3"
    >
      Historial
    </button>

    <div class="px-2"></div>

    <app-button-pdf
      *ngIf="!status?.observation"
      [reportType]="typesOfReports.Status"
      [creditNumber]="dialogData.creditInfoDetail.creditNumber"
      [nameReport]="'estado_de_cuenta'"
    ></app-button-pdf>
  </mat-dialog-actions>
</ng-template>
