<button
  mat-flat-button
  (click)="getDocumentPDF()"
  color="accent"
  [disabled]="downloading"
  class="rounded-3"
>
  {{ labelButton }}
  <mat-spinner
    *ngIf="downloading"
    [diameter]="20"
    class="d-inline-flex ms-2"
    color="primary"
  ></mat-spinner>
</button>
