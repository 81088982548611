import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { UploadFilesComponent } from './upload-files.component';

@NgModule({
  declarations: [UploadFilesComponent],
  imports: [
    CommonModule,
    FormsModule,

    MatProgressBarModule,
    MatButtonModule,
    MatIconModule,
  ],
  exports: [UploadFilesComponent]
})
export class UploadFilesModule {}
