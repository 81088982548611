<mat-card matRipple (click)="navigateToRoute()" class="px-0 pointer">
  <mat-list>
    <mat-list-item class="h-100">
      <mat-icon mat-list-icon color="accent">
        {{ notification?.wasRead ? "notifications" : "notifications_active" }}
      </mat-icon>

      <div mat-line>
        <p [ngClass]="{ bold: !notification?.wasRead }">
          {{ notification?.title }}
        </p>
      </div>

      <div mat-line class="text-wrap">
        <p [ngClass]="{ bold: !notification?.wasRead }">
          {{ notification?.content }}
        </p>
      </div>

      <button mat-icon-button>
        <mat-icon>chevron_right</mat-icon>
      </button>
    </mat-list-item>
  </mat-list>
</mat-card>
