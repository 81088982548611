<div mat-dialog-title class="d-flex justify-content-between">
  <div class="my-2">Recibo de pago</div>

  <button mat-icon-button [mat-dialog-close]="null" color="warn">
    <mat-icon>cancel</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <div class="d-flex justify-content-between">
    <p class="mb-1">Valor mínimo a pagar</p>
    <p class="mb-1 ms-2 text-end">
      ${{ dialogData.creditInfoDetail.details?.minimumValue | number }}
    </p>
  </div>

  <br />

  <p *ngIf="minimumValue > 0; else valorMinimo0">
    Descarga el Recibo de Pago e imprime una copia que debes entregar en las
    Oficinas del Banco
  </p>

  <ng-template #valorMinimo0>
    <p>
      Tu cuenta se encuentra al dia, actualmente no tienes ningun pago
      pendiente.
    </p>
  </ng-template>
</mat-dialog-content>

<mat-dialog-actions align="center">
  <button
    mat-flat-button
    (click)="presentReportHistory()"
    color="primary"
    class="rounded-3"
  >
    Historial
  </button>

  <div class="px-2"></div>

  <app-button-pdf
    *ngIf="minimumValue > 0"
    [reportType]="typesOfReports.Payment"
    [creditNumber]="dialogData.creditInfoDetail.creditNumber"
    [nameReport]="'recibo_de_pago'"
  ></app-button-pdf>
</mat-dialog-actions>
