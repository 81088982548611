<mat-card class="rounded-3">
  <mat-card-content>
    <apx-chart
      #chart
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [dataLabels]="chartOptions.dataLabels"
      [plotOptions]="chartOptions.plotOptions"
      [yaxis]="chartOptions.yaxis"
      [legend]="chartOptions.legend"
      [fill]="chartOptions.fill"
      [stroke]="chartOptions.stroke"
      [tooltip]="chartOptions.tooltip"
      [xaxis]="chartOptions.xaxis"
    ></apx-chart>

    <div *ngIf="showButtonMoreDetail" class="d-flex justify-content-end">
      <a mat-button [routerLink]="[routeMoreDetail]" color="accent">
        Más detalles..
      </a>
    </div>
  </mat-card-content>
</mat-card>

<ng-container *ngIf="showTable">
  <div class="w-100 py-2"></div>

  <mat-card class="rounded-3">
    <mat-card-content>
      <div class="overflow-auto">
        <table mat-table #table [dataSource]="dataSource">
          <ng-container
            [matColumnDef]="column"
            *ngFor="let column of displayedColumns"
          >
            <th mat-header-cell *matHeaderCellDef class="px-2">{{ column }}</th>
            <td mat-cell *matCellDef="let element" class="px-2">
              {{ element[column] }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>

      <p *ngIf="!dataSource.data.length" class="mt-2 mb-0">No hay datos 😄</p>

      <mat-paginator
        [pageSizeOptions]="[5, 10, 20]"
        showFirstLastButtons
      ></mat-paginator>
    </mat-card-content>
  </mat-card>
</ng-container>
