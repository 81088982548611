<mat-card class="rounded-3">
  <mat-card-content>
    <p class="text-center">{{ title }}</p>

    <apx-chart
      #chart
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [labels]="chartOptions.labels"
      [responsive]="chartOptions.responsive"
      [legend]="chartOptions.legend"
      [tooltip]="chartOptions.tooltip"
    ></apx-chart>
  </mat-card-content>
</mat-card>
