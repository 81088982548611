import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingDataComponent } from './loading-data.component';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';



@NgModule({
  declarations: [LoadingDataComponent],
  imports: [
    CommonModule,

    MatProgressSpinnerModule,
  ],
  exports: [LoadingDataComponent]
})
export class LoadingDataModule { }
