import {
  Component,
  Inject,
} from "@angular/core"
import { MatDatepicker } from "@angular/material/datepicker"
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog"
import {
  CreditInfo,
  TaxReportViewModel,
} from '@ifc-api/models'
import { CreditInfoService } from '@ifc-api/services'
import { TypesOfReports } from '@shared/models'
import { CommonService } from '@shared/services'
import {
  formatISO,
  getYear,
  subYears,
} from "date-fns"
import { take } from "rxjs"
import Swal from "sweetalert2"
import { ReportHistoryComponent } from '../report-history/report-history.component'

@Component({
  selector: 'app-income-statement',
  templateUrl: './income-statement.component.html',
  styleUrls: ['./income-statement.component.scss']
})
export class IncomeStatementComponent {

  fechaRenta = ''

  yearReport!: number
  yearReportDate: Date

  yearReportMin: number
  yearReportMinDate: Date

  yearReportMax: number
  yearReportMaxDate: Date

  incomeStatement?: TaxReportViewModel
  typesOfReports = TypesOfReports

  isLoading = false

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<IncomeStatementComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    private creditInforService: CreditInfoService,
    private commonService: CommonService,
  ) {
    this.yearReportMinDate = subYears(new Date(), 4)
    this.yearReportMin = getYear(this.yearReportMinDate)

    this.yearReportMaxDate = subYears(new Date(), 1)
    this.yearReportMax = getYear(this.yearReportMaxDate)

    this.yearReportDate = new Date(formatISO(this.yearReportMaxDate))
    this.getIncomeStatement()
  }

  getIncomeStatement(): void {
    const creditNumber = this.dialogData.creditInfoDetail.creditNumber || 0
    this.yearReport = getYear(new Date(this.yearReportDate))

    this.creditInforService
      .apiCreditInfoTaxReportDocumentIdCreditNumberYearGet$Json({
        documentId: this.dialogData.document,
        creditNumber,
        year: this.yearReport,
      })
      .pipe(take(1))
      .subscribe({
        next: response => {
          this.incomeStatement = response
          this.isLoading = false
        },
        error: (err) => {
          const message = this.commonService.getMessageErrorHTTP(err)

          Swal.fire({
            icon: 'error',
            title: 'Upss.. 🙁',
            text: message,
            confirmButtonText: 'Aceptar'
          }).then(() => {
            this.dialogRef.close()
          })
        }
      })
  }

  setNewDate(date: Date): void {
    this.isLoading = true
    this.yearReportDate = date
    this.getIncomeStatement()
  }

  chosenYearHandler(event: string, picker: MatDatepicker<any>): void {
    this.fechaRenta = '2020'
    picker.close()
  }

  presentReportHistory(): void {
    const dialogRef = this.dialog.open(ReportHistoryComponent, {
      data: {
        reportType: this.typesOfReports.TaxReport,
        creditNumber: this.dialogData.creditInfoDetail.creditNumber,
      }
    })
  }

}

interface DialogData {
  document: string
  creditInfoDetail: CreditInfo
}
