import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { IncomeStatementComponent } from './income-statement.component';
import { YearPickerModule } from '../year-picker/year-picker.module';
import { LoadingDataModule } from '../loading-data/loading-data.module';
import { ButtonPdfModule } from '../button-pdf/button-pdf.module';
import { ReportHistoryModule } from '../report-history/report-history.module';


@NgModule({
  declarations: [IncomeStatementComponent],
  imports: [
    CommonModule,
    FormsModule,

    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatProgressBarModule,

    YearPickerModule,
    LoadingDataModule,
    ButtonPdfModule,
    ReportHistoryModule,
  ],
  exports: [IncomeStatementComponent]
})
export class IncomeStatementModule { }
