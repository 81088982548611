import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from "@angular/core"
import { MatPaginator } from "@angular/material/paginator"
import { MatTableDataSource } from "@angular/material/table"
import { StatisticViewModel } from '@ifc-api/models'
import {
  format,
  getMonth,
  parseISO,
} from "date-fns"
import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexFill,
  ApexLegend,
  ApexPlotOptions,
  ApexStroke,
  ApexTooltip,
  ApexXAxis,
  ApexYAxis,
  ChartComponent,
} from "ng-apexcharts"
import { timer } from "rxjs"

type ChartOptions = {
  series: ApexAxisChartSeries
  chart: ApexChart
  dataLabels: ApexDataLabels
  plotOptions: ApexPlotOptions
  yaxis: ApexYAxis
  xaxis: ApexXAxis
  fill: ApexFill
  tooltip: ApexTooltip
  stroke: ApexStroke
  legend: ApexLegend
}

@Component({
  selector: 'app-chart-bar',
  templateUrl: './chart-bar.component.html',
  styleUrls: ['./chart-bar.component.scss']
})
export class ChartBarComponent implements OnChanges {

  @ViewChild('chart', { static: true }) chart!: ChartComponent
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator

  @Input() statistics: StatisticViewModel[] = []
  @Input() titleYaxis: string = ''
  @Input() showButtonMoreDetail = true
  @Input() routeMoreDetail = ''
  @Input() showTable = false

  chartOptions: ChartOptions

  dataStatistics: { [key: string]: number | string }[] = []
  displayedColumns: string[] = []
  dataSource = new MatTableDataSource<{ [key: string]: number | string }>()

  constructor() {
    this.chartOptions = this._chartOptions
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && this.statistics && this.titleYaxis) {
      this.setDataChartStatistics()
    }
  }

  private get _chartOptions(): ChartOptions {
    return {
      series: [],
      chart: {
        type: "bar",
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          // endingShape: "rounded"
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: []
      },
      yaxis: {
        title: {
          text: ''
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: (val) => {
            return `${val}`
          }
        }
      },
      legend: {
        show: true
      }
    }
  }

  setDataChartStatistics(): void {
    const months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']
    this.dataStatistics = []

    const lengthCategories = <string[]>this.statistics.filter((_report, index) => {
      const indexFind = this.statistics.findIndex(_rep => _rep.date === _report.date)
      return index === indexFind
    }).map(_report => _report.date || '')

    const _statistics = this.statistics.map(_report => {
      const _date = parseISO(_report.date!)

      const date = lengthCategories.length > 12 ?
        getMonth(_date) :
        format(new Date(_report.date!), 'yyyy/MM/dd')

      return {
        count: <number>_report.count,
        date,
        elementTypeName: <string>_report.elementTypeName,
        type: <number>_report.type,
      }
    })

    let categories = _statistics.sort((a, b) => {
      if (a.date < b.date) { return -1 }
      if (a.date > b.date) { return 1 }

      return 0
    }).filter((_report, index) => {
      const indexFind = _statistics.findIndex(_rep => _rep.date === _report.date)
      return index === indexFind
    }).map(_report => _report.date)

    let nameSerie = <{ name: string, type: number }[]>_statistics.map(_report => {
      return {
        name: <string>_report.elementTypeName,
        type: <number>_report.type,
      }
    }).sort((a, b) => a.type - b.type)

    nameSerie = nameSerie.filter((_report, index) => {
      const indexFind = nameSerie.findIndex(_rep => _rep.type === _report.type)
      return index === indexFind
    })

    const series: { name: string, data: number[] }[] =
      nameSerie.map(_category => ({ name: _category.name, data: [] }))

    categories.forEach((_dayOrMonth) => {

      const dataDayOrMonth: { [key: string]: number | string } = {}

      nameSerie.forEach((_category, indexCategory) => {

        const _reportPerCategory = _statistics
          .filter(_report => _report.date === _dayOrMonth)
          .filter(_report => _report.type === _category.type)

        let _count = 0

        if (_reportPerCategory.length > 0) {
          _count = _reportPerCategory.reduce((_c, { count }) => {
            return _c + Number(count)
          }, 0)
        }

        dataDayOrMonth[_category.name] = _count

        const dataSerie = series[indexCategory].data
        series[indexCategory].data = [...dataSerie, _count]

      })

      const keyDayOrMonth = lengthCategories.length > 12 ? 'Mes' : 'Día'
      dataDayOrMonth[keyDayOrMonth] = _dayOrMonth

      this.dataStatistics.push(dataDayOrMonth)
    })

    const categoriesName = lengthCategories.length > 12 ?
      categories.map(_category => months[<number>_category])
      : categories.map(_category => _category.toString())

    this.displayedColumns = nameSerie.map(_category => _category.name)

    this.displayedColumns = lengthCategories.length > 12 ?
      ['Mes', ...this.displayedColumns] :
      ['Día', ...this.displayedColumns]

    const newOptions = {
      yaxis: {
        title: {
          text: this.titleYaxis
        }
      },
      xaxis: {
        categories: categoriesName
      }
    }

    timer(300).subscribe({
      next: () => {
        this.chart.updateSeries(series)
        this.chart.updateOptions(newOptions, true)
        this.dataSource.paginator = this.paginator
        this.dataSource.data = this.dataStatistics
      }
    })

  }

}
