<app-loading-data *ngIf="!incomeStatement; else dataLoaded"></app-loading-data>

<ng-template #dataLoaded>
  <div mat-dialog-title class="d-flex justify-content-between">
    <div class="my-2">Para declarar renta</div>

    <button mat-icon-button [mat-dialog-close]="null" color="warn">
      <mat-icon>cancel</mat-icon>
    </button>
  </div>

  <mat-dialog-content>
    <div class="d-flex flex-column pt-1">
      <div class="pb-2">
        <mat-progress-bar *ngIf="isLoading" mode="indeterminate">
        </mat-progress-bar>
        <app-year-picker
          [date]="yearReportDate"
          [minDate]="yearReportMinDate"
          [maxDate]="yearReportMaxDate"
          (newDate)="setNewDate($event)"
          [readonly]="true"
        ></app-year-picker>
      </div>

      <div class="text-justify">
        <p>
          Que: {{ dialogData.creditInfoDetail.details?.userName | titlecase }},
          identificado(a) con cédula de ciudadanía No
          {{ dialogData.document }} en su condición de deudor, tenía en nuestros
          libros al 31-12-{{ yearReport }}
          por conceptos de operaciones de crédito, los siguientes saldos.
        </p>
        <br />
      </div>

      <div class="d-flex justify-content-between">
        <div>
          <p>Saldo:</p>
        </div>
        <div>
          <p>${{ incomeStatement?.debtValue | number }}</p>
        </div>
      </div>

      <div class="d-flex justify-content-between">
        <div>
          <p>Intereses Pagados, vigencia {{ yearReport }}:</p>
        </div>
        <div>
          <p>${{ incomeStatement?.interestPaidValue | number }}</p>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="center">
    <button
      mat-flat-button
      (click)="presentReportHistory()"
      color="primary"
      class="rounded-3"
    >
      Historial
    </button>

    <div class="px-2"></div>

    <app-button-pdf
      [reportType]="typesOfReports.TaxReport"
      [creditNumber]="dialogData.creditInfoDetail.creditNumber"
      [year]="yearReport"
      [nameReport]="'declarar_renta'"
    ></app-button-pdf>
  </mat-dialog-actions>
</ng-template>
