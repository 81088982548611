/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { GetMessagesQuery } from '../models/get-messages-query';
import { GetMessagesViewModel } from '../models/get-messages-view-model';
import { GetMyMessagesViewModel } from '../models/get-my-messages-view-model';
import { GetUserFilteredQuery } from '../models/get-user-filtered-query';
import { GetUserFilteredViewModel } from '../models/get-user-filtered-view-model';
import { MaskAsReadCommand } from '../models/mask-as-read-command';
import { MaskAsReadViewModel } from '../models/mask-as-read-view-model';
import { SendMessageCommand } from '../models/send-message-command';
import { SendMessageViewModel } from '../models/send-message-view-model';

@Injectable({ providedIn: 'root' })
export class MessagesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiMessagesUsersPost()` */
  static readonly ApiMessagesUsersPostPath = '/api/Messages/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMessagesUsersPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMessagesUsersPost$Plain$Response(
    params?: {
      body?: GetUserFilteredQuery
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<GetUserFilteredViewModel>>> {
    const rb = new RequestBuilder(this.rootUrl, MessagesService.ApiMessagesUsersPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GetUserFilteredViewModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMessagesUsersPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMessagesUsersPost$Plain(
    params?: {
      body?: GetUserFilteredQuery
    },
    context?: HttpContext
  ): Observable<Array<GetUserFilteredViewModel>> {
    return this.apiMessagesUsersPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GetUserFilteredViewModel>>): Array<GetUserFilteredViewModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMessagesUsersPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMessagesUsersPost$Json$Response(
    params?: {
      body?: GetUserFilteredQuery
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<GetUserFilteredViewModel>>> {
    const rb = new RequestBuilder(this.rootUrl, MessagesService.ApiMessagesUsersPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GetUserFilteredViewModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMessagesUsersPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMessagesUsersPost$Json(
    params?: {
      body?: GetUserFilteredQuery
    },
    context?: HttpContext
  ): Observable<Array<GetUserFilteredViewModel>> {
    return this.apiMessagesUsersPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GetUserFilteredViewModel>>): Array<GetUserFilteredViewModel> => r.body)
    );
  }

  /** Path part for operation `apiMessagesPost()` */
  static readonly ApiMessagesPostPath = '/api/Messages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMessagesPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMessagesPost$Plain$Response(
    params?: {
      body?: GetMessagesQuery
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<GetMessagesViewModel>>> {
    const rb = new RequestBuilder(this.rootUrl, MessagesService.ApiMessagesPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GetMessagesViewModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMessagesPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMessagesPost$Plain(
    params?: {
      body?: GetMessagesQuery
    },
    context?: HttpContext
  ): Observable<Array<GetMessagesViewModel>> {
    return this.apiMessagesPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GetMessagesViewModel>>): Array<GetMessagesViewModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMessagesPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMessagesPost$Json$Response(
    params?: {
      body?: GetMessagesQuery
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<GetMessagesViewModel>>> {
    const rb = new RequestBuilder(this.rootUrl, MessagesService.ApiMessagesPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GetMessagesViewModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMessagesPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMessagesPost$Json(
    params?: {
      body?: GetMessagesQuery
    },
    context?: HttpContext
  ): Observable<Array<GetMessagesViewModel>> {
    return this.apiMessagesPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GetMessagesViewModel>>): Array<GetMessagesViewModel> => r.body)
    );
  }

  /** Path part for operation `apiMessagesCreatePost()` */
  static readonly ApiMessagesCreatePostPath = '/api/Messages/create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMessagesCreatePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMessagesCreatePost$Plain$Response(
    params?: {
      body?: SendMessageCommand
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SendMessageViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, MessagesService.ApiMessagesCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SendMessageViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMessagesCreatePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMessagesCreatePost$Plain(
    params?: {
      body?: SendMessageCommand
    },
    context?: HttpContext
  ): Observable<SendMessageViewModel> {
    return this.apiMessagesCreatePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SendMessageViewModel>): SendMessageViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMessagesCreatePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMessagesCreatePost$Json$Response(
    params?: {
      body?: SendMessageCommand
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SendMessageViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, MessagesService.ApiMessagesCreatePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SendMessageViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMessagesCreatePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMessagesCreatePost$Json(
    params?: {
      body?: SendMessageCommand
    },
    context?: HttpContext
  ): Observable<SendMessageViewModel> {
    return this.apiMessagesCreatePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<SendMessageViewModel>): SendMessageViewModel => r.body)
    );
  }

  /** Path part for operation `apiMessagesMePost()` */
  static readonly ApiMessagesMePostPath = '/api/Messages/me';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMessagesMePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMessagesMePost$Plain$Response(
    params?: {
      body?: GetMessagesQuery
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<GetMyMessagesViewModel>>> {
    const rb = new RequestBuilder(this.rootUrl, MessagesService.ApiMessagesMePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GetMyMessagesViewModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMessagesMePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMessagesMePost$Plain(
    params?: {
      body?: GetMessagesQuery
    },
    context?: HttpContext
  ): Observable<Array<GetMyMessagesViewModel>> {
    return this.apiMessagesMePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GetMyMessagesViewModel>>): Array<GetMyMessagesViewModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMessagesMePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMessagesMePost$Json$Response(
    params?: {
      body?: GetMessagesQuery
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<GetMyMessagesViewModel>>> {
    const rb = new RequestBuilder(this.rootUrl, MessagesService.ApiMessagesMePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GetMyMessagesViewModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMessagesMePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMessagesMePost$Json(
    params?: {
      body?: GetMessagesQuery
    },
    context?: HttpContext
  ): Observable<Array<GetMyMessagesViewModel>> {
    return this.apiMessagesMePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GetMyMessagesViewModel>>): Array<GetMyMessagesViewModel> => r.body)
    );
  }

  /** Path part for operation `apiMessagesMarkAsReadPost()` */
  static readonly ApiMessagesMarkAsReadPostPath = '/api/Messages/MarkAsRead';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMessagesMarkAsReadPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMessagesMarkAsReadPost$Plain$Response(
    params?: {
      body?: MaskAsReadCommand
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MaskAsReadViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, MessagesService.ApiMessagesMarkAsReadPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MaskAsReadViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMessagesMarkAsReadPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMessagesMarkAsReadPost$Plain(
    params?: {
      body?: MaskAsReadCommand
    },
    context?: HttpContext
  ): Observable<MaskAsReadViewModel> {
    return this.apiMessagesMarkAsReadPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<MaskAsReadViewModel>): MaskAsReadViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMessagesMarkAsReadPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMessagesMarkAsReadPost$Json$Response(
    params?: {
      body?: MaskAsReadCommand
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<MaskAsReadViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, MessagesService.ApiMessagesMarkAsReadPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MaskAsReadViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiMessagesMarkAsReadPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMessagesMarkAsReadPost$Json(
    params?: {
      body?: MaskAsReadCommand
    },
    context?: HttpContext
  ): Observable<MaskAsReadViewModel> {
    return this.apiMessagesMarkAsReadPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<MaskAsReadViewModel>): MaskAsReadViewModel => r.body)
    );
  }

}
