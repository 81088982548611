import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { ButtonPdfComponent } from './button-pdf.component';
import { DownReportsService } from './down-reports.service';


@NgModule({
  declarations: [ButtonPdfComponent],
  imports: [
    CommonModule,

    MatButtonModule,
    MatProgressSpinnerModule,
  ],
  exports: [ButtonPdfComponent],
  providers: [
    DownReportsService
  ]
})
export class ButtonPdfModule { }
