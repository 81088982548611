import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading-data',
  templateUrl: './loading-data.component.html',
  styleUrls: ['./loading-data.component.scss']
})
export class LoadingDataComponent {

  @Input() message: string

  constructor () {
    this.message = 'Por favor espere..'
  }

}
