import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environment/environment';
import { TypesOfReports } from 'src/app/shared/models/reportType.model';


const APIDATA = environment.apiIFC

@Injectable()
export class DownReportsService {

  constructor (private http: HttpClient) { }

  getReportPDF (reportType: TypesOfReports, creditNumber: number, year: string | number): Observable<Blob> {
    return this.http.get(`${APIDATA}/api/Reports/${reportType}/${creditNumber}/${year}`, {
      responseType: 'blob'
    })
  }

}
