export enum TypesIfEnabled {
    /**
     * Reporte de Estado de Cuenta
     */
    ReporteEstadoCuenta = 1,
    /**
     * Certificado de deuda al día
     */
    CertificadoDeudaDia = 2,
    /**
     * Certificado para declarar renta
     */
    CertificadoDeclararRenta = 3,
    /**
     * Recibo de pago en bancos
     */
    ReciboPagoBancos = 4,
    /**
     * Certificado de Paz y Salvo
     */
    CertificadoPazSalvo = 5,
    /**
     * Pago en línea
     */
    PagoEnLinea = 6,
    /**
     * Renovación de Crédito Educativo
     */
    RenovacionCreditoEducativo = 7
}
