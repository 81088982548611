import {
  Component,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { HttpEventType } from '@angular/common/http';
import Swal from 'sweetalert2';
import {
  CommonService,
  UploadFileService,
} from '@shared/services';
import { FileDetail } from '@shared/models';

@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.scss']
})
export class UploadFilesComponent {

  @Input() labelButton = 'Adjuntar archivos'
  @Input() iconButton = 'file_upload'

  @Output() uploadingFiles = new EventEmitter<boolean>()
  @Output() fileDetails = new EventEmitter<FileDetail[]>()

  _fileDetails: FileDetail[] = []

  constructor(
    private uploadFileService: UploadFileService,
    public commonService: CommonService,
  ) {}

  /**
   * Agrega los archivos adjuntos al arreglo
   * @param event Input File Event
   * @returns Void
   */
  addAttachments(event: Event): void {
    const inputFile = event.target as HTMLInputElement
    const isFilesExtValid: boolean[] = []
    const isFilesSizeValid: boolean[] = []

    Array.from(inputFile.files as FileList).forEach((file) => {

      const isExtValid = file.type.includes('image') ||
        file.type.includes('pdf') ||
        file.type.includes('msword') ||
        file.type.includes('wordprocessingml') ||
        file.type.includes('ms-excel') ||
        file.type.includes('spreadsheetml')

      // Menor o igual a 10MiB
      const isSizeValid = (file.size <= 10485760)

      isFilesExtValid.push(isExtValid)
      isFilesSizeValid.push(isSizeValid)
    })

    if (isFilesExtValid.includes(false)) {
      Swal.fire('Upss.. 🙁', 'Por favor solo seleccione archivos válidos', 'error')
      return
    }

    if (isFilesSizeValid.includes(false)) {
      Swal.fire('Upss.. 🙁', 'Por favor solo seleccione archivos válidos, tamaño excedido', 'error')
      return
    }

    const attachedFiles: File[] = []

    Array.from(<FileList>inputFile.files).forEach(_file => {
      const indexFile = this._fileDetails.findIndex((_fileDetail) => _fileDetail.fileName === _file.name)

      if (indexFile === -1) {
        attachedFiles.push(_file)
      }
    })

    attachedFiles.forEach((_file) => {

      this._fileDetails.push({
        fileName: _file.name,
        size: (_file.size / 1024 / 1024),
        progress: 0,
        uploading: false,
        uploaded: false,
        filePath: '',
        file: _file,
        canceled: false
      })

    })

    this.#uploadFile()
  }

  /**
   * Sube los archivos
   */
  #uploadFile(): void {
    if (this._fileDetails.length === 0) {
      return
    }

    this.checkUploadingFiles()

    this._fileDetails.forEach((_fileDetail) => {

      if (!_fileDetail.uploading && !_fileDetail.canceled) {

        const subscription$ = this.uploadFileService.uploadFile(_fileDetail.file)
          .subscribe({
            next: (res) => {
              const _index = this._fileDetails.findIndex((_detail) => _detail.fileName === _fileDetail.fileName)

              if (_index === -1) {
                return
              }

              if (this._fileDetails[_index].canceled) {
                this._fileDetails[_index].uploading = false
                subscription$.unsubscribe()
                this.cancelOrDeleteFile(_fileDetail)
              }

              if (res.type === HttpEventType.Sent) {
                this._fileDetails[_index].uploading = true
              }

              if (res.type === HttpEventType.UploadProgress) {
                const total = res.total ? res.total : _fileDetail.file.size

                if (this._fileDetails[_index]) {
                  this._fileDetails[_index].progress = (res.loaded * 100) / total
                }
              }

              if (res.type === HttpEventType.Response) {
                this._fileDetails[_index].filePath = res.body?.filePath || ''
                this._fileDetails[_index].uploaded = true

                subscription$.unsubscribe()
                this.fileDetails.emit(this._fileDetails)
                this.checkUploadingFiles()
              }

            }
          })
      }

    })
  }

  cancelOrDeleteFile(fileDetail: FileDetail): void {
    if (fileDetail.uploaded || (fileDetail.canceled && !fileDetail.uploading)) {
      this._fileDetails = this._fileDetails.filter((_fileDetail) => _fileDetail.fileName !== fileDetail.fileName)
    } else if (fileDetail.uploading) {
      fileDetail.canceled = true
    }

    this.fileDetails.emit(this._fileDetails)
    this.checkUploadingFiles()
  }

  private checkUploadingFiles(): void {
    const uploadingFilesArray = this._fileDetails.map((_fileDetail) => _fileDetail.uploaded)
    const uploadingFiles = uploadingFilesArray.includes(false)

    this.uploadingFiles.emit(uploadingFiles)
  }

}
