export enum CreditRequestTypeEnum {
  Sostenimiento = 1,
  Matricula = 2,
  SostenimientoYMatricula = 3,
  Suspension = 4,
  unknown = -1,
}

export const toCreditRequestType = (requestType: any) => {
  switch (requestType) {
    case 1:
      return CreditRequestTypeEnum.Sostenimiento
    case 2:
      return CreditRequestTypeEnum.Matricula
    case 3:
      return CreditRequestTypeEnum.SostenimientoYMatricula
    case 4:
      return CreditRequestTypeEnum.Suspension

    default:
      return CreditRequestTypeEnum.unknown
  }
}

export const CreditRequestTypeArray = [
  { name: 'Sostenimiento', id: 1 },
  { name: 'Matrícula', id: 2 },
  { name: 'Sostenimiento Y Matrícula', id: 3 },
  { name: 'Suspensión', id: 4 }
]

export const AuthorizationTypeArray = [
  { name: 'Cuenta Personal', id: 1 },
  { name: 'Cuenta Universidad', id: 2 },
  { name: 'Mixto', id: 3, description: 'Indica que el valor de la matricula se consigna a la Universidad y el valor de sostenimiento al Estudiante' }
]

export const ContractTypeArray = [
  'INDEPENDIENTE',
  'Contrato de Prestación de servicios',
  'Contrato por obra o labor',
  'Contrato de trabajo a término fijo',
  'Contrato de trabajo a término indefinido',
  'Contrato de aprendizaje',
  'Contrato temporal, ocasional o accidental',
]

export enum GradeTypeEnum {
  Pregrade = 1,
  Posgrade = 2
}

export const GradeTypeArray = [
  { name: 'Pregrado', id: 1 },
  { name: 'Posgrado', id: 2 }
]

export const PreGradeTypeArray = [
  { name: 'Técnico Profesional', id: 1 },
  { name: 'Tecnólogo', id: 2 },
  { name: 'Profesional', id: 3 }
]

export const PosGradeTypeArray = [
  { name: 'Especializacion', id: 1 },
  { name: 'Maestria', id: 2 },
  { name: 'Doctorado', id: 3 }
]

export const UniversityTypeArray = [
  { name: 'Pública', id: 1 },
  { name: 'Privada', id: 2 }
]

export const ModalityArray = [
  { name: 'Presencial', id: 1 },
  { name: 'Distancia', id: 2 }
]

export const PeriodTypeArray = [
  { name: 'Anual', id: 1 },
  { name: 'Semestral', id: 2 },
  { name: 'Cuatrimestral', id: 3 }
]

export const SuspensionTypeArray = [
  { name: 'Retiro Temporal', id: 1 },
  { name: 'Cierre Universidad', id: 2 },
  { name: 'Problemas', id: 3 },
  { name: 'Homologación', id: 4 }
]


export enum CreditRenewStatusTypeEnum {
  /**
   * Está en proceso por parte del usuario
   */
  Borrador = 1,
  /**
   * Cuando el usuario terminó
   */
  Finalizado = 2,
  /**
   * Cuando el IFC Recibió y Validó la solicitud
   */
  Aceptado = 3,
  /**
   * Cuando el IFC Devolvió la solicitud
   */
  Rechazado = 4,
  /**
   * Cuando el IFC Aprobó la renovación
   */
  Aprobado = 5,
  /**
   * Cuando el IFC devuelve la solicitud
   */
  Devuelto = 6,
  /**
   * Cuando el IFC radica la solicitud con datos incompletos
   */
  RadicadoIncompleto = 7,
  /**
   * Cuando el IFC finaliza el tramite
   */
  TramiteFinalizado = 8,
  unknown = -1,
}

export const toCreditRenewStatusType = (statusType: any) => {
  switch (statusType) {
    case 1:
      return CreditRenewStatusTypeEnum.Borrador
    case 2:
      return CreditRenewStatusTypeEnum.Finalizado
    case 3:
      return CreditRenewStatusTypeEnum.Aceptado
    case 4:
      return CreditRenewStatusTypeEnum.Rechazado
    case 5:
      return CreditRenewStatusTypeEnum.Aprobado
    case 6:
      return CreditRenewStatusTypeEnum.Devuelto
    case 7:
      return CreditRenewStatusTypeEnum.RadicadoIncompleto
    case 8:
      return CreditRenewStatusTypeEnum.TramiteFinalizado

    default:
      return CreditRenewStatusTypeEnum.unknown
  }
}

export interface UploadFileRequestRenewParams {
  CreditId: string;
  DocumentType?: AttachmentDocumentTypeEnum;
  UserId: string;
  CreditRequestId: string;
}

export enum AttachmentDocumentTypeEnum {
  DocId = 1,
  UniversityCertification = 2,
  Payment = 3,
  NotesCertificate = 4,
  InscriptionPayment = 5,
  ExplicationLetter = 6,
  CoDeudorDocId = 7,
  CoDeudorBanco = 8,
  CoDeudorTrabajo = 9,
  bankCertification = 10,
  DocumentType = 100, // Otros documentos
  unknown = -1,
}

export const toAttachmentDocumentType = (documentType: any) => {
  switch (documentType) {
    case 1:
      return AttachmentDocumentTypeEnum.DocId
    case 2:
      return AttachmentDocumentTypeEnum.UniversityCertification
    case 3:
      return AttachmentDocumentTypeEnum.Payment
    case 4:
      return AttachmentDocumentTypeEnum.NotesCertificate
    case 5:
      return AttachmentDocumentTypeEnum.InscriptionPayment
    case 6:
      return AttachmentDocumentTypeEnum.ExplicationLetter
    case 7:
      return AttachmentDocumentTypeEnum.CoDeudorDocId
    case 8:
      return AttachmentDocumentTypeEnum.CoDeudorBanco
    case 9:
      return AttachmentDocumentTypeEnum.CoDeudorTrabajo
    case 10:
      return AttachmentDocumentTypeEnum.bankCertification
    case 100:
      return AttachmentDocumentTypeEnum.DocumentType

    default:
      return AttachmentDocumentTypeEnum.unknown
  }
}

export const AttachmentDocumentTypeArray = [
  {
    name: 'Cédula de ciudadanía o Tarjeta de identidad',
    id: 1,
  },
  {
    name: 'Certificación original, expedida por la Institución de Educación Superior',
    id: 2,
  },
  {
    name: 'Pago de cuota en época de estudio',
    id: 3,
  },
  {
    name: 'Certificado original de notas, expedido por la universidad, del semestre cursado anterior',
    id: 4
  },
  {
    name: 'Recibo de pago',
    id: 5,
  },
  {
    name: 'Oficio aclaratorio, aplazamiento de crédito',
    id: 6,
  },
  {
    name: 'Cédula de ciudadanía de codeudor',
    id: 7,
  },
  {
    name: 'Certificación bancaria de codeudor',
    id: 8,
  },
  {
    name: 'Certificación laboral de codeudor',
    id: 9,
  },
  {
    name: 'Certificación bancaria de estudiante',
    id: 10,
  },
  {
    name: 'Otro',
    id: 100,
  }
]

export const genders = [
  { name: 'Femenino', value: 'FE' },
  { name: 'Masculino', value: 'MA' },
  { name: 'No aplica', value: 'NA' },
]

export const estudiosRealizadosArray = [
  { name: 'PRIMARIA', value: 'PRIMARIA' },
  { name: 'SECUNDARIA', value: 'SECUNDARIA' },
  { name: 'TECNOLOGIA', value: 'TECNOLOGIA' },
  { name: 'UNIVERSITARIO', value: 'UNIVERSITARIO' },
  { name: 'POSGRADO', value: 'POSGRADO' }
]

export const estadoCivilArray = [
  { name: 'SOLTERO', value: 'SOLTERO' },
  { name: 'CASADO', value: 'CASADO' },
  { name: 'UNION LIBRE', value: 'UNION LIBRE' },
  { name: 'SEPARADO', value: 'SEPARADO' },
  { name: 'VIUDO', value: 'VIUDO' }
]

export const tipoDeViviendaArray = [
  { name: 'PROPIA', value: 'PROPIA' },
  { name: 'FAMILIAR', value: 'FAMILIAR' },
  { name: 'ARRENDADA', value: 'ARRENDADA' }
]

export const tipoActividadConyugueArray = [
  { name: 'HOGAR', value: 'HOGAR' },
  { name: 'EMPLEADO', value: 'EMPLEADO' },
  { name: 'PENSIONADO', value: 'PENSIONADO' },
  { name: 'INDEPENDIENTE', value: 'INDEPENDIENTE' }
]

export const tipoDeBienArray = [
  { name: 'CASAS', value: 'CASAS' },
  { name: 'EDIFICIOS', value: 'EDIFICIOS' },
  { name: 'LOTES', value: 'LOTES' },
  { name: 'FINCA', value: 'FINCA' },
  { name: 'LIBRANZA', value: 'LIBRANZA' }
]
