<h2>
  {{ credit?.creditType | firtsUpper }}
  <small>
    <small>
      <span
        class="credit-active rounded px-2"
        [ngClass]="
          (credit?.status | lowercase) === 'ca' ? 'bg__primary' : 'bg__accent'
        "
      >
        <small>
          {{ (credit?.status | lowercase) === "ca" ? "Cancelado" : "Activo" }}
        </small>
      </span>
    </small>
  </small>
</h2>
<h3>No. {{ credit?.creditNumber }}</h3>

<div class="d-flex justify-content-between">
  <p class="mb-1">Fecha inicio</p>
  <p class="mb-1 ms-2 text-end">{{ credit?.details?.startDate | date }}</p>
</div>

<div class="d-flex justify-content-between">
  <p class="mb-1">Fecha finalización</p>
  <p class="mb-1 ms-2 text-end">
    {{ credit?.details?.finishDate | date }}
  </p>
</div>

<div class="d-flex justify-content-between">
  <p class="mb-1">Valor total</p>
  <p class="mb-1 ms-2 text-end">
    ${{ credit?.details?.totalCreditValue | number }}
  </p>
</div>

<div class="d-flex justify-content-between">
  <p class="mb-1">Valor adeudado</p>
  <p class="mb-1 ms-2 text-end">${{ credit?.details?.debtValue | number }}</p>
</div>

<div class="d-flex justify-content-between">
  <p class="mb-1">Valor mínimo a pagar</p>
  <p class="mb-1 ms-2 text-end">
    ${{ credit?.details?.minimumValue | number }}
  </p>
</div>

<div class="d-flex justify-content-between">
  <p class="mb-1">Termino</p>
  <p class="mb-1 ms-2 text-end">{{ credit?.details?.term }}</p>
</div>

<div class="w-100" style="height: 0.6em"></div>

<mat-nav-list>
  <mat-list-item
    [appIfEnabled]="typesIfEnabled.ReporteEstadoCuenta"
    (ifEnabledClick)="presentAccountStatus()"
  >
    <span matListItemIcon>
      <mat-icon color="primary">assessment</mat-icon>
    </span>
    <div matListItemTitle style="white-space: normal" class="line-clamp-1">
      Reporte de estado de cuenta
    </div>
    <button matListItemMeta mat-icon-button color="primary">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
  </mat-list-item>

  <mat-list-item
    *ngIf="(credit?.status | lowercase) === 'ca'"
    [appIfEnabled]="typesIfEnabled.CertificadoPazSalvo"
    (ifEnabledClick)="presentPeaceAndSave()"
  >
    <span matListItemIcon>
      <mat-icon color="accent">check_circle</mat-icon>
    </span>
    <div matListItemTitle style="white-space: normal" class="line-clamp-1">
      Paz y salvo
    </div>
    <button matListItemMeta mat-icon-button color="accent">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
  </mat-list-item>

  <mat-list-item
    [appIfEnabled]="typesIfEnabled.CertificadoDeudaDia"
    (ifEnabledClick)="presentDebtADay()"
  >
    <span matListItemIcon>
      <mat-icon color="accent">description</mat-icon>
    </span>
    <div matListItemTitle style="white-space: normal" class="line-clamp-1">
      Certificado de deuda al día
    </div>
    <button matListItemMeta mat-icon-button color="accent">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
  </mat-list-item>

  <mat-list-item
    [appIfEnabled]="typesIfEnabled.CertificadoDeclararRenta"
    (ifEnabledClick)="presentIncomeStatement()"
  >
    <span matListItemIcon>
      <mat-icon color="primary">event</mat-icon>
    </span>
    <div matListItemTitle style="white-space: normal" class="line-clamp-1">
      Certificado para declarar renta
    </div>
    <button matListItemMeta mat-icon-button color="primary">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
  </mat-list-item>

  <mat-list-item
    *ngIf="
      (credit?.details?.type | lowercase) === 'fesca' &&
      (credit?.status | lowercase) === 'ac'
    "
    [appIfEnabled]="typesIfEnabled.RenovacionCreditoEducativo"
    (ifEnabledClick)="redirectRenewCredit(credit?.creditNumber)"
  >
    <span matListItemIcon>
      <mat-icon color="primary">savings</mat-icon>
    </span>
    <div matListItemTitle style="white-space: normal" class="line-clamp-1">
      Renovación de crédito FESCA
    </div>
    <button matListItemMeta mat-icon-button color="primary">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
  </mat-list-item>

  <mat-list-item
    *ngIf="(credit?.status | lowercase) === 'ac'"
    [appIfEnabled]="typesIfEnabled.ReciboPagoBancos"
    (ifEnabledClick)="presentReceiptPayments()"
  >
    <span matListItemIcon>
      <mat-icon color="accent">payment</mat-icon>
    </span>
    <div matListItemTitle>Recibo de pago</div>
    <div matListItemLine>Para bancos o terceros</div>
    <button matListItemMeta mat-icon-button color="accent">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
  </mat-list-item>

  <mat-list-item
    *ngIf="(credit?.status | lowercase) === 'ac'"
    [appIfEnabled]="typesIfEnabled.PagoEnLinea"
    (ifEnabledClick)="presentPaymentPSE()"
  >
    <span matListItemIcon>
      <mat-icon svgIcon="pse" color="accent"> </mat-icon>
    </span>
    <div matListItemTitle style="white-space: normal" class="line-clamp-1">
      Pago en línea PSE
    </div>
    <button matListItemMeta mat-icon-button color="accent">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
  </mat-list-item>
</mat-nav-list>
