import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { FirtsUpperModule } from '@shared/pipes';
import { IfEnabledModule } from '@shared/directives';
import { CreditDetailComponent } from './credit-detail.component';
import { ReceiptPaymentsModule } from '../receipt-payments/receipt-payments.module';

@NgModule({
  declarations: [CreditDetailComponent],
  imports: [
    CommonModule,
    RouterModule,

    MatCardModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,

    FirtsUpperModule,
    IfEnabledModule,
    ReceiptPaymentsModule,
  ],
  exports: [CreditDetailComponent]
})
export class CreditDetailModule {}
