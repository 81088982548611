export interface FileDetail {
    fileName: string;
    filePath: string;
    size: number;
    progress: number;
    uploading: boolean;
    uploaded: boolean;
    file: File;
    canceled: boolean;
}
