/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { PayCommand } from '../models/pay-command';
import { PayViewModel } from '../models/pay-view-model';
import { PaymentDetailsViewModel } from '../models/payment-details-view-model';
import { PaymentHistoryQuery } from '../models/payment-history-query';
import { PaymentHistoryViewModel } from '../models/payment-history-view-model';
import { PaymentListQuery } from '../models/payment-list-query';
import { PaymentListViewModel } from '../models/payment-list-view-model';
import { PaymentStatusByCreditQuery } from '../models/payment-status-by-credit-query';
import { PaymentStatusByCreditViewModel } from '../models/payment-status-by-credit-view-model';
import { ReportStatusViewModel } from '../models/report-status-view-model';

@Injectable({ providedIn: 'root' })
export class PaymentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiPaymentHavePendingPaymentsPost()` */
  static readonly ApiPaymentHavePendingPaymentsPostPath = '/api/Payment/HavePendingPayments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPaymentHavePendingPaymentsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPaymentHavePendingPaymentsPost$Plain$Response(
    params?: {
      body?: PaymentStatusByCreditQuery
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PaymentStatusByCreditViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentService.ApiPaymentHavePendingPaymentsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaymentStatusByCreditViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPaymentHavePendingPaymentsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPaymentHavePendingPaymentsPost$Plain(
    params?: {
      body?: PaymentStatusByCreditQuery
    },
    context?: HttpContext
  ): Observable<PaymentStatusByCreditViewModel> {
    return this.apiPaymentHavePendingPaymentsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentStatusByCreditViewModel>): PaymentStatusByCreditViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPaymentHavePendingPaymentsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPaymentHavePendingPaymentsPost$Json$Response(
    params?: {
      body?: PaymentStatusByCreditQuery
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PaymentStatusByCreditViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentService.ApiPaymentHavePendingPaymentsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaymentStatusByCreditViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPaymentHavePendingPaymentsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPaymentHavePendingPaymentsPost$Json(
    params?: {
      body?: PaymentStatusByCreditQuery
    },
    context?: HttpContext
  ): Observable<PaymentStatusByCreditViewModel> {
    return this.apiPaymentHavePendingPaymentsPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentStatusByCreditViewModel>): PaymentStatusByCreditViewModel => r.body)
    );
  }

  /** Path part for operation `apiPaymentPost()` */
  static readonly ApiPaymentPostPath = '/api/Payment';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPaymentPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPaymentPost$Plain$Response(
    params?: {
      body?: PayCommand
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PayViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentService.ApiPaymentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PayViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPaymentPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPaymentPost$Plain(
    params?: {
      body?: PayCommand
    },
    context?: HttpContext
  ): Observable<PayViewModel> {
    return this.apiPaymentPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PayViewModel>): PayViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPaymentPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPaymentPost$Json$Response(
    params?: {
      body?: PayCommand
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PayViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentService.ApiPaymentPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PayViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPaymentPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPaymentPost$Json(
    params?: {
      body?: PayCommand
    },
    context?: HttpContext
  ): Observable<PayViewModel> {
    return this.apiPaymentPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PayViewModel>): PayViewModel => r.body)
    );
  }

  /** Path part for operation `apiPaymentHistoryPost()` */
  static readonly ApiPaymentHistoryPostPath = '/api/Payment/history';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPaymentHistoryPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPaymentHistoryPost$Plain$Response(
    params?: {
      body?: PaymentHistoryQuery
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<PaymentHistoryViewModel>>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentService.ApiPaymentHistoryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PaymentHistoryViewModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPaymentHistoryPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPaymentHistoryPost$Plain(
    params?: {
      body?: PaymentHistoryQuery
    },
    context?: HttpContext
  ): Observable<Array<PaymentHistoryViewModel>> {
    return this.apiPaymentHistoryPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PaymentHistoryViewModel>>): Array<PaymentHistoryViewModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPaymentHistoryPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPaymentHistoryPost$Json$Response(
    params?: {
      body?: PaymentHistoryQuery
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<PaymentHistoryViewModel>>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentService.ApiPaymentHistoryPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PaymentHistoryViewModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPaymentHistoryPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPaymentHistoryPost$Json(
    params?: {
      body?: PaymentHistoryQuery
    },
    context?: HttpContext
  ): Observable<Array<PaymentHistoryViewModel>> {
    return this.apiPaymentHistoryPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PaymentHistoryViewModel>>): Array<PaymentHistoryViewModel> => r.body)
    );
  }

  /** Path part for operation `apiPaymentReportGet()` */
  static readonly ApiPaymentReportGetPath = '/api/Payment/report';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPaymentReportGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPaymentReportGet$Plain$Response(
    params?: {
      id_pago?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ReportStatusViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentService.ApiPaymentReportGetPath, 'get');
    if (params) {
      rb.query('id_pago', params.id_pago, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportStatusViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPaymentReportGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPaymentReportGet$Plain(
    params?: {
      id_pago?: string;
    },
    context?: HttpContext
  ): Observable<ReportStatusViewModel> {
    return this.apiPaymentReportGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportStatusViewModel>): ReportStatusViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPaymentReportGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPaymentReportGet$Json$Response(
    params?: {
      id_pago?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ReportStatusViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentService.ApiPaymentReportGetPath, 'get');
    if (params) {
      rb.query('id_pago', params.id_pago, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportStatusViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPaymentReportGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPaymentReportGet$Json(
    params?: {
      id_pago?: string;
    },
    context?: HttpContext
  ): Observable<ReportStatusViewModel> {
    return this.apiPaymentReportGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportStatusViewModel>): ReportStatusViewModel => r.body)
    );
  }

  /** Path part for operation `apiPaymentReportPost()` */
  static readonly ApiPaymentReportPostPath = '/api/Payment/report';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPaymentReportPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPaymentReportPost$Plain$Response(
    params?: {
      id_pago?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ReportStatusViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentService.ApiPaymentReportPostPath, 'post');
    if (params) {
      rb.query('id_pago', params.id_pago, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportStatusViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPaymentReportPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPaymentReportPost$Plain(
    params?: {
      id_pago?: string;
    },
    context?: HttpContext
  ): Observable<ReportStatusViewModel> {
    return this.apiPaymentReportPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportStatusViewModel>): ReportStatusViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPaymentReportPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPaymentReportPost$Json$Response(
    params?: {
      id_pago?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ReportStatusViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentService.ApiPaymentReportPostPath, 'post');
    if (params) {
      rb.query('id_pago', params.id_pago, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportStatusViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPaymentReportPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPaymentReportPost$Json(
    params?: {
      id_pago?: string;
    },
    context?: HttpContext
  ): Observable<ReportStatusViewModel> {
    return this.apiPaymentReportPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReportStatusViewModel>): ReportStatusViewModel => r.body)
    );
  }

  /** Path part for operation `apiPaymentDetailsPaymentIdGet()` */
  static readonly ApiPaymentDetailsPaymentIdGetPath = '/api/Payment/details/{paymentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPaymentDetailsPaymentIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPaymentDetailsPaymentIdGet$Plain$Response(
    params: {
      paymentId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PaymentDetailsViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentService.ApiPaymentDetailsPaymentIdGetPath, 'get');
    if (params) {
      rb.path('paymentId', params.paymentId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaymentDetailsViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPaymentDetailsPaymentIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPaymentDetailsPaymentIdGet$Plain(
    params: {
      paymentId: string;
    },
    context?: HttpContext
  ): Observable<PaymentDetailsViewModel> {
    return this.apiPaymentDetailsPaymentIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentDetailsViewModel>): PaymentDetailsViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPaymentDetailsPaymentIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPaymentDetailsPaymentIdGet$Json$Response(
    params: {
      paymentId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PaymentDetailsViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentService.ApiPaymentDetailsPaymentIdGetPath, 'get');
    if (params) {
      rb.path('paymentId', params.paymentId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaymentDetailsViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPaymentDetailsPaymentIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiPaymentDetailsPaymentIdGet$Json(
    params: {
      paymentId: string;
    },
    context?: HttpContext
  ): Observable<PaymentDetailsViewModel> {
    return this.apiPaymentDetailsPaymentIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentDetailsViewModel>): PaymentDetailsViewModel => r.body)
    );
  }

  /** Path part for operation `apiPaymentListPost()` */
  static readonly ApiPaymentListPostPath = '/api/Payment/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPaymentListPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPaymentListPost$Plain$Response(
    params?: {
      body?: PaymentListQuery
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<PaymentListViewModel>>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentService.ApiPaymentListPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PaymentListViewModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPaymentListPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPaymentListPost$Plain(
    params?: {
      body?: PaymentListQuery
    },
    context?: HttpContext
  ): Observable<Array<PaymentListViewModel>> {
    return this.apiPaymentListPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PaymentListViewModel>>): Array<PaymentListViewModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiPaymentListPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPaymentListPost$Json$Response(
    params?: {
      body?: PaymentListQuery
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<PaymentListViewModel>>> {
    const rb = new RequestBuilder(this.rootUrl, PaymentService.ApiPaymentListPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PaymentListViewModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiPaymentListPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiPaymentListPost$Json(
    params?: {
      body?: PaymentListQuery
    },
    context?: HttpContext
  ): Observable<Array<PaymentListViewModel>> {
    return this.apiPaymentListPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PaymentListViewModel>>): Array<PaymentListViewModel> => r.body)
    );
  }

}
