<app-loading-data
  *ngIf="loadingData; else loadedDataTemplate"
></app-loading-data>

<ng-template #loadedDataTemplate>
  <div mat-dialog-title class="d-flex justify-content-between">
    <div class="my-2">Pago en línea de su crédito</div>

    <button mat-icon-button [mat-dialog-close]="null" color="warn">
      <mat-icon>cancel</mat-icon>
    </button>
  </div>

  <mat-dialog-content>
    <div class="d-flex flex-column">
      <div class="text-justify">
        <p>
          Este proceso te permitirá realizar un pago a tu crédito a través de
          los medios virtuales, usando la plataforma PSE.
        </p>
        <br />
      </div>

      <div class="d-flex justify-content-between">
        <p>No. crédito:</p>
        <p class="ms-2">{{ dialogData.creditInfoDetail.creditNumber }}</p>
      </div>

      <div class="d-flex justify-content-between">
        <p>Pago mínimo:</p>
        <p class="ms-2">
          ${{ dialogData.creditInfoDetail.details?.minimumValue | number }}
        </p>
      </div>

      <div class="d-flex justify-content-between">
        <p>Pago total:</p>
        <p>${{ dialogData.creditInfoDetail.details?.totalPayment | number }}</p>
      </div>

      <div class="d-flex justify-content-between">
        <p>Fecha límite de pago:</p>
        <p>{{ dialogData.creditInfoDetail.details?.paymentDate | date }}</p>
      </div>

      <form [formGroup]="paymentGroup" autocomplete="off">
        <mat-form-field appearance="outline" class="w-100 form_field_3">
          <mat-label> Seleccione el Valor a pagar </mat-label>
          <mat-select formControlName="toPay">
            <mat-option *ngIf="!minPaymentIsZero" value="min">
              Valor mínimo
            </mat-option>
            <mat-option value="max"> Valor total </mat-option>
            <mat-option value="otro"> Otro valor </mat-option>
          </mat-select>
        </mat-form-field>

        <div *ngIf="paymentGroup.value.toPay === 'otro'" class="w-100">
          <p style="color: #ffc409">
            <small>
              El pago que vas a realizar se aplicará al valor de la cuota
              actual, si requieres realizar un abono a capital, es necesario que
              te comuniques con nosotros previamente *
            </small>
          </p>
        </div>

        <mat-form-field
          *ngIf="paymentGroup.value.toPay === 'otro'"
          appearance="outline"
          class="w-100 form_field_3"
        >
          <mat-label> Ingrese el Valor a pagar </mat-label>
          <input
            matInput
            formControlName="amount"
            mask="separator.0"
            thousandSeparator="."
            [placeholder]="
              'Ingrese un valor menor a ' +
              (dialogData.creditInfoDetail.details?.totalPayment | number)
            "
            appValidate
          />
          <mat-error></mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-100 form_field_3">
          <mat-label>Descripción</mat-label>
          <input matInput formControlName="description" appValidate />
          <mat-error></mat-error>
        </mat-form-field>

        <div>
          <p>
            Tienes dudas sobre el proceso de pagos, consulta nuestras
            <a
              href="https://www.ifc.gov.co/transparencia-y-acceso-a-la-informacion-publica/5-tramites-y-servicios/preguntas-frecuentes-pagos-en-linea"
              target="_blank"
              class="color"
            >
              preguntas frecuentes
            </a>
          </p>
        </div>

        <div>
          <mat-checkbox formControlName="termAndConditions">
            Acepto los términos y condiciones del Instituto Financiero del
            Casanare.
            <a
              href="https://www.ifc.gov.co/la-entidad/politica-de-tratamiento-de-datos-personales"
              target="_blank"
              class="color"
            >
              Consultar términos y condiciones
            </a>
          </mat-checkbox>
        </div>

        <div class="text-center">
          <p><small> Pago realizado a través de </small></p>
          <img src="/assets/images/GOU.svg" class="img-fluid" alt="" />
        </div>
      </form>

      <div *ngIf="paymentStatus?.havePaymentLastDay || 0 > 0" class="py-2">
        <h4>{{ paymentStatus?.message }}</h4>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions align="center">
    <button
      *ngIf="paymentStatus?.havePaymentLastDay || 0 > 0"
      mat-flat-button
      (click)="redirectPaymentsHistory()"
      color="primary"
    >
      Historial
    </button>

    <div *ngIf="paymentStatus?.havePaymentLastDay || 0 > 0" class="px-2"></div>

    <button
      mat-flat-button
      (click)="toPay()"
      color="accent"
      [disabled]="paying || !paymentGroup.get('termAndConditions')?.value"
      class="rounded-3 px-5"
    >
      <mat-icon svgIcon="pse" color="accent" class="me-2"></mat-icon> Pagar
      <mat-spinner
        *ngIf="paying"
        [diameter]="20"
        class="d-inline-flex ms-2"
        color="primary"
      ></mat-spinner>
    </button>
  </mat-dialog-actions>
</ng-template>
