import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { provideNgxMask, NgxMaskDirective } from 'ngx-mask';

import { ValidateModule } from '@shared/directives';
import { PaymentPseComponent } from './payment-pse.component';
import { ReportHistoryModule } from '../report-history/report-history.module';
import { LoadingDataModule } from '../loading-data/loading-data.module';

@NgModule({
  declarations: [PaymentPseComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    NgxMaskDirective,

    ReportHistoryModule,
    LoadingDataModule,
    ValidateModule,
  ],
  exports: [PaymentPseComponent],
  providers: [DecimalPipe, provideNgxMask()],
})
export class PaymentPseModule {}
