import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment/environment';
import { Observable } from 'rxjs';

const APIDATA = environment.apiIFC


@Injectable({
  providedIn: 'root'
})
export class DownRequestService {

  constructor(private http: HttpClient) {}

  getRequestPDF(creditRenewId: string): Observable<Blob> {
    return this.http.get(`${APIDATA}/api/CreditRenew/certificate/${creditRenewId}`, {
      responseType: 'blob'
    })
  }

}
