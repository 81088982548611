import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { NotificationComponent } from './notification.component';


@NgModule({
  declarations: [NotificationComponent],
  imports: [
    CommonModule,
    RouterModule,

    MatCardModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
  ],
  exports: [NotificationComponent]
})
export class NotificationModule { }
