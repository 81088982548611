import {
  Component,
  Inject,
} from "@angular/core"
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog"
import { CreditInfo, CreditStatusViewModel } from '@ifc-api/models'
import { CreditInfoService } from '@ifc-api/services'
import { TypesOfReports } from '@shared/models'
import { CommonService } from '@shared/services'
import { take } from "rxjs"
import Swal from "sweetalert2"
import { ReportHistoryComponent } from '../report-history/report-history.component'

@Component({
  selector: 'app-account-status',
  templateUrl: './account-status.component.html',
  styleUrls: ['./account-status.component.scss']
})
export class AccountStatusComponent {

  status?: CreditStatusViewModel
  typesOfReports = TypesOfReports

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<AccountStatusComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    private creditInforService: CreditInfoService,
    private commonService: CommonService,
  ) {
    this.getStatus()
  }

  getStatus(): void {
    const creditNumber = this.dialogData.creditInfoDetail.creditNumber || 0

    this.creditInforService
      .apiCreditInfoStatusDocumentIdCreditNumberGet$Json({
        documentId: this.dialogData.document,
        creditNumber,
      })
      .pipe(take(1))
      .subscribe({
        next: (status) => {
          this.status = status
        },
        error: (err) => {
          const message = this.commonService.getMessageErrorHTTP(err)

          Swal.fire({
            icon: 'error',
            title: 'Upss.. 🙁',
            text: message,
            confirmButtonText: 'Aceptar'
          }).then(() => {
            this.dialogRef.close()
          })
        }
      })
  }

  presentReportHistory(): void {
    const dialogRef = this.dialog.open(ReportHistoryComponent, {
      data: {
        reportType: this.typesOfReports.Status,
        creditNumber: this.dialogData.creditInfoDetail.creditNumber,
      }
    })
  }

}

interface DialogData {
  document: string
  creditInfoDetail: CreditInfo
}
