/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { CityViewModel } from '../models/city-view-model';
import { CountryViewModel } from '../models/country-view-model';
import { PaymentStatusTypeInt32TypesCombosViewModel } from '../models/payment-status-type-int-32-types-combos-view-model';
import { PersonDocumentTypeInt32TypesCombosViewModel } from '../models/person-document-type-int-32-types-combos-view-model';
import { PersonTypeInt32TypesCombosViewModel } from '../models/person-type-int-32-types-combos-view-model';
import { ProgramUniversityViewModel } from '../models/program-university-view-model';
import { RegionViewModel } from '../models/region-view-model';
import { RequestSubjectInt32TypesCombosViewModel } from '../models/request-subject-int-32-types-combos-view-model';
import { RequestTypeInt32TypesCombosViewModel } from '../models/request-type-int-32-types-combos-view-model';
import { ResponseTypeInt32TypesCombosViewModel } from '../models/response-type-int-32-types-combos-view-model';
import { StatusTypeInt32TypesCombosViewModel } from '../models/status-type-int-32-types-combos-view-model';
import { UniversityViewModel } from '../models/university-view-model';

@Injectable({ providedIn: 'root' })
export class TypesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiTypesUniversidadesCityIdGet()` */
  static readonly ApiTypesUniversidadesCityIdGetPath = '/api/Types/universidades/{cityId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTypesUniversidadesCityIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesUniversidadesCityIdGet$Plain$Response(
    params: {
      cityId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<UniversityViewModel>>> {
    const rb = new RequestBuilder(this.rootUrl, TypesService.ApiTypesUniversidadesCityIdGetPath, 'get');
    if (params) {
      rb.path('cityId', params.cityId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UniversityViewModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTypesUniversidadesCityIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesUniversidadesCityIdGet$Plain(
    params: {
      cityId: number;
    },
    context?: HttpContext
  ): Observable<Array<UniversityViewModel>> {
    return this.apiTypesUniversidadesCityIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UniversityViewModel>>): Array<UniversityViewModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTypesUniversidadesCityIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesUniversidadesCityIdGet$Json$Response(
    params: {
      cityId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<UniversityViewModel>>> {
    const rb = new RequestBuilder(this.rootUrl, TypesService.ApiTypesUniversidadesCityIdGetPath, 'get');
    if (params) {
      rb.path('cityId', params.cityId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UniversityViewModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTypesUniversidadesCityIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesUniversidadesCityIdGet$Json(
    params: {
      cityId: number;
    },
    context?: HttpContext
  ): Observable<Array<UniversityViewModel>> {
    return this.apiTypesUniversidadesCityIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UniversityViewModel>>): Array<UniversityViewModel> => r.body)
    );
  }

  /** Path part for operation `apiTypesUniversidadesProgramasUniversityIdGet()` */
  static readonly ApiTypesUniversidadesProgramasUniversityIdGetPath = '/api/Types/universidades/programas/{universityId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTypesUniversidadesProgramasUniversityIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesUniversidadesProgramasUniversityIdGet$Plain$Response(
    params: {
      universityId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ProgramUniversityViewModel>>> {
    const rb = new RequestBuilder(this.rootUrl, TypesService.ApiTypesUniversidadesProgramasUniversityIdGetPath, 'get');
    if (params) {
      rb.path('universityId', params.universityId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProgramUniversityViewModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTypesUniversidadesProgramasUniversityIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesUniversidadesProgramasUniversityIdGet$Plain(
    params: {
      universityId: number;
    },
    context?: HttpContext
  ): Observable<Array<ProgramUniversityViewModel>> {
    return this.apiTypesUniversidadesProgramasUniversityIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProgramUniversityViewModel>>): Array<ProgramUniversityViewModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTypesUniversidadesProgramasUniversityIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesUniversidadesProgramasUniversityIdGet$Json$Response(
    params: {
      universityId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ProgramUniversityViewModel>>> {
    const rb = new RequestBuilder(this.rootUrl, TypesService.ApiTypesUniversidadesProgramasUniversityIdGetPath, 'get');
    if (params) {
      rb.path('universityId', params.universityId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProgramUniversityViewModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTypesUniversidadesProgramasUniversityIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesUniversidadesProgramasUniversityIdGet$Json(
    params: {
      universityId: number;
    },
    context?: HttpContext
  ): Observable<Array<ProgramUniversityViewModel>> {
    return this.apiTypesUniversidadesProgramasUniversityIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ProgramUniversityViewModel>>): Array<ProgramUniversityViewModel> => r.body)
    );
  }

  /** Path part for operation `apiTypesPaisesGet()` */
  static readonly ApiTypesPaisesGetPath = '/api/Types/paises';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTypesPaisesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesPaisesGet$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CountryViewModel>>> {
    const rb = new RequestBuilder(this.rootUrl, TypesService.ApiTypesPaisesGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CountryViewModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTypesPaisesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesPaisesGet$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<CountryViewModel>> {
    return this.apiTypesPaisesGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CountryViewModel>>): Array<CountryViewModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTypesPaisesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesPaisesGet$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CountryViewModel>>> {
    const rb = new RequestBuilder(this.rootUrl, TypesService.ApiTypesPaisesGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CountryViewModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTypesPaisesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesPaisesGet$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<CountryViewModel>> {
    return this.apiTypesPaisesGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CountryViewModel>>): Array<CountryViewModel> => r.body)
    );
  }

  /** Path part for operation `apiTypesDeptosGet()` */
  static readonly ApiTypesDeptosGetPath = '/api/Types/deptos';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTypesDeptosGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesDeptosGet$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<RegionViewModel>>> {
    const rb = new RequestBuilder(this.rootUrl, TypesService.ApiTypesDeptosGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RegionViewModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTypesDeptosGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesDeptosGet$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<RegionViewModel>> {
    return this.apiTypesDeptosGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RegionViewModel>>): Array<RegionViewModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTypesDeptosGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesDeptosGet$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<RegionViewModel>>> {
    const rb = new RequestBuilder(this.rootUrl, TypesService.ApiTypesDeptosGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RegionViewModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTypesDeptosGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesDeptosGet$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<Array<RegionViewModel>> {
    return this.apiTypesDeptosGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RegionViewModel>>): Array<RegionViewModel> => r.body)
    );
  }

  /** Path part for operation `apiTypesPaisesDeptosPaisIdGet()` */
  static readonly ApiTypesPaisesDeptosPaisIdGetPath = '/api/Types/paises/deptos/{paisId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTypesPaisesDeptosPaisIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesPaisesDeptosPaisIdGet$Plain$Response(
    params: {
      paisId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<RegionViewModel>>> {
    const rb = new RequestBuilder(this.rootUrl, TypesService.ApiTypesPaisesDeptosPaisIdGetPath, 'get');
    if (params) {
      rb.path('paisId', params.paisId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RegionViewModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTypesPaisesDeptosPaisIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesPaisesDeptosPaisIdGet$Plain(
    params: {
      paisId: number;
    },
    context?: HttpContext
  ): Observable<Array<RegionViewModel>> {
    return this.apiTypesPaisesDeptosPaisIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RegionViewModel>>): Array<RegionViewModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTypesPaisesDeptosPaisIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesPaisesDeptosPaisIdGet$Json$Response(
    params: {
      paisId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<RegionViewModel>>> {
    const rb = new RequestBuilder(this.rootUrl, TypesService.ApiTypesPaisesDeptosPaisIdGetPath, 'get');
    if (params) {
      rb.path('paisId', params.paisId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RegionViewModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTypesPaisesDeptosPaisIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesPaisesDeptosPaisIdGet$Json(
    params: {
      paisId: number;
    },
    context?: HttpContext
  ): Observable<Array<RegionViewModel>> {
    return this.apiTypesPaisesDeptosPaisIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RegionViewModel>>): Array<RegionViewModel> => r.body)
    );
  }

  /** Path part for operation `apiTypesCitiesRegionidGet()` */
  static readonly ApiTypesCitiesRegionidGetPath = '/api/Types/cities/{regionid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTypesCitiesRegionidGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesCitiesRegionidGet$Plain$Response(
    params: {
      regionid: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CityViewModel>>> {
    const rb = new RequestBuilder(this.rootUrl, TypesService.ApiTypesCitiesRegionidGetPath, 'get');
    if (params) {
      rb.path('regionid', params.regionid, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CityViewModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTypesCitiesRegionidGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesCitiesRegionidGet$Plain(
    params: {
      regionid: number;
    },
    context?: HttpContext
  ): Observable<Array<CityViewModel>> {
    return this.apiTypesCitiesRegionidGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CityViewModel>>): Array<CityViewModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTypesCitiesRegionidGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesCitiesRegionidGet$Json$Response(
    params: {
      regionid: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CityViewModel>>> {
    const rb = new RequestBuilder(this.rootUrl, TypesService.ApiTypesCitiesRegionidGetPath, 'get');
    if (params) {
      rb.path('regionid', params.regionid, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CityViewModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTypesCitiesRegionidGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesCitiesRegionidGet$Json(
    params: {
      regionid: number;
    },
    context?: HttpContext
  ): Observable<Array<CityViewModel>> {
    return this.apiTypesCitiesRegionidGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CityViewModel>>): Array<CityViewModel> => r.body)
    );
  }

  /** Path part for operation `apiTypesCitiesDaneCodigoDaneGet()` */
  static readonly ApiTypesCitiesDaneCodigoDaneGetPath = '/api/Types/cities/dane/{codigoDane}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTypesCitiesDaneCodigoDaneGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesCitiesDaneCodigoDaneGet$Plain$Response(
    params: {
      codigoDane: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CityViewModel>>> {
    const rb = new RequestBuilder(this.rootUrl, TypesService.ApiTypesCitiesDaneCodigoDaneGetPath, 'get');
    if (params) {
      rb.path('codigoDane', params.codigoDane, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CityViewModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTypesCitiesDaneCodigoDaneGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesCitiesDaneCodigoDaneGet$Plain(
    params: {
      codigoDane: string;
    },
    context?: HttpContext
  ): Observable<Array<CityViewModel>> {
    return this.apiTypesCitiesDaneCodigoDaneGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CityViewModel>>): Array<CityViewModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTypesCitiesDaneCodigoDaneGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesCitiesDaneCodigoDaneGet$Json$Response(
    params: {
      codigoDane: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CityViewModel>>> {
    const rb = new RequestBuilder(this.rootUrl, TypesService.ApiTypesCitiesDaneCodigoDaneGetPath, 'get');
    if (params) {
      rb.path('codigoDane', params.codigoDane, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CityViewModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTypesCitiesDaneCodigoDaneGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesCitiesDaneCodigoDaneGet$Json(
    params: {
      codigoDane: string;
    },
    context?: HttpContext
  ): Observable<Array<CityViewModel>> {
    return this.apiTypesCitiesDaneCodigoDaneGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CityViewModel>>): Array<CityViewModel> => r.body)
    );
  }

  /** Path part for operation `apiTypesDocumentTypeGet()` */
  static readonly ApiTypesDocumentTypeGetPath = '/api/Types/documentType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTypesDocumentTypeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesDocumentTypeGet$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PersonDocumentTypeInt32TypesCombosViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, TypesService.ApiTypesDocumentTypeGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PersonDocumentTypeInt32TypesCombosViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTypesDocumentTypeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesDocumentTypeGet$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<PersonDocumentTypeInt32TypesCombosViewModel> {
    return this.apiTypesDocumentTypeGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PersonDocumentTypeInt32TypesCombosViewModel>): PersonDocumentTypeInt32TypesCombosViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTypesDocumentTypeGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesDocumentTypeGet$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PersonDocumentTypeInt32TypesCombosViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, TypesService.ApiTypesDocumentTypeGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PersonDocumentTypeInt32TypesCombosViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTypesDocumentTypeGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesDocumentTypeGet$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<PersonDocumentTypeInt32TypesCombosViewModel> {
    return this.apiTypesDocumentTypeGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PersonDocumentTypeInt32TypesCombosViewModel>): PersonDocumentTypeInt32TypesCombosViewModel => r.body)
    );
  }

  /** Path part for operation `apiTypesPersonTypeGet()` */
  static readonly ApiTypesPersonTypeGetPath = '/api/Types/personType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTypesPersonTypeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesPersonTypeGet$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PersonTypeInt32TypesCombosViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, TypesService.ApiTypesPersonTypeGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PersonTypeInt32TypesCombosViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTypesPersonTypeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesPersonTypeGet$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<PersonTypeInt32TypesCombosViewModel> {
    return this.apiTypesPersonTypeGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PersonTypeInt32TypesCombosViewModel>): PersonTypeInt32TypesCombosViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTypesPersonTypeGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesPersonTypeGet$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PersonTypeInt32TypesCombosViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, TypesService.ApiTypesPersonTypeGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PersonTypeInt32TypesCombosViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTypesPersonTypeGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesPersonTypeGet$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<PersonTypeInt32TypesCombosViewModel> {
    return this.apiTypesPersonTypeGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PersonTypeInt32TypesCombosViewModel>): PersonTypeInt32TypesCombosViewModel => r.body)
    );
  }

  /** Path part for operation `apiTypesSubjectTypeGet()` */
  static readonly ApiTypesSubjectTypeGetPath = '/api/Types/subjectType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTypesSubjectTypeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesSubjectTypeGet$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<RequestSubjectInt32TypesCombosViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, TypesService.ApiTypesSubjectTypeGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestSubjectInt32TypesCombosViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTypesSubjectTypeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesSubjectTypeGet$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<RequestSubjectInt32TypesCombosViewModel> {
    return this.apiTypesSubjectTypeGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RequestSubjectInt32TypesCombosViewModel>): RequestSubjectInt32TypesCombosViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTypesSubjectTypeGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesSubjectTypeGet$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<RequestSubjectInt32TypesCombosViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, TypesService.ApiTypesSubjectTypeGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestSubjectInt32TypesCombosViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTypesSubjectTypeGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesSubjectTypeGet$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<RequestSubjectInt32TypesCombosViewModel> {
    return this.apiTypesSubjectTypeGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<RequestSubjectInt32TypesCombosViewModel>): RequestSubjectInt32TypesCombosViewModel => r.body)
    );
  }

  /** Path part for operation `apiTypesRequestTypeGet()` */
  static readonly ApiTypesRequestTypeGetPath = '/api/Types/requestType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTypesRequestTypeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesRequestTypeGet$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<RequestTypeInt32TypesCombosViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, TypesService.ApiTypesRequestTypeGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestTypeInt32TypesCombosViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTypesRequestTypeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesRequestTypeGet$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<RequestTypeInt32TypesCombosViewModel> {
    return this.apiTypesRequestTypeGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<RequestTypeInt32TypesCombosViewModel>): RequestTypeInt32TypesCombosViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTypesRequestTypeGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesRequestTypeGet$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<RequestTypeInt32TypesCombosViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, TypesService.ApiTypesRequestTypeGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequestTypeInt32TypesCombosViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTypesRequestTypeGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesRequestTypeGet$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<RequestTypeInt32TypesCombosViewModel> {
    return this.apiTypesRequestTypeGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<RequestTypeInt32TypesCombosViewModel>): RequestTypeInt32TypesCombosViewModel => r.body)
    );
  }

  /** Path part for operation `apiTypesResponseTypeGet()` */
  static readonly ApiTypesResponseTypeGetPath = '/api/Types/responseType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTypesResponseTypeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesResponseTypeGet$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ResponseTypeInt32TypesCombosViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, TypesService.ApiTypesResponseTypeGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResponseTypeInt32TypesCombosViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTypesResponseTypeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesResponseTypeGet$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<ResponseTypeInt32TypesCombosViewModel> {
    return this.apiTypesResponseTypeGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseTypeInt32TypesCombosViewModel>): ResponseTypeInt32TypesCombosViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTypesResponseTypeGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesResponseTypeGet$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ResponseTypeInt32TypesCombosViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, TypesService.ApiTypesResponseTypeGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ResponseTypeInt32TypesCombosViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTypesResponseTypeGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesResponseTypeGet$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<ResponseTypeInt32TypesCombosViewModel> {
    return this.apiTypesResponseTypeGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ResponseTypeInt32TypesCombosViewModel>): ResponseTypeInt32TypesCombosViewModel => r.body)
    );
  }

  /** Path part for operation `apiTypesStatusTypeGet()` */
  static readonly ApiTypesStatusTypeGetPath = '/api/Types/statusType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTypesStatusTypeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesStatusTypeGet$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<StatusTypeInt32TypesCombosViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, TypesService.ApiTypesStatusTypeGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StatusTypeInt32TypesCombosViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTypesStatusTypeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesStatusTypeGet$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<StatusTypeInt32TypesCombosViewModel> {
    return this.apiTypesStatusTypeGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<StatusTypeInt32TypesCombosViewModel>): StatusTypeInt32TypesCombosViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTypesStatusTypeGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesStatusTypeGet$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<StatusTypeInt32TypesCombosViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, TypesService.ApiTypesStatusTypeGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<StatusTypeInt32TypesCombosViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTypesStatusTypeGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesStatusTypeGet$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<StatusTypeInt32TypesCombosViewModel> {
    return this.apiTypesStatusTypeGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<StatusTypeInt32TypesCombosViewModel>): StatusTypeInt32TypesCombosViewModel => r.body)
    );
  }

  /** Path part for operation `apiTypesPaymentStatusTypeGet()` */
  static readonly ApiTypesPaymentStatusTypeGetPath = '/api/Types/paymentStatusType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTypesPaymentStatusTypeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesPaymentStatusTypeGet$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PaymentStatusTypeInt32TypesCombosViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, TypesService.ApiTypesPaymentStatusTypeGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaymentStatusTypeInt32TypesCombosViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTypesPaymentStatusTypeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesPaymentStatusTypeGet$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<PaymentStatusTypeInt32TypesCombosViewModel> {
    return this.apiTypesPaymentStatusTypeGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentStatusTypeInt32TypesCombosViewModel>): PaymentStatusTypeInt32TypesCombosViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiTypesPaymentStatusTypeGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesPaymentStatusTypeGet$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PaymentStatusTypeInt32TypesCombosViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, TypesService.ApiTypesPaymentStatusTypeGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PaymentStatusTypeInt32TypesCombosViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiTypesPaymentStatusTypeGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiTypesPaymentStatusTypeGet$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<PaymentStatusTypeInt32TypesCombosViewModel> {
    return this.apiTypesPaymentStatusTypeGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaymentStatusTypeInt32TypesCombosViewModel>): PaymentStatusTypeInt32TypesCombosViewModel => r.body)
    );
  }

}
