/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { GetTransactionsDetailsQuery } from '../models/get-transactions-details-query';
import { GetTransactionsDetailsViewModel } from '../models/get-transactions-details-view-model';
import { GetTransactionsQuery } from '../models/get-transactions-query';
import { GetUserListQuery } from '../models/get-user-list-query';
import { StatisticViewModel } from '../models/statistic-view-model';
import { StatisticsQuery } from '../models/statistics-query';
import { UserListViewModel } from '../models/user-list-view-model';

@Injectable({ providedIn: 'root' })
export class StatisticsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiStatisticsUsePost()` */
  static readonly ApiStatisticsUsePostPath = '/api/Statistics/use';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStatisticsUsePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStatisticsUsePost$Plain$Response(
    params?: {
      body?: StatisticsQuery
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<StatisticViewModel>>> {
    const rb = new RequestBuilder(this.rootUrl, StatisticsService.ApiStatisticsUsePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StatisticViewModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStatisticsUsePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStatisticsUsePost$Plain(
    params?: {
      body?: StatisticsQuery
    },
    context?: HttpContext
  ): Observable<Array<StatisticViewModel>> {
    return this.apiStatisticsUsePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<StatisticViewModel>>): Array<StatisticViewModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStatisticsUsePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStatisticsUsePost$Json$Response(
    params?: {
      body?: StatisticsQuery
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<StatisticViewModel>>> {
    const rb = new RequestBuilder(this.rootUrl, StatisticsService.ApiStatisticsUsePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StatisticViewModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStatisticsUsePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStatisticsUsePost$Json(
    params?: {
      body?: StatisticsQuery
    },
    context?: HttpContext
  ): Observable<Array<StatisticViewModel>> {
    return this.apiStatisticsUsePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<StatisticViewModel>>): Array<StatisticViewModel> => r.body)
    );
  }

  /** Path part for operation `apiStatisticsDetailsPost()` */
  static readonly ApiStatisticsDetailsPostPath = '/api/Statistics/details';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStatisticsDetailsPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStatisticsDetailsPost$Plain$Response(
    params?: {
      body?: GetTransactionsQuery
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<StatisticViewModel>>> {
    const rb = new RequestBuilder(this.rootUrl, StatisticsService.ApiStatisticsDetailsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StatisticViewModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStatisticsDetailsPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStatisticsDetailsPost$Plain(
    params?: {
      body?: GetTransactionsQuery
    },
    context?: HttpContext
  ): Observable<Array<StatisticViewModel>> {
    return this.apiStatisticsDetailsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<StatisticViewModel>>): Array<StatisticViewModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStatisticsDetailsPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStatisticsDetailsPost$Json$Response(
    params?: {
      body?: GetTransactionsQuery
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<StatisticViewModel>>> {
    const rb = new RequestBuilder(this.rootUrl, StatisticsService.ApiStatisticsDetailsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StatisticViewModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStatisticsDetailsPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStatisticsDetailsPost$Json(
    params?: {
      body?: GetTransactionsQuery
    },
    context?: HttpContext
  ): Observable<Array<StatisticViewModel>> {
    return this.apiStatisticsDetailsPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<StatisticViewModel>>): Array<StatisticViewModel> => r.body)
    );
  }

  /** Path part for operation `apiStatisticsDetailsListPost()` */
  static readonly ApiStatisticsDetailsListPostPath = '/api/Statistics/details-list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStatisticsDetailsListPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStatisticsDetailsListPost$Plain$Response(
    params?: {
      body?: GetTransactionsDetailsQuery
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<GetTransactionsDetailsViewModel>>> {
    const rb = new RequestBuilder(this.rootUrl, StatisticsService.ApiStatisticsDetailsListPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GetTransactionsDetailsViewModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStatisticsDetailsListPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStatisticsDetailsListPost$Plain(
    params?: {
      body?: GetTransactionsDetailsQuery
    },
    context?: HttpContext
  ): Observable<Array<GetTransactionsDetailsViewModel>> {
    return this.apiStatisticsDetailsListPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GetTransactionsDetailsViewModel>>): Array<GetTransactionsDetailsViewModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStatisticsDetailsListPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStatisticsDetailsListPost$Json$Response(
    params?: {
      body?: GetTransactionsDetailsQuery
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<GetTransactionsDetailsViewModel>>> {
    const rb = new RequestBuilder(this.rootUrl, StatisticsService.ApiStatisticsDetailsListPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GetTransactionsDetailsViewModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStatisticsDetailsListPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStatisticsDetailsListPost$Json(
    params?: {
      body?: GetTransactionsDetailsQuery
    },
    context?: HttpContext
  ): Observable<Array<GetTransactionsDetailsViewModel>> {
    return this.apiStatisticsDetailsListPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GetTransactionsDetailsViewModel>>): Array<GetTransactionsDetailsViewModel> => r.body)
    );
  }

  /** Path part for operation `apiStatisticsUsersPost()` */
  static readonly ApiStatisticsUsersPostPath = '/api/Statistics/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStatisticsUsersPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStatisticsUsersPost$Plain$Response(
    params?: {
      body?: GetUserListQuery
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<UserListViewModel>>> {
    const rb = new RequestBuilder(this.rootUrl, StatisticsService.ApiStatisticsUsersPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserListViewModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStatisticsUsersPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStatisticsUsersPost$Plain(
    params?: {
      body?: GetUserListQuery
    },
    context?: HttpContext
  ): Observable<Array<UserListViewModel>> {
    return this.apiStatisticsUsersPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserListViewModel>>): Array<UserListViewModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiStatisticsUsersPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStatisticsUsersPost$Json$Response(
    params?: {
      body?: GetUserListQuery
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<UserListViewModel>>> {
    const rb = new RequestBuilder(this.rootUrl, StatisticsService.ApiStatisticsUsersPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<UserListViewModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiStatisticsUsersPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiStatisticsUsersPost$Json(
    params?: {
      body?: GetUserListQuery
    },
    context?: HttpContext
  ): Observable<Array<UserListViewModel>> {
    return this.apiStatisticsUsersPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserListViewModel>>): Array<UserListViewModel> => r.body)
    );
  }

}
