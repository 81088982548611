import {
  Inject,
  Injectable,
  PLATFORM_ID
  ,
} from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BehaviorSubject, Observable } from 'rxjs';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  #isMobile = false
  #isTablet = false
  #isDark = false
  #isDarkObserver = new BehaviorSubject<boolean>(false)

  constructor(
    breakpointObserver: BreakpointObserver,
    @Inject(PLATFORM_ID) private platformId: string,
    private snackBar: MatSnackBar,
  ) {
    breakpointObserver.observe(['(max-width: 761.98px)'])
      .subscribe((state) => (this.#isMobile = state.matches ? true : false))

    breakpointObserver.observe(['(max-width: 991.98px)'])
      .subscribe((state) => (this.#isTablet = state.matches ? true : false))

    const brightness = this.getDataStorage<{ mode: 'light' | 'dark' }>('brightness')
    this.#isDark = brightness?.mode === 'dark' ? true : false
    this.#isDarkObserver.next(this.#isDark)
  }

  get isMobile(): boolean {
    return this.#isMobile
  }

  get isTablet(): boolean {
    return this.#isTablet && !this.#isMobile
  }

  get isTabletOrMobile(): boolean {
    return this.#isTablet || this.#isMobile
  }

  get isDark(): boolean {
    return this.#isDark
  }

  get isDarkObserver(): Observable<boolean> {
    return this.#isDarkObserver.asObservable()
  }

  presentSnackBar(message: string, data?: { duration?: number }): void {
    this.snackBar.open(message, 'Ok', {
      duration: data?.duration ?? 3500
    })
  }

  setDataStorage(key: string, value: { [key: string]: any }): Promise<void> {
    return new Promise((resolve, reject) => {
      if (isPlatformBrowser(this.platformId)) {
        localStorage.setItem(key, JSON.stringify(value))
        resolve()
      } else {
        reject()
      }
    })
  }

  getDataStorage<T>(key: string): T | null {
    if (isPlatformBrowser(this.platformId)) {
      const data = localStorage.getItem(key)
      return data ? JSON.parse(data) : null
    } else {
      return null
    }
  }

  removeDataStorage(key: string): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem(key)
    }
  }


  patternValidator(regex: RegExp, error: { [key: string]: boolean }): ValidatorFn | null {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (!control.value) {
        // if control is empty return no error
        return null
      }

      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);

      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : error
    }
  }

  matchValidator(controlName: string, matchingControlName: string): ValidatorFn | null {
    return (abstractControl: AbstractControl): { [key: string]: boolean } | null => {
      const _control = abstractControl.get(controlName)
      const _controlConfirmation = abstractControl.get(matchingControlName)

      if (!_control || !_controlConfirmation) { return null }

      if (_control.value === _controlConfirmation.value) {
        _controlConfirmation.setErrors(null)
      } else {
        _controlConfirmation.setErrors({ confirmed: true })
      }

      return null
    }
  }

  requiredIfValidator(
    controlName: string,
    requiredControlName: string,
    matchValue: string | boolean | number,
    requiredTrue: boolean = false
  ): ValidatorFn | null {
    return (abstractControl: AbstractControl): { [key: string]: boolean } | null => {
      const _control = abstractControl.get(controlName)
      const _controlRequired = abstractControl.get(requiredControlName)

      if (!_control || !_controlRequired) { return null }

      if (_control.value === matchValue && !_controlRequired.value) {

        if (requiredTrue) {
          _controlRequired.setErrors({ requiredTrue: true })
        } else {
          _controlRequired.setErrors({ required: true })
        }

      } else {
        _controlRequired.setErrors(null)
      }

      return null
    }
  }

  /**
   * Toma el error y obtiene el mensaje a mostrar
   * @param err Respuesta de error de petición HTTP
   * @param message Mensaje a mostrar en algunos casos
   * @returns Mensaje
   */
  getMessageErrorHTTP(err: any, message?: string): string {
    let message_ = ''

    if (err?.error && typeof err?.error === 'string') {

      message_ = err.error

    } else if (err?.error?.error && typeof err.error.error === 'string') {

      message_ = err.error.error

    } else if (err?.error?.errors && (Array.isArray(err.error.errors) || typeof err.error.errors === 'object')) {

      if (Array.isArray(err.error.errors)) {
        message_ = Array.from(<[]>err.error.errors).map((error: string | { message: string }) => {
          if (typeof error === 'string') {
            return error
          }

          return error.message
        }).join(' <br /> ')
      } else {

        message_ = Object.values(<[]>err.error.errors).map((error: string[]) => {
          return error.join(' <br /> ')
        }).join(' <br />')

      }
    } else if (message) {
      message_ = message
    }

    if (!message_) {
      message_ = err?.statusText
    }

    return message_
  }

  isNumber(value: string): boolean {
    if (value.trim() === '') return false

    if ((/\D/).test(value) == true) {
      return false
    } else {
      return true
    }
  }

  /**
   * Ordenar lista
   * @param a a
   * @param b b
   * @returns value to sort 0, 1 or -1
   */
  compare(
    a?: string | number | Date | boolean | null,
    b?: string | number | Date | boolean | null,
  ): number {
    if (a === undefined || b === undefined) return 0
    if (a === null || b === null) return 0

    if (typeof a === 'string' && typeof b === 'string') {
      a = this.toNormalize(a)
      b = this.toNormalize(b)

      if (a < b) return -1
      if (a > b) return 1
    } else {
      if (a < b) return -1
      if (a > b) return 1
    }

    return 0
  }

  toNormalize(str: string) {
    if (!str) {
      return str
    }

    const from =
      "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆĞÍÌÎÏİŇÑÓÖÒÔÕØŘŔŠŞŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇğíìîïıňñóöòôõøðřŕšşťúůüùûýÿžþÞĐđßÆa·/_,:;";
    const to =
      "AAAAAACCCDEEEEEEEEGIIIIINNOOOOOORRSSTUUUUUYYZaaaaaacccdeeeeeeeegiiiiinnooooooorrsstuuuuuyyzbBDdBAa------";

    for (let i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    return str.trim();
  }

}
