import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { AccountStatusComponent } from './account-status.component';
import { LoadingDataModule } from '../loading-data/loading-data.module';
import { ButtonPdfModule } from '../button-pdf/button-pdf.module';
import { ReportHistoryModule } from '../report-history/report-history.module';


@NgModule({
  declarations: [AccountStatusComponent],
  imports: [
    CommonModule,

    MatDialogModule,
    MatButtonModule,
    MatIconModule,

    LoadingDataModule,
    ButtonPdfModule,
    ReportHistoryModule,
  ],
  exports: [AccountStatusComponent],
})
export class AccountStatusModule { }
