import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginatePipe } from './paginate.pipe';
import { PaginationService } from './pagination.service';



@NgModule({
  declarations: [
    PaginatePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [PaginatePipe],
  providers: [PaginationService],
})
export class PaginateModule { }
