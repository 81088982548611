import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';

import { ArrayGetValueModule } from '@shared/pipes';
import { FescaRequestDetailsComponent } from './fesca-request-details.component';

@NgModule({
  declarations: [FescaRequestDetailsComponent],
  imports: [
    CommonModule,

    MatListModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatExpansionModule,

    ArrayGetValueModule
  ],
  exports: [FescaRequestDetailsComponent]
})
export class FescaRequestDetailsModule {}
