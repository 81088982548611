import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { NgApexchartsModule } from "ng-apexcharts";

import { ChartPiePqrsComponent } from './chart-pie-pqrs.component';

@NgModule({
  declarations: [
    ChartPiePqrsComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    NgApexchartsModule,
  ],
  exports: [ChartPiePqrsComponent]
})
export class ChartPiePqrsModule {}
