/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { GetHistoryViewModel } from '../models/get-history-view-model';
import { PaymentsReportQuery } from '../models/payments-report-query';
import { ReportType } from '../models/report-type';

@Injectable({ providedIn: 'root' })
export class ReportsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiReportsReportTypeCreditNumberYearGet()` */
  static readonly ApiReportsReportTypeCreditNumberYearGetPath = '/api/Reports/{reportType}/{creditNumber}/{year}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiReportsReportTypeCreditNumberYearGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsReportTypeCreditNumberYearGet$Response(
    params: {
      reportType: ReportType;
      creditNumber: number;
      year: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ReportsService.ApiReportsReportTypeCreditNumberYearGetPath, 'get');
    if (params) {
      rb.path('reportType', params.reportType, {});
      rb.path('creditNumber', params.creditNumber, {});
      rb.path('year', params.year, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiReportsReportTypeCreditNumberYearGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsReportTypeCreditNumberYearGet(
    params: {
      reportType: ReportType;
      creditNumber: number;
      year: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiReportsReportTypeCreditNumberYearGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiReportsAdminReportTypeDocIdCreditNumberYearGet()` */
  static readonly ApiReportsAdminReportTypeDocIdCreditNumberYearGetPath = '/api/Reports/admin/{reportType}/{docId}/{creditNumber}/{year}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiReportsAdminReportTypeDocIdCreditNumberYearGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsAdminReportTypeDocIdCreditNumberYearGet$Response(
    params: {
      reportType: ReportType;
      creditNumber: number;
      docId: string;
      year: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ReportsService.ApiReportsAdminReportTypeDocIdCreditNumberYearGetPath, 'get');
    if (params) {
      rb.path('reportType', params.reportType, {});
      rb.path('creditNumber', params.creditNumber, {});
      rb.path('docId', params.docId, {});
      rb.path('year', params.year, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiReportsAdminReportTypeDocIdCreditNumberYearGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsAdminReportTypeDocIdCreditNumberYearGet(
    params: {
      reportType: ReportType;
      creditNumber: number;
      docId: string;
      year: number;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiReportsAdminReportTypeDocIdCreditNumberYearGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiReportsPaymentsPost()` */
  static readonly ApiReportsPaymentsPostPath = '/api/Reports/payments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiReportsPaymentsPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiReportsPaymentsPost$Response(
    params?: {
      body?: PaymentsReportQuery
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, ReportsService.ApiReportsPaymentsPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiReportsPaymentsPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiReportsPaymentsPost(
    params?: {
      body?: PaymentsReportQuery
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiReportsPaymentsPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `apiReportsHistoryReportTypeCreditNumberGet()` */
  static readonly ApiReportsHistoryReportTypeCreditNumberGetPath = '/api/Reports/history/{reportType}/{creditNumber}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiReportsHistoryReportTypeCreditNumberGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsHistoryReportTypeCreditNumberGet$Plain$Response(
    params: {
      reportType: ReportType;
      creditNumber: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<GetHistoryViewModel>>> {
    const rb = new RequestBuilder(this.rootUrl, ReportsService.ApiReportsHistoryReportTypeCreditNumberGetPath, 'get');
    if (params) {
      rb.path('reportType', params.reportType, {});
      rb.path('creditNumber', params.creditNumber, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GetHistoryViewModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiReportsHistoryReportTypeCreditNumberGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsHistoryReportTypeCreditNumberGet$Plain(
    params: {
      reportType: ReportType;
      creditNumber: number;
    },
    context?: HttpContext
  ): Observable<Array<GetHistoryViewModel>> {
    return this.apiReportsHistoryReportTypeCreditNumberGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GetHistoryViewModel>>): Array<GetHistoryViewModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiReportsHistoryReportTypeCreditNumberGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsHistoryReportTypeCreditNumberGet$Json$Response(
    params: {
      reportType: ReportType;
      creditNumber: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<GetHistoryViewModel>>> {
    const rb = new RequestBuilder(this.rootUrl, ReportsService.ApiReportsHistoryReportTypeCreditNumberGetPath, 'get');
    if (params) {
      rb.path('reportType', params.reportType, {});
      rb.path('creditNumber', params.creditNumber, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GetHistoryViewModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiReportsHistoryReportTypeCreditNumberGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiReportsHistoryReportTypeCreditNumberGet$Json(
    params: {
      reportType: ReportType;
      creditNumber: number;
    },
    context?: HttpContext
  ): Observable<Array<GetHistoryViewModel>> {
    return this.apiReportsHistoryReportTypeCreditNumberGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GetHistoryViewModel>>): Array<GetHistoryViewModel> => r.body)
    );
  }

}
