import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firtsUpper'
})
export class FirtsUpperPipe implements PipeTransform {

  transform(value?: string | null) {
    if (value && typeof value === 'string') {
      return value.charAt(0).toUpperCase() + value.slice(1).toLocaleLowerCase()
    }

    return value
  }

}
