import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { AnswerPqrsComponent } from './answer-pqrs.component';
import { ValidateModule } from '../../directives/validate/validate.module';
import { UploadFilesModule } from '../upload-files/upload-files.module';


@NgModule({
  declarations: [AnswerPqrsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,

    ValidateModule,
    UploadFilesModule,
  ],
  exports: [AnswerPqrsComponent]
})
export class AnswerPqrsModule { }
