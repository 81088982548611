import {
  Component,
  Inject,
  OnInit,
} from "@angular/core"
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms"
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog"
import {
  RequestHistoryViewModel,
  RequestResponseCommand,
  StatusType,
} from '@ifc-api/models'
import {
  PqrsService,
  TypesService,
  UserRequestService,
} from '@ifc-api/services'
import {
  AppService,
  CommonService,
} from '@shared/services'
import { take } from "rxjs"
import Swal from "sweetalert2"

@Component({
  selector: 'app-answer-pqrs',
  templateUrl: './answer-pqrs.component.html',
  styleUrls: ['./answer-pqrs.component.scss']
})
export class AnswerPqrsComponent implements OnInit {

  replyGroup: UntypedFormGroup
  statusTypes: StatusType[] = []

  fileDetails: FileDetail[] = []
  uploadingFiles = false

  saving = false

  constructor(
    private dialogRef: MatDialogRef<AnswerPqrsComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
    private typesService: TypesService,
    public commonService: CommonService,
    private formBuilder: UntypedFormBuilder,
    private appService: AppService,
    private pqrsService: PqrsService,
    private userRequestService: UserRequestService,
  ) {
    this.replyGroup = this.formBuilderReplyGroup

    this.getStatusTypes()
    this.setDataReplyGroup()
  }

  ngOnInit(): void {
  }

  private get formBuilderReplyGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      requestId: [null, [Validators.required]],
      statusTypeId: [null, [Validators.required]],
      description: [null, [
        Validators.required, Validators.minLength(100), Validators.maxLength(3000),
      ]],
      isSendFromClient: [null, [Validators.required]],
      senderId: [null, [Validators.required]],
      assignedTo: [null],
      assignedToEmail: [null],
      files: [[]],
    })
  }

  private getStatusTypes(): void {
    this.typesService.apiTypesStatusTypeGet$Json()
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.statusTypes = res.typeCombo || []
          this.setStatusTypeRyplyGroup()
        }
      })
  }

  private setStatusTypeRyplyGroup(): void {
    if (this.appService.isAdmin) {
      this.replyGroup.controls['statusTypeId'].setValue(this.dialogData.request.statusId)
    } else if (!this.appService.isAdmin) {
      const currentStatusObject = this.statusTypes
        .filter(_status => _status.name?.toLowerCase().includes(this.dialogData.request.statusName?.toLowerCase() ?? ''))[0]

      if (currentStatusObject) {
        // this.replyGroup.controls.statusTypeId.setValue(currentStatusObject.id)
        this.replyGroup.controls['statusTypeId'].setValue(1)
        this.replyGroup.controls['statusTypeId'].disable()
      }
    }
  }

  private setDataReplyGroup() {
    this.appService.$user
      .pipe(take(1))
      .subscribe({
        next: (user) => {
          if (user) {
            this.replyGroup.controls['requestId'].setValue(this.dialogData.request.id)
            this.replyGroup.controls['senderId'].setValue(user.id)
            this.replyGroup.controls['isSendFromClient'].setValue(!this.appService.isAdmin)
          }
        }
      })
  }

  replyRequest(): void {
    this.replyGroup.markAllAsTouched()

    if (this.replyGroup.invalid) {
      return
    }

    if (this.uploadingFiles) {
      this.commonService.presentSnackBar('Por favor espere, aún se están cargando archivos')

      return
    }

    this.saving = true
    const files = this.fileDetails.map(({ filePath }) => ({ filePath }))
    const { value } = this.replyGroup

    const data: RequestResponseCommand = { ...value, files }

    if (this.appService.isAdmin) {

      this.pqrsService.apiPqrsSendReplyPost$Json({
        body: data
      })
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            Swal.fire('Respuesta enviada', 'Su respuesta de ha guardado correctamente', 'success')
              .then(() => this.dialogRef.close(res))
          },
          error: (err) => {
            this.saving = false
            const message = this.commonService.getMessageErrorHTTP(err, 'Lo sentimos, ha ocurrido un error')

            Swal.fire('Ups.. 😟', message, 'error')
          }
        })

    } else {
      data.statusTypeId = 1

      this.userRequestService.apiUserRequestSendReplyPost$Json({
        body: data
      })
        .pipe(take(1))
        .subscribe({
          next: (res) => {
            Swal.fire('Respuesta enviada', 'Su respuesta de ha guardado correctamente', 'success')
              .then(() => this.dialogRef.close(res))
          },
          error: (err) => {
            this.saving = false

            const message = this.commonService.getMessageErrorHTTP(err, 'Lo sentimos, ha ocurrido un error')
            Swal.fire('Ups.. 😟', message, 'error')
          }
        })

    }

  }

}

interface DialogData {
  request: RequestHistoryViewModel
}

interface FileDetail {
  fileName: string;
  filePath: string;
  size: number;
  progress: number;
  uploading: boolean;
  uploaded: boolean;
  file: File;
  canceled: boolean;
}
