/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { UpdateUserCommand } from '../models/update-user-command';
import { UpdateUserViewModel } from '../models/update-user-view-model';
import { UserInfoResponse } from '../models/user-info-response';
import { UserInfoViewModel } from '../models/user-info-view-model';
import { ValidationQuestionsViewModel } from '../models/validation-questions-view-model';

@Injectable({ providedIn: 'root' })
export class UserInfoService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiUserInfoIsclientDocumentIdGet()` */
  static readonly ApiUserInfoIsclientDocumentIdGetPath = '/api/UserInfo/isclient/{documentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserInfoIsclientDocumentIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserInfoIsclientDocumentIdGet$Plain$Response(
    params: {
      documentId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UserInfoViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, UserInfoService.ApiUserInfoIsclientDocumentIdGetPath, 'get');
    if (params) {
      rb.path('documentId', params.documentId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserInfoViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUserInfoIsclientDocumentIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserInfoIsclientDocumentIdGet$Plain(
    params: {
      documentId: string;
    },
    context?: HttpContext
  ): Observable<UserInfoViewModel> {
    return this.apiUserInfoIsclientDocumentIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserInfoViewModel>): UserInfoViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserInfoIsclientDocumentIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserInfoIsclientDocumentIdGet$Json$Response(
    params: {
      documentId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UserInfoViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, UserInfoService.ApiUserInfoIsclientDocumentIdGetPath, 'get');
    if (params) {
      rb.path('documentId', params.documentId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserInfoViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUserInfoIsclientDocumentIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserInfoIsclientDocumentIdGet$Json(
    params: {
      documentId: string;
    },
    context?: HttpContext
  ): Observable<UserInfoViewModel> {
    return this.apiUserInfoIsclientDocumentIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserInfoViewModel>): UserInfoViewModel => r.body)
    );
  }

  /** Path part for operation `apiUserInfoIscreditvalidDocumentIdCreditNumberGet()` */
  static readonly ApiUserInfoIscreditvalidDocumentIdCreditNumberGetPath = '/api/UserInfo/iscreditvalid/{documentId}/{creditNumber}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserInfoIscreditvalidDocumentIdCreditNumberGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserInfoIscreditvalidDocumentIdCreditNumberGet$Plain$Response(
    params: {
      documentId: string;
      creditNumber: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UserInfoViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, UserInfoService.ApiUserInfoIscreditvalidDocumentIdCreditNumberGetPath, 'get');
    if (params) {
      rb.path('documentId', params.documentId, {});
      rb.path('creditNumber', params.creditNumber, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserInfoViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUserInfoIscreditvalidDocumentIdCreditNumberGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserInfoIscreditvalidDocumentIdCreditNumberGet$Plain(
    params: {
      documentId: string;
      creditNumber: number;
    },
    context?: HttpContext
  ): Observable<UserInfoViewModel> {
    return this.apiUserInfoIscreditvalidDocumentIdCreditNumberGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserInfoViewModel>): UserInfoViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserInfoIscreditvalidDocumentIdCreditNumberGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserInfoIscreditvalidDocumentIdCreditNumberGet$Json$Response(
    params: {
      documentId: string;
      creditNumber: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UserInfoViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, UserInfoService.ApiUserInfoIscreditvalidDocumentIdCreditNumberGetPath, 'get');
    if (params) {
      rb.path('documentId', params.documentId, {});
      rb.path('creditNumber', params.creditNumber, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserInfoViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUserInfoIscreditvalidDocumentIdCreditNumberGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserInfoIscreditvalidDocumentIdCreditNumberGet$Json(
    params: {
      documentId: string;
      creditNumber: number;
    },
    context?: HttpContext
  ): Observable<UserInfoViewModel> {
    return this.apiUserInfoIscreditvalidDocumentIdCreditNumberGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserInfoViewModel>): UserInfoViewModel => r.body)
    );
  }

  /** Path part for operation `apiUserInfoQuestionsDocumentIdCreditNumberGet()` */
  static readonly ApiUserInfoQuestionsDocumentIdCreditNumberGetPath = '/api/UserInfo/questions/{documentId}/{creditNumber}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserInfoQuestionsDocumentIdCreditNumberGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserInfoQuestionsDocumentIdCreditNumberGet$Plain$Response(
    params: {
      documentId: string;
      creditNumber: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ValidationQuestionsViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, UserInfoService.ApiUserInfoQuestionsDocumentIdCreditNumberGetPath, 'get');
    if (params) {
      rb.path('documentId', params.documentId, {});
      rb.path('creditNumber', params.creditNumber, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ValidationQuestionsViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUserInfoQuestionsDocumentIdCreditNumberGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserInfoQuestionsDocumentIdCreditNumberGet$Plain(
    params: {
      documentId: string;
      creditNumber: number;
    },
    context?: HttpContext
  ): Observable<ValidationQuestionsViewModel> {
    return this.apiUserInfoQuestionsDocumentIdCreditNumberGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ValidationQuestionsViewModel>): ValidationQuestionsViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserInfoQuestionsDocumentIdCreditNumberGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserInfoQuestionsDocumentIdCreditNumberGet$Json$Response(
    params: {
      documentId: string;
      creditNumber: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ValidationQuestionsViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, UserInfoService.ApiUserInfoQuestionsDocumentIdCreditNumberGetPath, 'get');
    if (params) {
      rb.path('documentId', params.documentId, {});
      rb.path('creditNumber', params.creditNumber, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ValidationQuestionsViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUserInfoQuestionsDocumentIdCreditNumberGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserInfoQuestionsDocumentIdCreditNumberGet$Json(
    params: {
      documentId: string;
      creditNumber: number;
    },
    context?: HttpContext
  ): Observable<ValidationQuestionsViewModel> {
    return this.apiUserInfoQuestionsDocumentIdCreditNumberGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ValidationQuestionsViewModel>): ValidationQuestionsViewModel => r.body)
    );
  }

  /** Path part for operation `apiUserInfoValidateGet()` */
  static readonly ApiUserInfoValidateGetPath = '/api/UserInfo/validate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserInfoValidateGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserInfoValidateGet$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UserInfoViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, UserInfoService.ApiUserInfoValidateGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserInfoViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUserInfoValidateGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserInfoValidateGet$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<UserInfoViewModel> {
    return this.apiUserInfoValidateGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserInfoViewModel>): UserInfoViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserInfoValidateGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserInfoValidateGet$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UserInfoViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, UserInfoService.ApiUserInfoValidateGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserInfoViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUserInfoValidateGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserInfoValidateGet$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<UserInfoViewModel> {
    return this.apiUserInfoValidateGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserInfoViewModel>): UserInfoViewModel => r.body)
    );
  }

  /** Path part for operation `apiUserInfoProfileGet()` */
  static readonly ApiUserInfoProfileGetPath = '/api/UserInfo/profile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserInfoProfileGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserInfoProfileGet$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UserInfoResponse>> {
    const rb = new RequestBuilder(this.rootUrl, UserInfoService.ApiUserInfoProfileGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserInfoResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUserInfoProfileGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserInfoProfileGet$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<UserInfoResponse> {
    return this.apiUserInfoProfileGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserInfoResponse>): UserInfoResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserInfoProfileGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserInfoProfileGet$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UserInfoResponse>> {
    const rb = new RequestBuilder(this.rootUrl, UserInfoService.ApiUserInfoProfileGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserInfoResponse>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUserInfoProfileGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserInfoProfileGet$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<UserInfoResponse> {
    return this.apiUserInfoProfileGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserInfoResponse>): UserInfoResponse => r.body)
    );
  }

  /** Path part for operation `apiUserInfoUpdateProfilePost()` */
  static readonly ApiUserInfoUpdateProfilePostPath = '/api/UserInfo/update-profile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserInfoUpdateProfilePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserInfoUpdateProfilePost$Plain$Response(
    params?: {
      body?: UpdateUserCommand
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UpdateUserViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, UserInfoService.ApiUserInfoUpdateProfilePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UpdateUserViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUserInfoUpdateProfilePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserInfoUpdateProfilePost$Plain(
    params?: {
      body?: UpdateUserCommand
    },
    context?: HttpContext
  ): Observable<UpdateUserViewModel> {
    return this.apiUserInfoUpdateProfilePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateUserViewModel>): UpdateUserViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserInfoUpdateProfilePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserInfoUpdateProfilePost$Json$Response(
    params?: {
      body?: UpdateUserCommand
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UpdateUserViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, UserInfoService.ApiUserInfoUpdateProfilePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UpdateUserViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUserInfoUpdateProfilePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiUserInfoUpdateProfilePost$Json(
    params?: {
      body?: UpdateUserCommand
    },
    context?: HttpContext
  ): Observable<UpdateUserViewModel> {
    return this.apiUserInfoUpdateProfilePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UpdateUserViewModel>): UpdateUserViewModel => r.body)
    );
  }

  /** Path part for operation `apiUserInfoDocumentIdGet()` */
  static readonly ApiUserInfoDocumentIdGetPath = '/api/UserInfo/{documentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserInfoDocumentIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserInfoDocumentIdGet$Plain$Response(
    params: {
      documentId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UserInfoViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, UserInfoService.ApiUserInfoDocumentIdGetPath, 'get');
    if (params) {
      rb.path('documentId', params.documentId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserInfoViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUserInfoDocumentIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserInfoDocumentIdGet$Plain(
    params: {
      documentId: string;
    },
    context?: HttpContext
  ): Observable<UserInfoViewModel> {
    return this.apiUserInfoDocumentIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserInfoViewModel>): UserInfoViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiUserInfoDocumentIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserInfoDocumentIdGet$Json$Response(
    params: {
      documentId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<UserInfoViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, UserInfoService.ApiUserInfoDocumentIdGetPath, 'get');
    if (params) {
      rb.path('documentId', params.documentId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserInfoViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiUserInfoDocumentIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiUserInfoDocumentIdGet$Json(
    params: {
      documentId: string;
    },
    context?: HttpContext
  ): Observable<UserInfoViewModel> {
    return this.apiUserInfoDocumentIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserInfoViewModel>): UserInfoViewModel => r.body)
    );
  }

}
