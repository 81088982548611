/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AssignReviewCommand } from '../models/assign-review-command';
import { AssignReviewViewModel } from '../models/assign-review-view-model';
import { ConfirmCreditRenewCommand } from '../models/confirm-credit-renew-command';
import { ConfirmCreditRenewViewModel } from '../models/confirm-credit-renew-view-model';
import { CreditRenewCommand } from '../models/credit-renew-command';
import { CreditRenewDataViewModel } from '../models/credit-renew-data-view-model';
import { CreditRenewViewModel } from '../models/credit-renew-view-model';
import { GetCreditRenewListQuery } from '../models/get-credit-renew-list-query';
import { GetInstitucionesViewModel } from '../models/get-instituciones-view-model';
import { GetProgramasViewModel } from '../models/get-programas-view-model';
import { GetServiceTypeViewModel } from '../models/get-service-type-view-model';
import { ReplyCreditRenewCommand } from '../models/reply-credit-renew-command';
import { ReplyCreditRenewViewModel } from '../models/reply-credit-renew-view-model';
import { SendToIasCommand } from '../models/send-to-ias-command';
import { SendToIasViewModel } from '../models/send-to-ias-view-model';

@Injectable({ providedIn: 'root' })
export class CreditRenewService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `apiCreditRenewRepairGet()` */
  static readonly ApiCreditRenewRepairGetPath = '/api/CreditRenew/repair';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewRepairGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditRenewRepairGet$Plain$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewRepairGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewRepairGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditRenewRepairGet$Plain(
    params?: {
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.apiCreditRenewRepairGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewRepairGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditRenewRepairGet$Json$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewRepairGetPath, 'get');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewRepairGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditRenewRepairGet$Json(
    params?: {
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.apiCreditRenewRepairGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `apiCreditRenewParamsTiposSignoGet()` */
  static readonly ApiCreditRenewParamsTiposSignoGetPath = '/api/CreditRenew/params/tipos/{signo}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewParamsTiposSignoGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditRenewParamsTiposSignoGet$Plain$Response(
    params: {
      signo: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<GetServiceTypeViewModel>>> {
    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewParamsTiposSignoGetPath, 'get');
    if (params) {
      rb.path('signo', params.signo, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GetServiceTypeViewModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewParamsTiposSignoGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditRenewParamsTiposSignoGet$Plain(
    params: {
      signo: string;
    },
    context?: HttpContext
  ): Observable<Array<GetServiceTypeViewModel>> {
    return this.apiCreditRenewParamsTiposSignoGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GetServiceTypeViewModel>>): Array<GetServiceTypeViewModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewParamsTiposSignoGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditRenewParamsTiposSignoGet$Json$Response(
    params: {
      signo: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<GetServiceTypeViewModel>>> {
    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewParamsTiposSignoGetPath, 'get');
    if (params) {
      rb.path('signo', params.signo, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GetServiceTypeViewModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewParamsTiposSignoGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditRenewParamsTiposSignoGet$Json(
    params: {
      signo: string;
    },
    context?: HttpContext
  ): Observable<Array<GetServiceTypeViewModel>> {
    return this.apiCreditRenewParamsTiposSignoGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GetServiceTypeViewModel>>): Array<GetServiceTypeViewModel> => r.body)
    );
  }

  /** Path part for operation `apiCreditRenewParamsUniversidadesCiudadGet()` */
  static readonly ApiCreditRenewParamsUniversidadesCiudadGetPath = '/api/CreditRenew/params/universidades/{ciudad}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewParamsUniversidadesCiudadGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditRenewParamsUniversidadesCiudadGet$Plain$Response(
    params: {
      ciudad: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<GetInstitucionesViewModel>>> {
    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewParamsUniversidadesCiudadGetPath, 'get');
    if (params) {
      rb.path('ciudad', params.ciudad, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GetInstitucionesViewModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewParamsUniversidadesCiudadGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditRenewParamsUniversidadesCiudadGet$Plain(
    params: {
      ciudad: string;
    },
    context?: HttpContext
  ): Observable<Array<GetInstitucionesViewModel>> {
    return this.apiCreditRenewParamsUniversidadesCiudadGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GetInstitucionesViewModel>>): Array<GetInstitucionesViewModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewParamsUniversidadesCiudadGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditRenewParamsUniversidadesCiudadGet$Json$Response(
    params: {
      ciudad: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<GetInstitucionesViewModel>>> {
    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewParamsUniversidadesCiudadGetPath, 'get');
    if (params) {
      rb.path('ciudad', params.ciudad, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GetInstitucionesViewModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewParamsUniversidadesCiudadGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditRenewParamsUniversidadesCiudadGet$Json(
    params: {
      ciudad: string;
    },
    context?: HttpContext
  ): Observable<Array<GetInstitucionesViewModel>> {
    return this.apiCreditRenewParamsUniversidadesCiudadGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GetInstitucionesViewModel>>): Array<GetInstitucionesViewModel> => r.body)
    );
  }

  /** Path part for operation `apiCreditRenewParamsProgramasUniversidadGet()` */
  static readonly ApiCreditRenewParamsProgramasUniversidadGetPath = '/api/CreditRenew/params/programas/{universidad}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewParamsProgramasUniversidadGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditRenewParamsProgramasUniversidadGet$Plain$Response(
    params: {
      universidad: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<GetProgramasViewModel>>> {
    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewParamsProgramasUniversidadGetPath, 'get');
    if (params) {
      rb.path('universidad', params.universidad, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GetProgramasViewModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewParamsProgramasUniversidadGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditRenewParamsProgramasUniversidadGet$Plain(
    params: {
      universidad: string;
    },
    context?: HttpContext
  ): Observable<Array<GetProgramasViewModel>> {
    return this.apiCreditRenewParamsProgramasUniversidadGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GetProgramasViewModel>>): Array<GetProgramasViewModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewParamsProgramasUniversidadGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditRenewParamsProgramasUniversidadGet$Json$Response(
    params: {
      universidad: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<GetProgramasViewModel>>> {
    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewParamsProgramasUniversidadGetPath, 'get');
    if (params) {
      rb.path('universidad', params.universidad, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GetProgramasViewModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewParamsProgramasUniversidadGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditRenewParamsProgramasUniversidadGet$Json(
    params: {
      universidad: string;
    },
    context?: HttpContext
  ): Observable<Array<GetProgramasViewModel>> {
    return this.apiCreditRenewParamsProgramasUniversidadGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<GetProgramasViewModel>>): Array<GetProgramasViewModel> => r.body)
    );
  }

  /** Path part for operation `apiCreditRenewUserUserIdCreditCreditIdGet()` */
  static readonly ApiCreditRenewUserUserIdCreditCreditIdGetPath = '/api/CreditRenew/user/{userId}/credit/{creditId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewUserUserIdCreditCreditIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditRenewUserUserIdCreditCreditIdGet$Plain$Response(
    params: {
      userId: string;
      creditId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CreditRenewDataViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewUserUserIdCreditCreditIdGetPath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.path('creditId', params.creditId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreditRenewDataViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewUserUserIdCreditCreditIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditRenewUserUserIdCreditCreditIdGet$Plain(
    params: {
      userId: string;
      creditId: string;
    },
    context?: HttpContext
  ): Observable<CreditRenewDataViewModel> {
    return this.apiCreditRenewUserUserIdCreditCreditIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreditRenewDataViewModel>): CreditRenewDataViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewUserUserIdCreditCreditIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditRenewUserUserIdCreditCreditIdGet$Json$Response(
    params: {
      userId: string;
      creditId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CreditRenewDataViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewUserUserIdCreditCreditIdGetPath, 'get');
    if (params) {
      rb.path('userId', params.userId, {});
      rb.path('creditId', params.creditId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreditRenewDataViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewUserUserIdCreditCreditIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditRenewUserUserIdCreditCreditIdGet$Json(
    params: {
      userId: string;
      creditId: string;
    },
    context?: HttpContext
  ): Observable<CreditRenewDataViewModel> {
    return this.apiCreditRenewUserUserIdCreditCreditIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreditRenewDataViewModel>): CreditRenewDataViewModel => r.body)
    );
  }

  /** Path part for operation `apiCreditRenewListPost()` */
  static readonly ApiCreditRenewListPostPath = '/api/CreditRenew/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewListPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewListPost$Plain$Response(
    params?: {
      body?: GetCreditRenewListQuery
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CreditRenewDataViewModel>>> {
    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewListPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CreditRenewDataViewModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewListPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewListPost$Plain(
    params?: {
      body?: GetCreditRenewListQuery
    },
    context?: HttpContext
  ): Observable<Array<CreditRenewDataViewModel>> {
    return this.apiCreditRenewListPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CreditRenewDataViewModel>>): Array<CreditRenewDataViewModel> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewListPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewListPost$Json$Response(
    params?: {
      body?: GetCreditRenewListQuery
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CreditRenewDataViewModel>>> {
    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewListPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CreditRenewDataViewModel>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewListPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewListPost$Json(
    params?: {
      body?: GetCreditRenewListQuery
    },
    context?: HttpContext
  ): Observable<Array<CreditRenewDataViewModel>> {
    return this.apiCreditRenewListPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CreditRenewDataViewModel>>): Array<CreditRenewDataViewModel> => r.body)
    );
  }

  /** Path part for operation `apiCreditRenewPost()` */
  static readonly ApiCreditRenewPostPath = '/api/CreditRenew';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewPost$Plain$Response(
    params?: {
      body?: CreditRenewCommand
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CreditRenewViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreditRenewViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewPost$Plain(
    params?: {
      body?: CreditRenewCommand
    },
    context?: HttpContext
  ): Observable<CreditRenewViewModel> {
    return this.apiCreditRenewPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreditRenewViewModel>): CreditRenewViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewPost$Json$Response(
    params?: {
      body?: CreditRenewCommand
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CreditRenewViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreditRenewViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewPost$Json(
    params?: {
      body?: CreditRenewCommand
    },
    context?: HttpContext
  ): Observable<CreditRenewViewModel> {
    return this.apiCreditRenewPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CreditRenewViewModel>): CreditRenewViewModel => r.body)
    );
  }

  /** Path part for operation `apiCreditRenewConfirmPost()` */
  static readonly ApiCreditRenewConfirmPostPath = '/api/CreditRenew/confirm';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewConfirmPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewConfirmPost$Plain$Response(
    params?: {
      body?: ConfirmCreditRenewCommand
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ConfirmCreditRenewViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewConfirmPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConfirmCreditRenewViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewConfirmPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewConfirmPost$Plain(
    params?: {
      body?: ConfirmCreditRenewCommand
    },
    context?: HttpContext
  ): Observable<ConfirmCreditRenewViewModel> {
    return this.apiCreditRenewConfirmPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConfirmCreditRenewViewModel>): ConfirmCreditRenewViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewConfirmPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewConfirmPost$Json$Response(
    params?: {
      body?: ConfirmCreditRenewCommand
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ConfirmCreditRenewViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewConfirmPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ConfirmCreditRenewViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewConfirmPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewConfirmPost$Json(
    params?: {
      body?: ConfirmCreditRenewCommand
    },
    context?: HttpContext
  ): Observable<ConfirmCreditRenewViewModel> {
    return this.apiCreditRenewConfirmPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConfirmCreditRenewViewModel>): ConfirmCreditRenewViewModel => r.body)
    );
  }

  /** Path part for operation `apiCreditRenewReplyAdminPost()` */
  static readonly ApiCreditRenewReplyAdminPostPath = '/api/CreditRenew/reply-admin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewReplyAdminPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewReplyAdminPost$Plain$Response(
    params?: {
      body?: ReplyCreditRenewCommand
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ReplyCreditRenewViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewReplyAdminPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReplyCreditRenewViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewReplyAdminPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewReplyAdminPost$Plain(
    params?: {
      body?: ReplyCreditRenewCommand
    },
    context?: HttpContext
  ): Observable<ReplyCreditRenewViewModel> {
    return this.apiCreditRenewReplyAdminPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReplyCreditRenewViewModel>): ReplyCreditRenewViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewReplyAdminPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewReplyAdminPost$Json$Response(
    params?: {
      body?: ReplyCreditRenewCommand
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ReplyCreditRenewViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewReplyAdminPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReplyCreditRenewViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewReplyAdminPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewReplyAdminPost$Json(
    params?: {
      body?: ReplyCreditRenewCommand
    },
    context?: HttpContext
  ): Observable<ReplyCreditRenewViewModel> {
    return this.apiCreditRenewReplyAdminPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReplyCreditRenewViewModel>): ReplyCreditRenewViewModel => r.body)
    );
  }

  /** Path part for operation `apiCreditRenewSendToIasPost()` */
  static readonly ApiCreditRenewSendToIasPostPath = '/api/CreditRenew/send-to-ias';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewSendToIasPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewSendToIasPost$Plain$Response(
    params?: {
      body?: SendToIasCommand
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SendToIasViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewSendToIasPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SendToIasViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewSendToIasPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewSendToIasPost$Plain(
    params?: {
      body?: SendToIasCommand
    },
    context?: HttpContext
  ): Observable<SendToIasViewModel> {
    return this.apiCreditRenewSendToIasPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SendToIasViewModel>): SendToIasViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewSendToIasPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewSendToIasPost$Json$Response(
    params?: {
      body?: SendToIasCommand
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<SendToIasViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewSendToIasPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SendToIasViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewSendToIasPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewSendToIasPost$Json(
    params?: {
      body?: SendToIasCommand
    },
    context?: HttpContext
  ): Observable<SendToIasViewModel> {
    return this.apiCreditRenewSendToIasPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<SendToIasViewModel>): SendToIasViewModel => r.body)
    );
  }

  /** Path part for operation `apiCreditRenewAssignReviewPost()` */
  static readonly ApiCreditRenewAssignReviewPostPath = '/api/CreditRenew/assign-review';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewAssignReviewPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewAssignReviewPost$Plain$Response(
    params?: {
      body?: AssignReviewCommand
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AssignReviewViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewAssignReviewPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AssignReviewViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewAssignReviewPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewAssignReviewPost$Plain(
    params?: {
      body?: AssignReviewCommand
    },
    context?: HttpContext
  ): Observable<AssignReviewViewModel> {
    return this.apiCreditRenewAssignReviewPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<AssignReviewViewModel>): AssignReviewViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewAssignReviewPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewAssignReviewPost$Json$Response(
    params?: {
      body?: AssignReviewCommand
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<AssignReviewViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewAssignReviewPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AssignReviewViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewAssignReviewPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewAssignReviewPost$Json(
    params?: {
      body?: AssignReviewCommand
    },
    context?: HttpContext
  ): Observable<AssignReviewViewModel> {
    return this.apiCreditRenewAssignReviewPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<AssignReviewViewModel>): AssignReviewViewModel => r.body)
    );
  }

  /** Path part for operation `apiCreditRenewReplyPost()` */
  static readonly ApiCreditRenewReplyPostPath = '/api/CreditRenew/reply';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewReplyPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewReplyPost$Plain$Response(
    params?: {
      body?: ReplyCreditRenewCommand
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ReplyCreditRenewViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewReplyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: 'text/plain', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReplyCreditRenewViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewReplyPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewReplyPost$Plain(
    params?: {
      body?: ReplyCreditRenewCommand
    },
    context?: HttpContext
  ): Observable<ReplyCreditRenewViewModel> {
    return this.apiCreditRenewReplyPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReplyCreditRenewViewModel>): ReplyCreditRenewViewModel => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewReplyPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewReplyPost$Json$Response(
    params?: {
      body?: ReplyCreditRenewCommand
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ReplyCreditRenewViewModel>> {
    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewReplyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'text/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReplyCreditRenewViewModel>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewReplyPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiCreditRenewReplyPost$Json(
    params?: {
      body?: ReplyCreditRenewCommand
    },
    context?: HttpContext
  ): Observable<ReplyCreditRenewViewModel> {
    return this.apiCreditRenewReplyPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReplyCreditRenewViewModel>): ReplyCreditRenewViewModel => r.body)
    );
  }

  /** Path part for operation `apiCreditRenewCertificateCreditRenewIdGet()` */
  static readonly ApiCreditRenewCertificateCreditRenewIdGetPath = '/api/CreditRenew/certificate/{creditRenewId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiCreditRenewCertificateCreditRenewIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditRenewCertificateCreditRenewIdGet$Response(
    params: {
      creditRenewId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, CreditRenewService.ApiCreditRenewCertificateCreditRenewIdGetPath, 'get');
    if (params) {
      rb.path('creditRenewId', params.creditRenewId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `apiCreditRenewCertificateCreditRenewIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiCreditRenewCertificateCreditRenewIdGet(
    params: {
      creditRenewId: string;
    },
    context?: HttpContext
  ): Observable<void> {
    return this.apiCreditRenewCertificateCreditRenewIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
