import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { getYear, setYear } from 'date-fns';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DateFnsDateAdapter } from './date-fns-date-adapter';

export const MY_FORMATS = {
  parse: {
    dateInput: 'yyyy',
  },
  display: {
    dateInput: 'yyyy',
    monthYearLabel: 'yyyy',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'yyyy',
  },
};

@Component({
  selector: 'app-year-picker',
  templateUrl: './year-picker.component.html',
  styleUrls: ['./year-picker.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: DateFnsDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class YearPickerComponent implements OnChanges {

  @Input() label = 'Año'
  @Input() minDate!: Date
  @Input() maxDate!: Date
  @Input() date!: Date | number | null
  @Input() readonly = false
  @Input() disabled = false

  @Output() newDate = new EventEmitter<Date>()
  @Output() newYear = new EventEmitter<number>()

  dateControl = new UntypedFormControl(null, [Validators.required])

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['date'] && this.date) {
      if (this.date.toString().length === 4) {
        const _date = setYear(new Date(), <number>this.date)
        this.dateControl.setValue(_date)
      } else {
        this.dateControl.setValue(new Date(this.date))
      }
    }

    if (changes && changes['disabled']) {
      this.disabled ? this.dateControl.disable() : this.dateControl.enable()
    }
  }

  chosenYearHandler(normalizedYear: Date, datepicker: MatDatepicker<Date>): void {
    const _year = getYear(normalizedYear)
    const _date = setYear(new Date(), _year)

    this.newDate.emit(_date)
    this.newYear.emit(_year)
    this.dateControl.setValue(_date)
    datepicker.close()
  }

}
