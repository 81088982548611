import { DecimalPipe } from '@angular/common';
import {
  Directive,
  ElementRef,
  Renderer2,
  OnInit,
  HostListener
} from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appValidate]'
})
export class ValidateDirective implements OnInit {

  private parent?: HTMLDivElement
  private messagesErrors: Properties = {
    confirmed: 'El campo no coincide',
    min: 'Debe ser mayor o igual a {length}',
    max: 'Debe ser menor o igual a {length}',
    required: 'El campo es obligatorio',
    requiredTrue: 'El campo es obligatorio',
    email: 'Debe ser un correo electrónico válido',
    minlength: 'Debe ser de {length} caracteres o superior',
    maxlength: 'Debe de ser de {length} caracteres o menor',
    pattern: 'El formato del campo no es válido',
    nullValidator: 'Los datos no son validos',
    compose: 'La validación compuesta no es valida',
    composeAsync: 'La validación compuesta no es valida',
    hasNumber: 'Debe tener al menos un número',
    hasCapitalCase: 'Debe tener al menos una mayúscula',
    hasSmallCase: 'Debe tener al menos una minúscula',
    hasSpecialCharacters: 'Debe tener al menos un carácter especial',
  }

  constructor(
    private decimalPipe: DecimalPipe,
    private ngControl: NgControl,
    private el: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    if (!this.ngControl) {
      console.warn('Note: The appValidate directive should be used with one of ngModel, formControl or formControlName directives.');
      return;
    }

    this.parent = this.el.nativeElement?.parentNode?.parentNode?.parentNode?.parentNode
  }

  @HostListener('focusout') onfocusout(): void {
    this.#createError(this.#messageError)
  }

  @HostListener('keyup') onkeyup(): void {
    this.#createError(this.#messageError)
  }

  @HostListener('selectionChange') onSelect(): void {
    this.#createError(this.#messageError)
  }

  @HostListener('openedChange') onOpened(): void {
    this.#createError(this.#messageError)
  }

  #createError(err: string): void {
    const matError = this.parent?.querySelector('mat-error')
    if (!matError) return
    this.renderer.setProperty(matError, 'innerHTML', err)
  }

  get #messageError(): string {
    let error = ''

    if (this.ngControl.touched && this.ngControl.invalid) {
      const errors = this.ngControl.errors || {}
      const attribute: keyof Properties = Object.keys(errors)[0] as keyof Properties
      const message: string = this.messagesErrors[attribute]
      if (attribute === 'minlength' || attribute === 'maxlength') {
        const length = this.decimalPipe.transform(errors[attribute].requiredLength, "0.0-2") || '¿?'
        error = message.replace(/{length}/i, length)
      } else if (attribute === 'min' || attribute === 'max') {
        const length = this.decimalPipe.transform(errors[attribute][attribute], "0.0-2") || '¿?'
        error = message.replace(/{length}/i, length)
      } else {
        error = message
      }
    }

    return error
  }

}

interface Properties {
  confirmed: string;
  min: string;
  max: string;
  required: string;
  requiredTrue: string;
  email: string;
  minlength: string;
  maxlength: string;
  pattern: string;
  nullValidator: string;
  compose: string;
  composeAsync: string;
  hasNumber: string;
  hasCapitalCase: string;
  hasSmallCase: string;
  hasSpecialCharacters: string;
}
