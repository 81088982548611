import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IfEnabledDirective } from './if-enabled.directive';

@NgModule({
  declarations: [
    IfEnabledDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [IfEnabledDirective]
})
export class IfEnabledModule {}
