<div class="mt-1 py-2 px-3 mat-elevation-z4 rounded">
  <h3>Documentos anexos</h3>

  <p class="text-justify">
    Cuando requiera o sea de su interés presentar algún documento que soporte su
    petición puede adjuntarlo en esta sección. Los documentos permitidos deben
    ser de tipo .pdf .doc .docx .jpg .png .xls .xlsx. Tamaño máximo permitido de
    archivo 4MiB.
  </p>

  <div class="d-flex flex-column py-3">
    <div
      *ngFor="let fileDetail of _fileDetails"
      class="d-flex align-items-center py-2 file-item"
      [ngClass]="{ 'flex-column': commonService.isMobile }"
    >
      <p class="mb-0">
        {{ fileDetail.fileName }}
        ({{ fileDetail.size | number : "1.1-2" }} MiB )
      </p>
      <div
        [ngClass]="commonService.isMobile ? 'w-100 py-2' : 'flex-grow-1 px-2'"
      >
        <mat-progress-bar
          mode="determinate"
          [value]="fileDetail.progress"
        ></mat-progress-bar>
      </div>

      <button
        (click)="cancelOrDeleteFile(fileDetail)"
        mat-stroked-button
        type="button"
        color="warn"
        [disabled]="fileDetail.canceled"
      >
        {{
          fileDetail.uploaded
            ? "Eliminar"
            : fileDetail.uploading
            ? "Cancelar"
            : "Cancelado"
        }}
      </button>
    </div>
  </div>

  <div class="d-flex justify-content-center">
    <button
      mat-stroked-button
      type="button"
      (click)="attachedFilesInput.click()"
      color="accent"
      class="rounded-3"
    >
      {{ labelButton }} <mat-icon>{{ iconButton }}</mat-icon>
    </button>
    <input
      #attachedFilesInput
      hidden
      (change)="addAttachments($event); attachedFilesInput.value = ''"
      type="file"
      accept="image/*,application/pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      multiple
    />
  </div>
</div>
