import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Title, Meta } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class MetaTagsService {

  private _titleToolbar = new BehaviorSubject('')
  private urlMeta = 'og:url'
  private titleMeta = 'og:title'
  private descriptionMeta = 'og:description'
  private imageMeta = 'og:image'
  private secureImageMeta = 'og:image:secure_url'
  private twitterTitleMeta = 'twitter:text:title'
  private twitterImageMeta = 'twitter:image'

  constructor(
    private titleService: Title,
    private metaService: Meta
  ) {}

  /**
   * Obtiene el título del Toolbar
   */
  get titleToolbar(): Observable<string> {
    return this._titleToolbar.asObservable()
  }

  /**
   * Asigna el título al Toolbar
   * @param data título
   */
  setTitleToolbar(data: string): void {
    this._titleToolbar.next(data)
  }

  /**
   * Asigna el título a la página actual
   * @param title título
   */
  public setTitle(title: string): void {
    if (title.trim()) {
      this.titleService.setTitle(`${title} | Instituto Financiero de Casanare`)
    } else {
      this.titleService.setTitle('IFC | Instituto Financiero de Casanare')
    }
  }

  /**
   * Asigna las meta que son consumidos por Graph para que
   * se visulice el contenido en las redes sociales
   * @param data Mapa de valores para asignarlos a los tags
   */
  public setSocialMediaTags(data: SocialMetatag): void {
    const tags = [
      new MetaTag(this.titleMeta, data.title, true),
      new MetaTag(this.descriptionMeta, data.description, true),
      new MetaTag(this.imageMeta, data.imageUrl, true),
      new MetaTag(this.secureImageMeta, data.imageUrl, true),
      new MetaTag(this.twitterTitleMeta, data.title, false),
      new MetaTag(this.twitterImageMeta, data.imageUrl, false)
    ]
    this.setTags(tags)
  }

  private setTags(tags: MetaTag[]): void {
    tags.forEach(siteTag => {
      if (siteTag.isFacebook) {
        this.metaService.addTag({ property: siteTag.name, content: siteTag.value })
      } else {
        this.metaService.addTag({ name: siteTag.name, content: siteTag.value })
      }
    })
  }

}

class MetaTag {
  name: string
  value: string
  isFacebook: boolean

  constructor(name: string, value: string, isFacebook: boolean) {
    this.name = name
    this.value = value
    this.isFacebook = isFacebook
  }
}

/**
 * @param pathname contiene el '/' inicial, seguido por la ruta de la URL.
 * @param title Título del página actual
 * @param description Descripción de la página actual
 * @param imageUrl URL de la imágen principal del página actual
 */
export interface SocialMetatag {
  pathname: string
  title: string
  description: string
  imageUrl: string
}
