import {
  Component,
  Inject,
  OnInit,
} from "@angular/core"
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog"
import { CreditInfo } from '@ifc-api/models'
import { TypesOfReports } from '@shared/models'
import { ReportHistoryComponent } from '../report-history/report-history.component'

@Component({
  selector: 'app-receipt-payments',
  templateUrl: './receipt-payments.component.html',
  styleUrls: ['./receipt-payments.component.scss']
})
export class ReceiptPaymentsComponent implements OnInit {

  minimumValue = 0
  typesOfReports = TypesOfReports

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ReceiptPaymentsComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
  ) {}

  ngOnInit(): void {
    this.minimumValue = this.dialogData.creditInfoDetail.details?.minimumValue || 0
  }

  presentReportHistory(): void {
    const dialogRef = this.dialog.open(ReportHistoryComponent, {
      data: {
        reportType: this.typesOfReports.Payment,
        creditNumber: this.dialogData.creditInfoDetail.creditNumber,
      }
    })
  }

}

interface DialogData {
  document: string
  creditInfoDetail: CreditInfo
}
