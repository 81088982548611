import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from "@angular/core"
import { WebProcessViewModel } from '@ifc-api/models'
import { AppService } from '@shared/services'
import Swal from "sweetalert2"

@Directive({
  selector: '[appIfEnabled]'
})
export class IfEnabledDirective {

  private _itemId: string | number | false = ''

  @Input('appIfEnabled')
  public set itemId(_itemId: string | number | false) {
    if (_itemId === '' || _itemId === false) {
      console.warn(`Nota: El valor ${JSON.stringify(_itemId)} no es asignable.
      Solo string (no vacio "un numero") o número es permitido.`)

      this._itemId = false
      return
    }

    this._itemId = _itemId
  }

  public get itemId(): string | number | false {
    return this._itemId
  }

  constructor(private appService: AppService) {}

  @Output() ifEnabledClick = new EventEmitter()

  @HostListener('click', ['$event'])
  onClick(e: MouseEvent) {

    const stopEvent = (e: MouseEvent, item?: WebProcessViewModel) => {
      e.stopImmediatePropagation()
      e.preventDefault()
      e.stopPropagation()
      this.presentMessage(item)
    }

    if (!this.itemId) {
      stopEvent(e)
      return
    }

    const items = this.appService.ifEnabledItems

    if (items.length > 0) {

      const item = items.filter((_item) => _item.id === this.itemId)[0]

      if (item && item.isEnabled) {
        this.ifEnabledClick.next(e)
      } else {
        stopEvent(e, item)
      }

    } else {
      stopEvent(e)
    }

  }

  private presentMessage(item?: WebProcessViewModel): void {
    const messageNext = `Estamos trabajando en brindarle nuevas funcionalidades. <br /> <br /> Regresa pronto.`

    if (item && item?.messageProcessDescription) {
      item.messageProcessDescription = item.messageProcessDescription.replace(/\n/g, '<br />')
    }

    const title = item?.messageProcessTitle ?? '¡Próximamente!'
    const message = item?.messageProcessDescription ?? messageNext

    Swal.fire(title, message, 'info')
  }

}
