export enum TypesOfReports {
    /**
     * Paz y Salvo
     */
    Compliance = 1,
    /**
     * Estado De Cuenta
     */
    Status = 2,
    /**
     * Deuda al dia
     */
    DebtStatus = 3,
    /**
     * Declaracion
     */
    TaxReport = 4,
    /**
     * Recibo Pago
     */
    Payment = 5,
    Registers = 6,
    Logins = 7,
    AllAuth = 9,
    AllReports = 8,
    AllData = 0,
}
