import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { ReceiptPaymentsComponent } from './receipt-payments.component';
import { ButtonPdfModule } from '../button-pdf/button-pdf.module';
import { ReportHistoryModule } from '../report-history/report-history.module';

@NgModule({
  declarations: [ReceiptPaymentsComponent],
  imports: [
    CommonModule,

    MatDialogModule,
    MatButtonModule,
    MatIconModule,

    ButtonPdfModule,
    ReportHistoryModule,
  ],
  exports: [ReceiptPaymentsComponent]
})
export class ReceiptPaymentsModule {}
