<div mat-dialog-title class="d-flex justify-content-between">
  <div class="my-2">Responder PQRS</div>

  <button mat-icon-button [mat-dialog-close]="null" color="warn">
    <mat-icon>cancel</mat-icon>
  </button>
</div>

<mat-dialog-content class="pb-2">
  <form [formGroup]="replyGroup" class="row pt-1">
    <mat-form-field appearance="outline" class="col-12 form_field_3">
      <mat-label> Estado </mat-label>
      <mat-select formControlName="statusTypeId" appValidate>
        <mat-option *ngFor="let status of statusTypes" [value]="status.id">
          {{ status.name }}
        </mat-option>
      </mat-select>
      <mat-error></mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="col-12 form_field_3">
      <mat-label> Descripción </mat-label>
      <textarea
        matInput
        formControlName="description"
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="10"
        appValidate
      ></textarea>
      <mat-error></mat-error>
    </mat-form-field>

    <mat-form-field
      *ngIf="replyGroup.value.statusTypeId === 3"
      appearance="outline"
      class="col-12 form_field_3"
    >
      <mat-label> Nombre de responsable </mat-label>
      <input matInput formControlName="assignedTo" appValidate />
      <mat-error></mat-error>
    </mat-form-field>

    <mat-form-field
      *ngIf="replyGroup.value.statusTypeId === 3"
      appearance="outline"
      class="col-12 form_field_3"
    >
      <mat-label> Correo electrónico de responsable </mat-label>
      <input matInput formControlName="assignedToEmail" appValidate />
      <mat-error></mat-error>
    </mat-form-field>
  </form>

  <div class="row">
    <div class="col-12">
      <app-upload-files
        (uploadingFiles)="uploadingFiles = $event"
        (fileDetails)="fileDetails = $event"
      ></app-upload-files>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="center">
  <button
    mat-flat-button
    (click)="replyRequest()"
    color="primary"
    class="rounded-3 px-5"
    [disabled]="saving"
  >
    Responder
    <mat-spinner
      *ngIf="saving"
      [diameter]="20"
      class="d-inline-flex ms-2"
      color="primary"
    ></mat-spinner>
  </button>
</mat-dialog-actions>
