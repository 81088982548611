<app-loading-data
  *ngIf="
    !debtADay && (dialogData.creditInfoDetail.status | lowercase) === 'ac';
    else dataLoaded
  "
></app-loading-data>

<ng-template #dataLoaded>
  <div mat-dialog-title class="d-flex justify-content-between">
    <div class="my-2">Deuda al día</div>

    <button mat-icon-button [mat-dialog-close]="null" color="warn">
      <mat-icon>cancel</mat-icon>
    </button>
  </div>

  <mat-dialog-content
    *ngIf="(debtADay?.status | lowercase) === 'ac' && minimumValue === 0"
  >
    <div class="d-flex flex-column">
      <div class="text-justify">
        <p>
          Que:
          {{ dialogData.creditInfoDetail.details?.userName | titlecase }}
          identificados(as) con cédula de ciudadanía No
          {{ dialogData.document }} en su condición de deudor y codeudor(es), se
          encuentran al día en sus pagos con la siguiente obligación:
        </p>
        <br />
      </div>

      <div class="d-flex justify-content-between">
        <p>No. crédito:</p>
        <p class="ms-2">{{ debtADay?.creditNumber }}</p>
      </div>

      <div class="d-flex justify-content-between">
        <p>Valor aprobado:</p>
        <p class="ms-2">${{ debtADay?.totalValue | number }}</p>
      </div>

      <div class="d-flex justify-content-between">
        <p>Saldo:</p>
        <p class="ms-2">${{ debtADay?.debtValue | number }}</p>
      </div>

      <div class="d-flex justify-content-between">
        <p>Estado de cuenta de crédito:</p>
        <p class="ms-2">
          {{
            (dialogData.creditInfoDetail.status | lowercase) === "ac"
              ? "ACTIVO"
              : "CANCELADO"
          }}
        </p>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-content
    *ngIf="(debtADay?.status | lowercase) === 'ac' && minimumValue > 0"
  >
    <p class="text-justify">
      Esta obligación se encuentra con una deuda pendiente de ${{
        minimumValue | number
      }}, por tanto no puede generar el certificado.
    </p>
  </mat-dialog-content>

  <!-- Crédito cancelado -->
  <mat-dialog-content *ngIf="(debtADay?.status | lowercase) === 'ca'">
    <p class="text-justify">
      Esta obligación ya se encuentra cancelada, por lo tanto no se puede
      generar el formato de Deuda al Dia, para generar un Paz y Salvo cierre
      esta ventana y acceda la función adecuada
    </p>
  </mat-dialog-content>

  <mat-dialog-actions
    *ngIf="(debtADay?.status | lowercase) === 'ac'"
    align="center"
  >
    <button mat-flat-button (click)="presentReportHistory()" color="primary">
      Historial
    </button>

    <div class="px-2"></div>

    <app-button-pdf
      *ngIf="minimumValue === 0"
      [reportType]="typesOfReports.DebtStatus"
      [creditNumber]="dialogData.creditInfoDetail.creditNumber"
      [nameReport]="'deuda_al_día'"
    ></app-button-pdf>
  </mat-dialog-actions>
</ng-template>
