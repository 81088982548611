import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';

import { ReportHistoryComponent } from './report-history.component';
import { LoadingDataModule } from '../loading-data/loading-data.module';


@NgModule({
  declarations: [ReportHistoryComponent],
  imports: [
    CommonModule,

    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,

    LoadingDataModule,
  ],
  exports: [ReportHistoryComponent]
})
export class ReportHistoryModule { }
